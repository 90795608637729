import { AccordionViewModel } from "./accordion-view-model";

export class ActivityPrototypeViewModel extends AccordionViewModel {
    category?: string | undefined;
    categoryExternal?: string | undefined;
    validFrom?: Date | undefined;
    validTo?: Date | undefined;
    key?: string | undefined;
}

// export class ProgramCategoryViewModel{
//     //category: string | undefined;
//     program?: string | undefined; // Programm // fitness, vorsorge, ...
//     participants: ProgramParticipantModel[] | undefined;
// }

// export class ProgramParticipantModel{
//     participant: string | undefined; // Teilnehmer // Kinder, Erwachsene, ...
//     //categoryExternal: string | undefined;
//     accordionItems: ActivityPrototypeViewModel[] | undefined;
// }



export class ProgramParticipantModel {
    participant: string | undefined; // Teilnehmer // Kinder, Erwachsene, ...
    //categoryExternal: string | undefined;
    ProgramCategories: ProgramCategoryViewModel[] | undefined;
}

export class ProgramCategoryViewModel {
    //category: string | undefined;
    program?: string | undefined; // Programm // fitness, vorsorge, ...
    //participants: ProgramParticipantModel[] | undefined;
    accordionItems: ActivityPrototypeViewModel[] | undefined;
}


