import { ProfileService } from './../service/profile/profile-service';
import { TenantViewModel } from './../view-models/tenant-view-models';
import { autoinject } from 'aurelia-framework';
import { ConfigProvider, Logger } from "voltospa";
import { TenantContext } from "../service/tenant/tenant-context";
import { TenantService } from "../service/tenant/tenant-service";
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { PageBase } from "./contents/Base/page-base";
import { Constants } from '../constants/constants';
import {ErrorDTO} from "../service/api/aok-rest-api";

@autoinject()
export class EmailBestaetigung extends PageBase {

    public tenant: string = '';

    public generalError: boolean = false;
    public verificationCompleted: boolean = false;
    public emailVerified: boolean = false;
    public optinTokenNotFoundOrExpired: boolean = false;
    public optinTokenAlreadyConfirmed: boolean = false;

    public programName: string = '';

    private currentTenant: TenantViewModel = null;

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private profileService: ProfileService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
        layout.setStartLayout();
        this.programName = this.config.get<any>(Constants.aokConfigurationProgramInfo + this.program).name
    }


    async activate(params: any, route, navigationInstruction) {
        this.reInitialize();

        var uuid: string = params.uuid;

        let tenant = (await this.tenantService.getTenantsByProgramType()).filter(t => t.key === navigationInstruction.params.tenant);
        if (tenant) {
            this.currentTenant = tenant[0];
        } else {
            this.currentTenant = null;
        }

        if (this.currentTenant != null) {
            if (!uuid || uuid.trim() == '') {
                this.optinTokenNotFoundOrExpired = true;
                this.verificationCompleted = true;
                return;
            }

            let loyaltyProgram = this.currentTenant.programs.filter(lp => lp.programType == this.program)[0];
            if (!loyaltyProgram) {
                this.generalError = true;
                this.verificationCompleted = true;
                return;
            }

            this.tenantProvider.setTenant(this.currentTenant, false);

            try {
                await this.profileService.confirmOptinToken(loyaltyProgram.id, uuid);
                this.emailVerified = true;
            } catch (error) {
                if (error instanceof ErrorDTO) {
                    switch (error.errorCode) {
                        case "OPTIN_TOKEN_NOTFOUND":
                        case "OPTIN_TOKEN_EXPIRED":
                            this.optinTokenNotFoundOrExpired = true;
                            break;
                        case "OPTIN_TOKEN_ALREADY_CONFIRMED":
                            this.optinTokenAlreadyConfirmed = true;
                            break;
                        default:
                            this.generalError = true;
                    }
                } else {
                    this.generalError = true;
                }
            }
        } else {
            this.generalError = true;
        }
        this.verificationCompleted = true;
    }

    private reInitialize() {
        this.tenant = '';

        this.generalError = false;
        this.verificationCompleted = false;
        this.emailVerified = false;
        this.optinTokenNotFoundOrExpired = false;
        this.optinTokenAlreadyConfirmed = false;

        this.programName = '';
        this.currentTenant = null;
    }
}