export class ParticipantNewsViewModel {
    news?: ParticipantNews[] | undefined;
    pageNumber?: number | undefined;
    totalNewsCount?: number | undefined;
}

export class ParticipantNews {
    category?: string | undefined;
    date?: Date | undefined;
    dateFormatted?: string | undefined;
    isNew: boolean | false;
    text?: string | undefined
}