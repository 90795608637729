import { observable } from 'aurelia-framework';
export abstract class LayoutProvider {
    @observable
    abstract hasMenu: boolean;

    @observable
    abstract hasContainer: boolean;

    @observable
    abstract hasFooter: boolean;

    abstract hasLanguageSelect: boolean;

    abstract hasLogout: boolean;

    hasMenuChanged(newValue, oldValue) {
        this.adjustBodyClass('layout-default hide-sidebar', !this.hasMenu);
    }


    adjustBodyClass(className: string, value: boolean): void {
        if (value) {
            $('body').addClass(className);
        } else {
            $('body').removeClass(className);
            
        }
    }
}