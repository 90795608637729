import * as $ from 'jquery';
/*
 * Mobile Radio-Button-Groups
 *
 *
 */

export function initMobileRadios(selector) {
    $(selector).each(function (index, radioBtnGroup) {
        let btnGroupElem = document.createElement("div");
        btnGroupElem.setAttribute("class", "btn-group");
        btnGroupElem.setAttribute("role", "group");

        $(radioBtnGroup).find(".radio-options .form-check").each(function (index, formCheckElem) {
            $(this).css({ "visibility": "hidden", "position": "absolute" });

            let btnElem = document.createElement("button");
            btnElem.setAttribute("type", "button");
            btnElem.setAttribute("class", "btn btn-secondary");
            let btnText = $(this).find("label")[0].textContent.trim();
            btnElem.setAttribute("data-value", btnText);
            btnElem.textContent = btnText;

            if ($(this).find("input").attr("checked")) {
                $(btnElem).addClass("active");
            }

            $(btnElem).on("click", function () {
                var event = document.createEvent("Event");
                event.initEvent("change", false, true); 
                $(formCheckElem).find("input").get(0).dispatchEvent(event);
                $(radioBtnGroup).find(".btn-group button").removeClass("active");
                $(this).addClass("active");
            });

            btnGroupElem.appendChild(btnElem);
        });

        $(radioBtnGroup).find(".radio-options").get(0).appendChild(btnGroupElem);
    });
}