import { autoinject, customElement, bindable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { AuthenticationProvider } from '../../services/security/authenticationProvider';
import { LocalizationProvider } from '../../services/localization/localizationProvider';

@autoinject
@customElement('volto-logininfo')
export class VoltoLogininfo {

    @bindable
    public isUserLoggedIn: boolean = false;

    @bindable
    public loggedInUserName: string = "a";

    @bindable
    public accountMenuOptionTitle: string = this.localizationProvider.translate("I18N_VOLTOSPA_LOGINFO_ACCOUNT_TITLE");
    @bindable
    public settingsMenuOptionTitle: string = this.localizationProvider.translate("I18N_VOLTOSPA_LOGINFO_SETTINGS_TITLE");

    @bindable
    public logoffTitle: string = this.localizationProvider.translate("I18N_VOLTOSPA_LOGOFF_TITLE");
    @bindable
    public logoffMessage: string = this.localizationProvider.translate("I18N_VOLTOSPA_LOGOFF_MESSAGE");
    @bindable
    public logoffYes: string = this.localizationProvider.translate("I18N_VOLTOSPA_LOGOFF_YES");
    @bindable
    public logoffNo: string = this.localizationProvider.translate("I18N_VOLTOSPA_LOGOFF_NO");
    
    public constructor(public EventAggregator: EventAggregator, public authenticationProvider: AuthenticationProvider, 
        public localizationProvider: LocalizationProvider) {
        this.initialize();
    }    

    async created() { 
        var self = this;
        $('body').off('click', '#yesLogout');
        $('body').on('click', '#yesLogout', function (e) {
            self.userLogout();
            e.preventDefault();
        });

        this.EventAggregator.subscribe('voltospa:authentication-login', () => {
            this.isUserLoggedIn = true;            
            this.displayloggedInUserName();
        });

        this.EventAggregator.subscribe('voltospa:authentication-logout', () => {
            this.isUserLoggedIn = false;
        });
    }

    async initialize(){
        let authenticationActive = this.authenticationProvider.authenticationActive();
        if(!authenticationActive)
            return;

        let isAuthenticated = await this.authenticationProvider.isAuthenticated();
        this.isUserLoggedIn = isAuthenticated;
        if(isAuthenticated){
            this.displayloggedInUserName();
        }
    }

    async displayloggedInUserName(){
        let user = await this.authenticationProvider.getUser();
        this.loggedInUserName = user.name;
    }

    private userLogout() {
        console.log(document.location.href.replace(document.location.hash,''));
        this.authenticationProvider.logout(document.location.href.replace(document.location.hash,''));
    }

}