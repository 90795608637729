import { autoinject, FrameworkConfiguration } from 'aurelia-framework';
import { User } from "../../models/security/user";
import { AuthenticationProvider } from "./authenticationProvider";
import { UserManager, UserManagerSettings } from 'oidc-client';
import { ConfigProvider } from '../configuration/configProvider';
import { Router, RouteConfig, NavigationInstruction, RouterConfiguration } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class DefaultAuthenticationProvider extends AuthenticationProvider {

    private userManager: UserManager;

    public constructor(private configProvider: ConfigProvider, public router: Router, public eventAggregator: EventAggregator) {
        super();
    }

    public async init(config: FrameworkConfiguration): Promise<void> {
        var authConfig: UserManagerSettings = this.configProvider.get<UserManagerSettings>('authentication');
        if (authConfig == null)
            return;
        this.userManager = new UserManager(authConfig);

        var subscription = this.eventAggregator.subscribe('voltospa:router-configured', () => {
            var home = this.router.routes.find(r => r.route == '');
            this.router.handleUnknownRoutes(async () => {
                return home;
            });
        });
    }

    public async login(returnUrl: string): Promise<void> {
        await this.userManager.signinRedirect({ state: { returnUrl: returnUrl } });
    }

    public async logout(returnUrl: string): Promise<void> {
        await this.userManager.removeUser();
        this.eventAggregator.publish('voltospa:authentication-logout');
        this.redirect(returnUrl);
    }

    private redirect(returnUrl: string): void {
        if(returnUrl == document.location.href)
            document.location.reload(true);
        else
            document.location.href = returnUrl;
    }

    public async isAuthenticated(): Promise<boolean> {
        var user = await this.getUser();
        return user != null;
    }

    public async getUser(): Promise<User> {
        var user = await this.userManager.getUser();
        if (user == null)
            return null;
        var result = new User();
        result.accessToken = user.access_token;
        result.userId = user.profile["sub"];
        result.name = user.profile["name"] != null ? user.profile["name"] : user.profile["sub"];
        for (var claim in user.profile) {
            result.claims[claim] = user.profile[claim];
        }
        return result;
    }

    public authenticationActive(): boolean {
        return this.configProvider.get<UserManagerSettings>('authentication') != null;
    }

    private async processLogin(): Promise<void> {
        try {
            var user = await this.userManager.signinRedirectCallback();
            if (user != null && user.state != null) {
                this.redirect(user.state.returnUrl);
                this.eventAggregator.publish('voltospa:authentication-login');
            }
        }
        catch{
        }
    }

    private async processLogout(): Promise<void> {
        var user = null;
        try {
            var user = await this.userManager.signoutRedirectCallback();
            if (user != null && user.state != null) {
                this.redirect(user.state.returnUrl);
                this.eventAggregator.publish('voltospa:authentication-logout');
            }
        }
        catch{
        }
    }

    public async handleAuthenticationCallbacks() {
        await this.processLogin();
        await this.processLogout();
    }
}