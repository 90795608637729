import { FormElement } from './formElement';
import { FormControlHelper } from './../../services/binding/formControlHelper';
import { bindable, autoinject, bindingMode, observable } from 'aurelia-framework';

@autoinject
export class VoltoRadiobuttons extends FormElement {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: any;
    @observable public selectedValue: any;
    @bindable public options: object[];
    @bindable public optionsLabel: string;
    @bindable public optionsValue: string;

    constructor(element: Element, formControlHelper: FormControlHelper) {
        super(element, formControlHelper);
    }
    
    public valueChanged(){
        if(this.value == null)
            this.selectedValue = null;
        this.selectedValue = this.value[this.optionsValue];
    }

    public selectedValueChanged(){
        this.value = this.options.find(o => o[this.optionsValue] == this.selectedValue);
    }
}