import * as $ from 'jquery';

export function initFloatingLabels() {
    /*
     * Floating Labels
     * Toggles .focused class on .form-group.floating-label elements
     */

    $(document).on("click.Volto", function (e) {
        if ($("body").hasClass("layout-mobile")) {
            let $floatingLabel = $(e.target).closest(".form-group.floating-label");
            $(".form-group.floating-label.focused").removeClass("focused");
            if ($floatingLabel.length)
                $floatingLabel.toggleClass("focused");
        }
    });
}