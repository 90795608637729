import { autoinject } from 'aurelia-framework';
import * as moment from "moment";
import { Moment } from "moment";
import { ConfigProvider } from 'voltospa';
import {
    ErrorDTO,
    ConfigurationDTO,
    LoyaltyProgramFAQDTO,
    IAOKRestApi, LoyaltyProgramDTO,
    TenantDTO,
    LoyaltyProgramNewsDTO,
    ResourceReferenceDTO,
    RewardGroupDTO,
    RewardGroupDTOGroup,
    ExtendedRewardDTO,
    LoyaltyProgramActivityPrototypeDTO,
    ExtendedRewardDTOGroup,
    ExtendedRewardDTOType,
    QueryRewardsDTO,
    UpdateChequebookDeliveryDTO,
    CommunityMemberDTO,
    PhvDTO,
    RegistrationFormRequestDTO,
    RegistrationFormResultDTO,
    ChequeBookDTO,
    PmvDTO,
    MembershipAokDTO,
    RelatedCommunityMemberDTO,
    RelatedCommunityMemberDTOSalutation,
    MembershipAokDTOChequebookDelivery,
    ActivityDTO,
    RedemptionRequestDTO,
    ChequeDocumentDetailsDTO,
    AccountBalanceResponseDTO,
    AccountStatementResponseDTO,
    ExpiringPointsResponseDTO,
    GetAccountBalanceRequestDTO,
    QueryAccountStatementRequestDTO,
    RegistrationFormResultDTOAddressValidationResult,
    ActivityRedemptionRequestDTO,
    PostingDTO,
    GetStatusResultDTO,
    CreateOnlineAccessRequestDTO,
    FeedbackDTO,
    OrderDTO,
    AddressDTO,
    DeliveryAddressDTO,
    OrderItemDTO,
    OrderItemDTOStatus,
    OrderRewardRequestDTO,
    RequestDownloadChequeBookDTO,
    ChangePasswordDTO,
    QueryParticipantAnouncementRequestDTO,
    QueryParticipantAnouncementResultDTO,
    ParticipantAnnouncementDTO, RedeemSubventionRequestDTO
} from "./aok-rest-api";
import { loremIpsum } from "lorem-ipsum";

@autoinject()
export class AokRestApiMock implements IAOKRestApi {

    private mockTenants: TenantDTO[];
    private mockFaqs: LoyaltyProgramFAQDTO[];
    private mockNews: LoyaltyProgramNewsDTO[];
    private mockRewardsGroup: RewardGroupDTO[];
    private mockRewards: ExtendedRewardDTO[];
    private mockActivityPrototype: LoyaltyProgramActivityPrototypeDTO[];
    private mockAnnouncements: ParticipantAnnouncementDTO[];

    constructor(private config: ConfigProvider) {
        this._initTenants();
        this._initFaqs();
        this._initNews();
        this._initRewardGroups();
        this._initActivityPrototypes();
        this._initParticipantAnnouncements();
    }
    queryParticipantAnnouncementsUsingPOST(request: QueryParticipantAnouncementRequestDTO): Promise<QueryParticipantAnouncementResultDTO> {
        var queryAnnouncement = new QueryParticipantAnouncementResultDTO();
        queryAnnouncement.pageNumber = request.pageNumber;
        queryAnnouncement.totalResultQuantity = this.mockAnnouncements.length;

        var startIndex = request.pageNumber * request.pageQuantity;
        queryAnnouncement.announcements = this.mockAnnouncements.slice(startIndex, startIndex + request.pageQuantity);
        queryAnnouncement.pageResultQuantity= queryAnnouncement.announcements.length;
        return Promise.resolve<QueryParticipantAnouncementResultDTO>(queryAnnouncement);
    }
    changePasswordUsingPOST(changePasswordDTO: ChangePasswordDTO): Promise<void> {
        throw new Error('Method not implemented.');
    }

    getChequeBookPdfUsingPOST(membershipId: number, requestDownloadChequeBookDTO: RequestDownloadChequeBookDTO): Promise<string> {
        throw new Error('Method not implemented.');
    }
    createOrderRewardPOST(membershipid: number, request: OrderRewardRequestDTO): Promise<OrderDTO> {
        throw new Error('Method not implemented.');
    }

    listOrdersGET(membershipid: number): Promise<OrderDTO[]> {

        let orders: OrderDTO[] = [];
        let order = new OrderDTO();
        order.orderDate = moment(new Date(2021, 5, 2));
        order.deliveryAddress = <DeliveryAddressDTO>{ firstname: 'Karl', lastname: 'Hammer' };
        order.value = 2232123477;  //Bestellnummer?

        let i1 = new OrderItemDTO();
        i1.amount = 2;
        i1.valuePerUnit = 500;
        i1.articleNumber = "A12345";
        i1.status = OrderItemDTOStatus.DELIVERED;

        let i2 = new OrderItemDTO();
        i2.amount = 1;
        i2.valuePerUnit = 1200;
        i2.articleNumber = "B23212";
        i2.status = OrderItemDTOStatus.CANCELLED;

        order.orderItems = [i1, i2];


        let order2 = new OrderDTO();
        order2.orderDate = moment(new Date(2021, 5, 2));
        order2.deliveryAddress = <DeliveryAddressDTO>{ firstname: 'Marta', lastname: 'Hammer' };
        order2.value = 2232123477;  //Bestellnummer?

        let i3 = new OrderItemDTO();
        i3.amount = 1;
        i3.valuePerUnit = 400;
        i3.articleNumber = "c23212";
        i3.status = OrderItemDTOStatus.CANCELLED;
        order2.orderItems = [i3];

        orders = [order, order, order2, order2, order, order2, order2];

        return Promise.resolve<OrderDTO[]>(orders);
    }
    cancelOrderPOST(membershipid: number, orderItemId: number): Promise<void> {
        throw new Error('Method not implemented.');
    }

    getNextExpiringPointsAppUsingGET(membershipid: number): Promise<ExpiringPointsResponseDTO> {
        let result = new ExpiringPointsResponseDTO();
        result.expiryPoints = Math.ceil(Math.random() * 10000);
        result.expiryDate = moment(new Date((Math.ceil(Math.random() * 5) + 2020), (Math.ceil(Math.random() * 12)), (Math.ceil(Math.random() * 28))));
        return Promise.resolve<ExpiringPointsResponseDTO>(result);
    }

    getAccountBalanceAppUsingPOST(membershipid: number, body: GetAccountBalanceRequestDTO): Promise<AccountBalanceResponseDTO> {
        var result = new AccountBalanceResponseDTO();
        result.accountId = membershipid;
        result.collectedPoints = Math.ceil(Math.random() * 50000) + 10000;
        result.endBalance = result.collectedPoints - Math.ceil(Math.random() * 20000);
        result.startDate = body.startDate;
        result.endDate = body.endDate;
        return Promise.resolve<AccountBalanceResponseDTO>(result);
    }

    queryAccountStatementAppUsingPOST(membershipid: number, body: QueryAccountStatementRequestDTO): Promise<AccountStatementResponseDTO> {
        let result = new AccountStatementResponseDTO();
        result.postings = new Array<PostingDTO>();
        result.accountId = membershipid;
        result.pageNumber = body.pageNumber;
        result.pageResultQuantity = body.pageQuantity;
        result.totalResultQuantity = Math.ceil(Math.random() * 250);

        for (var i = 1; i <= body.pageQuantity; i++) {
            var posting = new PostingDTO();
            posting.description = (i * (body.pageNumber + 1)) % 6 == 0 ? 'Bestellung ' + Math.ceil((Math.random() * 1000000)) : loremIpsum({ count: Math.ceil(Math.random() * 10), units: "words", format: "plain" });
            posting.postingType = (i * (body.pageNumber + 1)) % 6 == 0 ? 'DEBIT' : 'CREDIT';
            posting.transactionDate = moment(new Date((Math.ceil(Math.random() * 5) + 2015), (Math.ceil(Math.random() * 12)), (Math.ceil(Math.random() * 28))));
            posting.value = (i * (body.pageNumber + 1)) % 6 == 0 ? Math.ceil(Math.random() * 20) * 150 : Math.ceil(Math.random() * 20) * 50;
            result.postings.push(posting);
        }

        return Promise.resolve<AccountStatementResponseDTO>(result);
    }

    private _initTenants() {
        this.mockTenants = [];
        this.mockTenants.push(this._newTenant("bayern", "AOK Bayern", "01", ["PREMIUM"], ["S00"]));
        this.mockTenants.push(this._newTenant("nordwest", "AOK NordWest", "03", ["PREMIUM", "FAMILY_BONUS"], ["S00", "F01"]));
        this.mockTenants.push(this._newTenant("baden_wuerttemberg", "AOK Baden-Württemberg", "12", ["PREMIUM"], ["S01"]));
    }

    private _newTenant(key: string, name: string, tenantId: string, programs: string[], programType: string[]): TenantDTO {
        let tenant = new TenantDTO();
        tenant.ftamid = "ftam" + name;
        tenant.id = Math.random() * 100;
        tenant.insuranceId = "" + Math.random() * 100;
        tenant.key = key;
        tenant.name = name;
        tenant.tenantId = tenantId;

        let vm = this;
        tenant.programs = [];
        var index = 0;
        programs.forEach(p => {
            tenant.programs.push(vm._newLP(p, programType[index]));
            index++;
        });

        return tenant;
    }

    private _newLP(name: string, type: string): LoyaltyProgramDTO {
        let lp = new LoyaltyProgramDTO();
        lp.id = Math.random() * 100;
        lp.name = name;
        lp.useActivityLevels = false;
        lp.programType = type;

        return lp;
    }

    private _initFaqs() {
        this.mockFaqs = [];
        this.mockFaqs.push(this._newFaq("2020-01-01", "2020-12-31", "Category_1", "Question_1", "<p>Some Text</p>"));
        this.mockFaqs.push(this._newFaq("2020-01-01", "2020-12-31", "Category_1", "Question_2", '\<p\>Some Text for question 2\</p\>'))
        this.mockFaqs.push(this._newFaq("2020-01-01", "2020-12-31", "Category_1", "Question_3", "<p>Some Text for question 3</p>"))
        this.mockFaqs.push(this._newFaq("2020-01-01", "2020-12-31", "Category_2", "Question_4", "<p>Some Text for question 4</p>"))
        this.mockFaqs.push(this._newFaq("2020-01-01", "2020-12-31", "Category_2", "Question_5", "<p>Some Text for question 5</p>"))
        this.mockFaqs.push(this._newFaq("2020-01-01", "2020-12-31", "Category_3", "Question_6", "<p>Some Text for question 6</p>"))
    }

    private _newFaq(validFrom: string, validTo: string, category: string, question: string, formattedAnswer: string) {
        let faq = new LoyaltyProgramFAQDTO();
        faq.category = category;
        faq.question = question;
        faq.formattedAnswer = formattedAnswer;
        return faq;
    }


    private _initParticipantAnnouncements() {
        this.mockAnnouncements = [];
        this.mockAnnouncements.push(this._newAnnouncement("Bestellung", moment({ year: 2021, month: 1, day: 26 }), "Ihre Bestellung verzögert sich", true));
        this.mockAnnouncements.push(this._newAnnouncement("Maßnahme abgelehnt", moment({ year: 2021, month: 0, day: 26 }), "Ihre Maßnahme XY wurde abgelehnt, da auf anderen Namen ausgestellt", true));
        this.mockAnnouncements.push(this._newAnnouncement("Ankündigung Punkteverfall", moment({ year: 2020, month: 5, day: 30 }), "Am 30.09.2020 werden bei Ihnen 400 Punkte verfallen", true));
        this.mockAnnouncements.push(this._newAnnouncement("Maßnahme abgelehnt", moment({ year: 2020, month: 0, day: 26 }), "Ihre Maßnahme XY wurde abgelehnt, da auf anderen Namen ausgestellt", false));
        this.mockAnnouncements.push(this._newAnnouncement("Bestellung", moment({ year: 2019, month: 1, day: 26 }), "Ihre Bestellung verzögert sich", false));
        this.mockAnnouncements.push(this._newAnnouncement("Ankündigung Punkteverfall", moment({ year: 2018, month: 5, day: 30 }), "Am 30.09.2020 werden bei Ihnen 400 Punkte verfallen", false));
        this.mockAnnouncements.push(this._newAnnouncement("Maßnahme abgelehnt", moment({ year: 2018, month: 0, day: 26 }), "Ihre Maßnahme XY wurde abgelehnt, da auf anderen Namen ausgestellt", false));
        this.mockAnnouncements.push(this._newAnnouncement("Bestellung", moment({ year: 2017, month: 1, day: 26 }), "Ihre Bestellung verzögert sich", false));

    }
    private _newAnnouncement(category: string, date: Moment, text: string, isNew: boolean): ParticipantAnnouncementDTO {
        let news = new ParticipantAnnouncementDTO();
        news.category = category;
        news.date = date;
        news.text = text;
        news.new = isNew;
        return news;
    }

    private _initActivityPrototypes() {
        this.mockActivityPrototype = [];
        this.mockActivityPrototype.push(this._newActivityPrototype("Erwachsene", "Fitness", "Sie treiben aktiv Sport und lieben die Gemeinschaft im Sportverein? Egal welchen Sport Sie im Verein ausüben, das ist uns 200 Punkte wert.", "200 Punkte - für ihre Aktivität", moment(new Date(2020, 0, 1)), moment(new Date(2030, 0, 1))))
        this.mockActivityPrototype.push(this._newActivityPrototype("Erwachsene", "Regionales", "Ausdauer, Kraft oder Koordination. Im Fitness-Studio gibt’s für jeden Wunsch das Richtige. Ob an Geräten oder im Kurs. Wenn Sie regelmäßig Ihr Fitnessstudio nutzen, bekommen Sie 200 Prämienpunkte.", "200 Punkte fürs FitnessStudio", moment(new Date(2020, 0, 1)), moment(new Date(2030, 0, 1))))
        this.mockActivityPrototype.push(this._newActivityPrototype("Erwachsene", "Fitness", "Ihr großer Vorteil bei der NordWest: Sie haben ab dem 20. Geburtstag alle zwei Jahre Anspruch auf ein kostenloses Hautkrebs-Screening.", "300 Punkte für...", moment(new Date(2020, 0, 1)), moment(new Date(2030, 0, 12))))
        this.mockActivityPrototype.push(this._newActivityPrototype("Kinder", "Fitness", "Reitsportverein oder Fußball-Club. Tanzgruppe oder Taekwondo. Ganz gleich, welchen Sport Ihr Sprössling im Verein macht, 200 Punkte dafür sind sicher.", "400 Punkte für ...", moment(new Date(2020, 0, 1)), moment(new Date(2021, 11, 31))))
        this.mockActivityPrototype.push(this._newActivityPrototype("Kinder", "Regionales", "Regionales 1", "200 Punkte für dies", moment(new Date(2020, 0, 1)), moment(new Date(2030, 0, 1))))
        this.mockActivityPrototype.push(this._newActivityPrototype("Kinder", "Regionales", "Regionales 2", "300 Punkte für das", moment(new Date(2020, 0, 1)), moment(new Date(2030, 1, 1))))
        this.mockActivityPrototype.push(this._newActivityPrototype("XXX", "XXX", "xyz", "300 Punkte für das", moment(new Date(2030, 0, 1)), moment(new Date(2030, 1, 1))))
        this.mockActivityPrototype.push(this._newActivityPrototype("XXX", "XXX", "ttt", "300 Punkte für das", moment(new Date(2020, 0, 1)), moment(new Date(2020, 11, 1))))
    }

    private _newActivityPrototype(category: string, categoryExternal: string, formattedDescription: string,
        nameExternal: string, validFrom: Moment, validTo: Moment) {
        let ap = new LoyaltyProgramActivityPrototypeDTO();
        ap.category = category;
        ap.categoryExternal = categoryExternal;
        ap.formattedDescription = formattedDescription;
        ap.nameExternal = nameExternal;
        ap.validFrom = validFrom;
        ap.validTo = validTo;
        return ap;
    }


    private _initNews() {
        this.mockNews = [];

        for (var i = 0; i < Math.ceil(Math.random() * 12) + 1; i = i + 4) {

            this.mockNews.push(this._newNews("#wirbleibenzuhause", i + 1, "",
                "Die Corona-Pandemie hat den Alltag der Menschen in Deutschland komplett verändert. Wer kann, bleibt zu Hause. Auch wir haben auf die aktuelle Lage reagiert.",
                "Punkte sammeln mit CyberFitness", (i % 4) + 1, "CyberFitness"));

            this.mockNews.push(this._newNews("Prämien", i + 2, "",
                "Für gesunde Zähne ist eine regelmäßige Vorsorge wichtig. Viele Zahnärzte empfehlen deshalb zusätzlich zur jährlichen Prophylaxe eine professionelle Zahnreinigung.",
                "Gesunde Sache!", ((i + 1) % 4) + 1, "Prämien"));

            this.mockNews.push(this._newNews("Scheckheft", i + 3, "",
                "Die Corona-Pandemie hat den Alltag der Menschen in Deutschland komplett verändert. Wer kann, bleibt zu Hause. Auch wir haben auf die aktuelle Lage reagiert.",
                "Steigen Sie um auf das komfortable Online-Scheckheft!", ((i + 2) % 4) + 1, "Scheckheft"));

            this.mockNews.push(this._newNews("AOK News " + i, i + 4, "",
                "Kurze Beschreibung: Lorem ipsum und alle Taste - qwertz uiopü asdf ghjkl öäyx cvbnm",
                "Nachrichten-Titel", ((i + 3) % 4) + 1, "AOK News"));
        }
    }

    private _newNews(category: string, id: number, formattedContent: string, shortDescription: string, title: string, resourceId: number, resourceDescription: string) {
        var news = new LoyaltyProgramNewsDTO();
        news.category = category;
        news.id = id;
        news.formattedContent = formattedContent;
        news.shortDescription = shortDescription;
        news.title = title;
        news.validFrom = moment(new Date(2021, Math.floor(Math.random() * 3) + 1, Math.floor(Math.random() * 27) + 1));
        news.thumbnail = new ResourceReferenceDTO();
        news.thumbnail.id = resourceId;
        news.thumbnail.alternativeText = resourceDescription;
        return news;
    }

    private _initRewardGroups() {
        this.mockRewardsGroup = [];

        this.mockRewardsGroup.push(this._newRewardGroup(RewardGroupDTOGroup.Family, RewardGroupDTOGroup.Family.toString(),
            "Tolle Ideen für Kinder und Eltern: elektrische Zahnbürste für Kinder, sportliche Laufräder, Spiele für Klein und Groß sowie drinnen und draußen und vieles mehr.", "", 1, "Familie"));

        this.mockRewardsGroup.push(this._newRewardGroup(RewardGroupDTOGroup.Fun, RewardGroupDTOGroup.Fun.toString(),
            "Tolle Ideen für Spaß und Freiheit: Spaß und Freiheit Angeboten und vieles mehr.", "", 3, "Fun"));

        this.mockRewardsGroup.push(this._newRewardGroup(RewardGroupDTOGroup.Health, RewardGroupDTOGroup.Health.toString(),
            "Tolle Ideen für Gesundheit und Wellness: Gesundheit und Wellness Angeboten und vieles mehr.", "", 2, "Gesund"));

        this.mockRewardsGroup.push(this._newRewardGroup(RewardGroupDTOGroup.Nutrition, RewardGroupDTOGroup.Nutrition.toString(),
            "Tolle Ideen für Kochen und Genuss: Kochen und Genuss Angeboten mit extraordinary Kochbuch von Koch experten mit viele Ernährung und vieles mehr.", "", 4, "Kochen und Genuss"));

        this.mockRewardsGroup.push(this._newRewardGroup(RewardGroupDTOGroup.Money, RewardGroupDTOGroup.Money.toString(),
            "Tolle Ideen für Gutschein und Geldprämie: Gutschein und Geldprämie bekommen und vieles mehr.", "", 3, "Money"));

        this.mockRewardsGroup.push(this._newRewardGroup(RewardGroupDTOGroup.Sports, RewardGroupDTOGroup.Sports.toString(),
            "Fit und mobil durch das Jahr: Pulsmesser, Gutscheine, Taschen von reisenthel®, moderne Trinkgefäße, kabellose Kopfhörer und vieles mehr.", "", 1, "Sports"));

        this.mockRewardsGroup.push(this._newRewardGroup(RewardGroupDTOGroup.RegionalPromotion, RewardGroupDTOGroup.RegionalPromotion.toString(),
            "Hier finden Sie besonders attraktive Prämien und Geldprämien für ihre Region", "", 2, "Regional Promotion"));
    }

    private _newRewardGroup(group: RewardGroupDTOGroup, key: string, shortDescription: string, title: string, resourceId: number, resourceDescription: string) {
        var rewardGroup = new RewardGroupDTO();
        rewardGroup.group = group;
        rewardGroup.key = key;
        rewardGroup.shortDescription = shortDescription;
        rewardGroup.thumbnail = new ResourceReferenceDTO();
        rewardGroup.thumbnail.id = resourceId;
        rewardGroup.thumbnail.alternativeText = resourceDescription;
        return rewardGroup;
    }

    private _initRewards(group: ExtendedRewardDTOGroup) {
        this.mockRewards = [];

        for (var i = 1; i <= Math.ceil(Math.random() * 40) + 8; i++) {
            this.mockRewards.push(this._generateReward(i, group));
        }
    }

    private _generateReward(id: number, group: ExtendedRewardDTOGroup) {
        return this._newReward(id, "Prämie " + id, (Math.ceil(Math.random() * 150) + 1) * 100, group, "Prämie " + id + " : Sie ist es eine tolle Prämie!",
            ExtendedRewardDTOGroup[this.randomEnumKey(ExtendedRewardDTOGroup)], Math.floor(Math.random() * 4) + 7, "Prämie " + id,
            new Date(2021, Math.floor(Math.random() * 1) + 1, Math.floor(Math.random() * 27) + 1),
            new Date(2021, Math.floor(Math.random() * 12) + 1, Math.floor(Math.random() * 27) + 1),
            Math.floor(Math.random() * (15 - 2) + 2),
            Math.floor(Math.random() * (999 - 100) + 100))
    }

    private _newReward(id: number, name: string, value: number, group: ExtendedRewardDTOGroup, description: string, type: ExtendedRewardDTOType, resourceId: number, resourceDescription: string, validFrom: Date, validTo: Date, countSentences: number, articleNumber: number) {
        var reward = new ExtendedRewardDTO();
        reward.id = id;
        reward.name = name;
        reward.value = value;
        reward.group = group;
        reward.description = description;
        reward.formattedDescription = "<p>Das SmartFit™-Modell der Fiskars Gartenscheren ist mit dem Red Dot Design Award 2013 ausgezeichnet. Die 21 Zentimeter lange Bypass-Gartenschere ist ein echtes Multitalent: Mit ihrer verstellbaren Grifföffnung, passt sie sich perfekt an Ihre individuelle Handgröße und die verschiedenen Anwendungen an. So ist sie optimal für das schnelle Schneiden von dünnen sowie für das kräftige Schneiden von bis zu 24 Millimeter dicken, frischen Ästen und Zweigen einsetzbar. Die Griffe der SmartFit™ Bypass-Gartenschere bestehen aus einem mit Glasfasern verstärkten Kunststoff. Damit Ihnen diese Fiskars Gartenschere außerdem einen sicheren und angenehmen Halt bietet, ist ihr oberer Griff mit einer rutschfesten Oberfläche versehen. Ob links oder rechts – dieses Prachtstück liegt dank ihrer ergonomischen Form einfach immer gut in der Hand und ist mit einem Gewicht von 192 Gramm angenehm leicht. Ist die Schere nicht mehr in Gebrauch, können Sie diese mit nur einer Hand schnell und sicher verschließen. </p><p></p><p><b>Produkt Details: </b> </p><p></p><ul class=\"list\"><li> Maße (LxHxB): 23,8 x 2,5 x 10,8 cm </li><li> Gewicht: 192 g </li><li> Material: Kunststoff, Metall </li><li> Farbe: Schwarz, Orange </li> </ul>"; // loremIpsum({count:countSentences, units:"sentences", format:"html" });
        reward.type = type;
        reward.articleNumber = "AOK-" + articleNumber;
        reward.validFrom = moment(validFrom);
        reward.validTo = moment(validTo);
        reward.thumbnail = new ResourceReferenceDTO();
        reward.thumbnail.id = resourceId;
        reward.thumbnail.alternativeText = resourceDescription;
        reward.articleNumber = id.toString();
        reward.resources = []; //.push(new ResourceReferenceDTO({alternateText:""}));
        reward.resources = this._newResourceReferences();
        return reward;
    }

    private _newResourceReferences() {
        let result = [];
        for (var i = 5; i <= 7; i++) {
            var reference = new ResourceReferenceDTO();
            reference.id = i;
            reference.alternativeText = loremIpsum({ count: 5, units: "words", format: "plain" });
            reference.filename = loremIpsum({ count: 1, units: "words", format: "plain" }) + ".png";
            result.push(reference)
        }
        return result;
    }


    async requestConfigurationAppUsingGET(request: string): Promise<ConfigurationDTO[]> {
        switch (request) {
            case 'membershipstatus':
                return [
                    new ConfigurationDTO({ key: "key1", displayValue: "config1" }),
                    new ConfigurationDTO({ key: "key2", displayValue: "config2" })
                ];
            case 'rewardgroups':
                return [
                    new ConfigurationDTO({ key: "Sports", displayValue: "Sport und Freizeit" }),
                    new ConfigurationDTO({ key: "Family", displayValue: "Familie & Kind" }),
                    new ConfigurationDTO({ key: "Health", displayValue: "Gesundheit und Wellness" }),
                    new ConfigurationDTO({ key: "Nutrition", displayValue: "Kochen und Genuss" }),
                    new ConfigurationDTO({ key: "Electronics", displayValue: "Multimedia und Technik" }),
                    new ConfigurationDTO({ key: "Fun", displayValue: "Spiel und Spaß" }),
                    new ConfigurationDTO({ key: "Donation", displayValue: "Prämien Spenden" }),
                    new ConfigurationDTO({ key: "Money", displayValue: "Gutschein und Geldprämie" }),
                    new ConfigurationDTO({ key: "Subvention", displayValue: "Prämie für Gesundheitsleistungen" }),
                    new ConfigurationDTO({ key: "BayernSpecial", displayValue: "Bayern Spezial" }),
                    new ConfigurationDTO({ key: "PromotionSpecial", displayValue: "Aktionprämie Spezial" }),
                    new ConfigurationDTO({ key: "FamilyBonus", displayValue: "Familien-/Kinderbonus" }),
                    new ConfigurationDTO({ key: "RegionalPromotion", displayValue: "Aktionen & Regionales" })
                ];
            case 'orderitemdtostatus':
                return [
                    new ConfigurationDTO({ key: "CREATED", displayValue: "erstellt" }),
                    new ConfigurationDTO({ key: "DELIVERED", displayValue: "geliefert" }),
                    new ConfigurationDTO({ key: "RETURNED", displayValue: "zurückgesandt" }),
                    new ConfigurationDTO({ key: "RESERVED", displayValue: "reserviert" }),
                    new ConfigurationDTO({ key: "REVIEW", displayValue: "überprüfen" }),
                    new ConfigurationDTO({ key: "CANCELLED", displayValue: "storniert" }),
                ];
            case 'orderitemdtotype':
                return [
                    new ConfigurationDTO({ key: "PHYSICAL", displayValue: "Physikalisch" }),
                    new ConfigurationDTO({ key: "MONEY", displayValue: "Geld" }),
                    new ConfigurationDTO({ key: "COUPON", displayValue: "Kupon" }),
                    new ConfigurationDTO({ key: "DONATION", displayValue: "Spende" }),
                    new ConfigurationDTO({ key: "GIFT", displayValue: "Geschenk" }),
                    new ConfigurationDTO({ key: "SUBVENTION", displayValue: "Subvention" }),
                ];
        }
        return [];
    }

    async getAllTenantsUsingGET(): Promise<TenantDTO[]> {
        return this.mockTenants;
    }

    async getTenantsByTariffUsingGET(type: string): Promise<TenantDTO[]> {
        let result = this.mockTenants.filter(t => t.programs.find(p => p.programType === type));
        return result.sort((t1, t2) => t1.name.localeCompare(t2.name));
    }

    // params 
    async getFAQUsingGET(loyaltyProgramID: number): Promise<LoyaltyProgramFAQDTO[]> {
        let result = this.mockFaqs;
        return result.sort((t1, t2) => t1.category.localeCompare(t2.category));
    }

    async getNewsRecordUsingGET(id: number): Promise<LoyaltyProgramNewsDTO> {
        let news = this.mockNews.filter(n => n.id == id)[0];

        if (!news) {
            news = new LoyaltyProgramNewsDTO();
            news.category = "Nachrichten-Kategorie"
            news.id = id;
            news.title = "Nachrichten-Titel";
            news.shortDescription = "Kurze Beschreibung";
            news.validFrom = moment(new Date(2021, Math.floor(Math.random() * 3) + 1, Math.floor(Math.random() * 27) + 1));
        }

        news.formattedContent = '<p><b><font color="#F18E0B">Neue attraktive Prämien:</font></b> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.</br>At vero eos et accusam et justo duo dolores et ea rebum. </p>';
        return news;

    }

    async getNewsUsingGET(loyaltyProgramID: number): Promise<LoyaltyProgramNewsDTO[]> {
        // not implemented yet
        let result = this.mockNews;
        return result;
    }

    async listByLoyaltyProgramAndGroup(id: number, groupKey: string, request: QueryRewardsDTO): Promise<ExtendedRewardDTO[]> {
        this._initRewards(ExtendedRewardDTOGroup[groupKey]);
        let result = this.mockRewards;

        if (request.onlyValid == true) {
            result = this.mockRewards.filter(r => r.validTo >= moment())
        }

        return result;
    }

    async getRewardById(id: number): Promise<ExtendedRewardDTO> {
        this.mockRewards = [];
        this.mockRewards.push(this._generateReward(id, ExtendedRewardDTOGroup.Fun));
        let result = this.mockRewards[0];
        return result;
    }
    getRewardGroupsUsingGET(tenant: number, loyaltyProgramID: number): Promise<RewardGroupDTO[]> {
        // not implemented yet
        var result: RewardGroupDTO[] = this.mockRewardsGroup;
        return Promise.resolve<RewardGroupDTO[]>(result);
    }
    // not implemented yet
    async listGroups(id: number): Promise<RewardGroupDTO[]> {
        var result: RewardGroupDTO[] = this.mockRewardsGroup;
        return Promise.resolve<RewardGroupDTO[]>(result);
    }

    async listByLoyaltyProgram(id: number, request: QueryRewardsDTO): Promise<ExtendedRewardDTO[]> {
        throw new Error("Method not implemented.");
    }

    getNewsById(id: number): Promise<any> {
        throw new Error("Method not implemented.");
    }

    helloUsingGET(): Promise<string> {
        return undefined;
    }

    async getActivityPrototypesUsingGET(loyaltyProgramID: number): Promise<LoyaltyProgramActivityPrototypeDTO[]> {
        let result = this.mockActivityPrototype;
        return result.sort((t1, t2) => t1.category.localeCompare(t2.category));
    }

    updateCheckbookDeliveryUsingPOST(id: number, request: UpdateChequebookDeliveryDTO): Promise<void> {
        if (request.chequebookDelivery == MembershipAokDTOChequebookDelivery.ONLINE.toString()) {
            if (!request.email) {
                throw new Error("Email nicht gültig");
            }
        }

        return;
    }

    getCommunityMemberUsingPOST(id: number): Promise<CommunityMemberDTO> {
        let numSubInsurer = Math.floor(Math.random() * 6);

        var result = new CommunityMemberDTO();

        result.city = "Stuttgart";
        result.firstname = numSubInsurer == 1 ? "Luke" : "Anakin";
        result.namePrefix = numSubInsurer == 1 ? "Jedi" : "Vader";
        result.lastname = "Skywalker";
        result.kvnr = Math.random().toString(36).toUpperCase();
        result.street = "Skywalker str.";
        result.streetNo = (Math.floor(Math.random() * 99) + 1).toString();
        result.zip = "70173";
        result.addressSupplement = "";
        result.salutation = "Herr";
        result.title = "";
        result.countryCode = "DE";
        result.membership = null;
        result.mainCollector = numSubInsurer != 1;
        result.additionalInsured = [];
        result.membership = new MembershipAokDTO();
        result.membership.chequebookDelivery = MembershipAokDTOChequebookDelivery[this.randomEnumKey(MembershipAokDTOChequebookDelivery)];
        result.membership.loyaltyProgramId = id;
        result.membership.membershipId = 1;
        result.membership.startDate = moment(new Date(Math.ceil(Math.random() * 19 + 2000), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)));

        if (result.membership.chequebookDelivery == MembershipAokDTOChequebookDelivery.ONLINE) {
            result.membership.email = "skywalker@email.com";
        }

        for (var i = 0; i < numSubInsurer; i++) {
            var subInsurer = new RelatedCommunityMemberDTO();
            subInsurer.firstname = i % 2 == 0 ? "Leia " + i : "Luke " + i;
            subInsurer.lastname = "Skywalker";
            subInsurer.kvnr = Math.random().toString(36).toUpperCase();
            subInsurer.salutation = i % 2 == 0 ? RelatedCommunityMemberDTOSalutation.FEMALE : RelatedCommunityMemberDTOSalutation.MALE;
            subInsurer.title = "";
            subInsurer.mainCollector = numSubInsurer == 1;
            result.additionalInsured.push(subInsurer);
        }

        return Promise.resolve<CommunityMemberDTO>(result);
    }

    getMainInsuredUsingGET(id: number): Promise<PhvDTO> {
        let numSubInsurer = Math.floor(Math.random() * 6);

        var result = new PhvDTO();

        result.city = "Hagen";
        result.firstname = "Clark";
        result.lastname = "Kent";
        result.namePrefix = "Superman";
        result.kvnr = Math.random().toString(36).toUpperCase();
        result.street = "der Clark-Kent str.";
        result.streetNo = (Math.floor(Math.random() * 99) + 1).toString();
        result.zip = "58089";
        result.addressSupplement = "";
        result.salutation = "Herr";
        result.title = "";
        result.membership = null;
        result.additionalInsured = [];
        result.membership = new MembershipAokDTO();
        result.membership.chequebookDelivery = MembershipAokDTOChequebookDelivery[this.randomEnumKey(MembershipAokDTOChequebookDelivery)];
        result.membership.loyaltyProgramId = id;
        result.membership.membershipId = 1;
        result.membership.startDate = moment(new Date(Math.ceil(Math.random() * 19 + 2000), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)));

        if (result.membership.chequebookDelivery == MembershipAokDTOChequebookDelivery.ONLINE) {
            result.membership.email = "clark.kent@email.com";
        }

        for (var i = 0; i < numSubInsurer; i++) {
            var subInsurer = new PmvDTO();
            subInsurer.city = "Hagen";
            subInsurer.firstname = i % 2 == 0 ? "Rose " + i : "Clark " + i;
            subInsurer.lastname = "Kent";
            subInsurer.kvnr = Math.random().toString(36).toUpperCase();
            subInsurer.street = "der Clark-Kent str.";
            subInsurer.streetNo = result.streetNo;
            subInsurer.zip = "87533";
            subInsurer.addressSupplement = "";
            subInsurer.salutation = i % 2 == 0 ? "Frau" : "Herr";
            subInsurer.title = "";
            subInsurer.membership = new MembershipAokDTO();
            subInsurer.membership.loyaltyProgramId = id;
            subInsurer.membership.membershipId = i + 2;
            subInsurer.membership.startDate = moment(new Date(Math.ceil(Math.random() * 19 + 2000), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)));

            if (i % 3 == 0) {
                subInsurer.membership.endDate = moment(new Date(Math.ceil(Math.random() * 21 + 2000), Math.floor(Math.random() * 12), Math.floor(Math.random() * 28)));
            }

            result.additionalInsured.push(subInsurer);
        }

        return Promise.resolve<PhvDTO>(result);
    }

    createRegistrationRequestUsingPOST(pid: number, requestDTO: RegistrationFormRequestDTO): Promise<RegistrationFormResultDTO> {
        if (pid === undefined || pid === null) {
            throw new Error("The parameter 'pid' must be defined.");
        }
        var num = Math.ceil(Math.random() * 2);


        if (num === 1) {
            let result200: any = null;
            result200 = [] as any;
            let resultData200: RegistrationFormResultDTO = new RegistrationFormResultDTO();
            resultData200.addressValidationResult = RegistrationFormResultDTOAddressValidationResult.ADDRESS_OK;
            resultData200.participantId = Math.floor(Math.random() * 50);
            result200.push(resultData200);
            return result200;
        }
        else {
            let response = new Response();
            return response.text().then((_responseText) => {
                let result400: any = null;
                //let resultData400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result400 = "Result400 Text";
                return throwException("Bad Request", 400, _responseText, null, result400);
            });
        }
    }

    sendFeedbackUsingPOST(id: number, feedbackDTO: FeedbackDTO): Promise<void> {

        return Promise.resolve<void>(<any>null);
    }

    getChequeBookAppUsingGET(membershipId: number | null | undefined, year: number | null | undefined): Promise<ChequeBookDTO> {
        var result = new ChequeBookDTO();
        result.year = year;
        result.activities = new Array<ActivityDTO>();
        var status: string[] = ['Locked', 'Accepted', 'Modified', 'Rejected', 'Open'];
        var category: string[] = ['Fitness', 'Vorsorge', 'Regional', 'Familie', 'Gesundheit'];

        var numActivites = Math.ceil(Math.random() * 10);

        for (var i = 1; i <= numActivites; i++) {
            var activity = new ActivityDTO();
            activity.id = year * 10 + i;
            activity.category = category[Math.floor(Math.random() * 5)];
            activity.name = "Maßnahme " + activity.id;
            activity.value = Math.ceil(Math.random() * 20) * 50;
            activity.chequeDocumentDetails = new ChequeDocumentDetailsDTO();
            activity.chequeDocumentDetails.description = loremIpsum({ count: Math.ceil(Math.random() * 10), units: "words", format: "plain" });
            activity.request = new Array<RedemptionRequestDTO>();
            var redemption = new RedemptionRequestDTO();
            redemption.id = Math.ceil(Math.random() * 10000);
            redemption.status = status[Math.floor(Math.random() * 5)];
            activity.request.push(redemption);
            result.activities.push(activity);
        }

        return Promise.resolve<ChequeBookDTO>(result);
    }

    confirmOptinTokenUsingGET(lp: number, hashValue: string): Promise<void> {
        if (hashValue.length < 5)
            throw "UUID muus mindestens 5 Zeichen lang sein.";
        return Promise.resolve<void>(<any>null);
    }

    redeemChequeBookActivityUsingPOST(id: number, activityId: number, activityRedemptionRequest: ActivityRedemptionRequestDTO): Promise<ActivityDTO> {
        if (activityRedemptionRequest.fileAttachments.length == 0) throw "No Files";
        if (activityId % Math.ceil(Math.random() * 10) == 0) throw "Random failed request";
        return Promise.resolve<ActivityDTO>(new ActivityDTO());
    }

    checkKvnrUsingGET(id: number, kvnr: string): Promise<GetStatusResultDTO> {
        let result = new GetStatusResultDTO();
        result.alreadyRegistered = (Math.ceil(Math.random() * 4)) % 2 == 0;
        result.onlineAccessExists = (Math.ceil(Math.random() * 4)) % 2 == 0;
        return Promise.resolve<GetStatusResultDTO>(result);
    }

    createOnlineAccessUsingPOST(id: number, request: CreateOnlineAccessRequestDTO): Promise<void> {
        var number = Math.ceil(Math.random() * 3);
        var errorCodes = ["Participant Not Found Exception", "Participant Is Not Active Exception", "Participant Is PMV Exception", "Service Not Available",
            "Online Access Already Exists Exception", "TENANT_DOES_NOT_SUPPORT_LOYALTYPROGRAM", "MANDATORY_FIELDS_MISSING", "Sync in Process Excepetion", "kvnr already in Use Exception"];

        if (number == 2) {
            var err = new ErrorDTO();
            err.errorCode = errorCodes[Math.floor(Math.random() * 9)];
            err.errorMessages = errorCodes;
            err.exception = errorCodes[Math.floor(Math.random() * 9)];
            throw err;
        }

        return Promise.resolve<void>(<any>null);
    }

    randomEnumKey = enumeration => {
        try {
            const keys = Object.keys(enumeration)
                .filter(k => !(Math.abs(Number.parseInt(k)) + 1));
            const enumKey = keys[Math.floor(Math.random() * keys.length)];
            return enumKey;
        }
        catch {
            return 0;
        }
    };

    postAppUsageUsingPOST(): Promise<void> {
        return Promise.resolve(undefined);
    }

    getCommunityMemberUsingGET(id: number): Promise<CommunityMemberDTO> {
        return Promise.resolve(undefined);
    }

    queryInvoiceDocumentsForOrderUsingGET(membershipid: number, orderId: number): Promise<RedeemSubventionRequestDTO> {
        return Promise.resolve(undefined);
    }

    queryParticipantLetterForSubventionOrderUsingGET(membershipid: number, orderId: number): Promise<string> {
        return Promise.resolve("");
    }
}
export class SwaggerException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isSwaggerException = true;

    static isSwaggerException(obj: any): obj is SwaggerException {
        return obj.isSwaggerException === true;
    }
}

function throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
    if (result !== null && result !== undefined)
        throw result;
    else
        throw new SwaggerException(message, status, response, headers, null);
}