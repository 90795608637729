import { ParticipantNewsViewModel } from './../view-models/participant-news-view-model';
import { ParticipantNewsService } from './../service/participant-news/participant-news-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PageBase } from "./contents/Base/page-base";
import { autoinject } from 'aurelia-framework';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { ParticipantNews } from "../view-models/participant-news-view-model";
import { Constants } from '../constants/constants';
import { AuthService } from '../service/auth/auth-service';


@autoinject()
export class TeilnehmerNews extends PageBase {
    private subscriptions: any[] = [];

    public participantNews: ParticipantNews[] = null;
    public itemCount: number = 0;
    public pageSize: number = 6;

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private eventAggregator: EventAggregator, private participantNewsService: ParticipantNewsService,
        private auth: AuthService,) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.subscriptions.push(this.eventAggregator.subscribe(Constants.pageChangedEvent, (data) => { this.getParticipantNews(data); }));
    }

    async attached() {
        var particpantNewViewModel = await this.participantNewsService.getParticipantNews(0, this.pageSize);
        this.participantNews = particpantNewViewModel.news;
        this.participantNews.forEach(x => x.text = this.replaceAllNewLines(x.text));
        this.itemCount = particpantNewViewModel.totalNewsCount;

    }


    async detached() {
        await this.eventAggregator.publish(Constants.particpantNewsChangedEvent);
        this.subscriptions.forEach(subscription => {
            subscription.dispose();
        });
    }

    private async getParticipantNews(data: any) {
        var size = data.pageSize;
        var particpantNewViewModel = await this.participantNewsService.getParticipantNews(data.pageIndex, size);
        if (particpantNewViewModel != null && particpantNewViewModel.news != null) {
            this.participantNews = particpantNewViewModel.news;
            this.participantNews.forEach(x => x.text = this.replaceAllNewLines(x.text));
        }
    }

    private replaceAllNewLines(text: string): string {
        return text.replace(new RegExp('\n', 'g'), "<br>");
    }
}

