import { autoinject, computedFrom } from 'aurelia-framework';
import { UILayoutService } from "../../../service/ui/ui-layout-service";
import { TenantContext } from "../../../service/tenant/tenant-context";
import { UiNavigationService } from "../../../service/ui/ui-navigation-service";

@autoinject()
export class AokFooter {

    constructor(private layout: UILayoutService, private tenantContext: TenantContext, public nav: UiNavigationService) {
    }

    @computedFrom('tenantContext', 'tenantContext.current')
    get currentTenant(): string {
        if (this.tenantContext && this.tenantContext.isTenantSet()) {
            return this.tenantContext.getTenant().tenantId;
        }
        return "";
    }

    private jetztRegistrieren(){
        console.log("tenant: " + this.tenantContext.getTenant().programs[0].id);
        if(this.tenantContext.getTenant().programs[0].id == 3){
            console.log("hier");
            this.nav.navigate('news', { id: 439609499 });
        }
        else if(this.tenantContext.getTenant().programs[0].id == 15){
            this.nav.navigate('news', { id: 439609631 });
        }
        else{
            this.nav.navigate('mitteilung');
        }
    }

}