import { Constants } from './../../constants/constants';
import { autoinject, FrameworkConfiguration, Lazy, inject } from 'aurelia-framework';
import { UserManager, UserManagerSettings } from 'oidc-client';
import { Router } from 'aurelia-router';
import { EventAggregator } from 'aurelia-event-aggregator';
import { EnvironmentConfigurationService } from "../configuration/environment-configuration-service";
import { HashTable } from "voltospa/models/general/hashTable";
import { AuthenticationProvider, ConfigProvider } from "voltospa";
import { TenantContext } from '../tenant/tenant-context';
import { TenantService } from '../tenant/tenant-service';
import { StorageKey, StorageService } from '../storage/storage-service';
import { TenantViewModel } from '../../view-models/tenant-view-models';
import { Container } from 'aurelia-dependency-injection';
import { TenantMapping } from '../../mappings/tenant-mapping';




@autoinject()
export class AokAuthenticationProvider extends AuthenticationProvider {

    private subscriptions: Array<any>;
    private userManager: UserManager;

    public constructor(private configProvider: EnvironmentConfigurationService, public router: Router, public eventAggregator: EventAggregator,
                       public tenantProvider: TenantContext, public storage: StorageService, private config: ConfigProvider,
                       private container: Container) {
        super();
        this.handleEvents();
    }

    public async init(config: FrameworkConfiguration): Promise<void> {
        await this.configProvider.init(); // früheste stelle wo es sein kann und muss

        let tenant = this.tenantProvider.getTenant();
        if (!tenant)
            return;

        var oidcKey = "oidc_" + tenant.programs[0].programType ;
        if(this.getIsPreEnvironment())
            oidcKey = oidcKey + "_PRE";
        var oidcConfig = (await this.configProvider.getConfiguration())[oidcKey];
        if (oidcConfig == null)
            return;
        if(this.config.get<boolean>(Constants.aokRouterPushState)){
            oidcConfig.post_logout_redirect_uri = oidcConfig.post_logout_redirect_uri + "?pt=" + tenant.key;
        }else{
            oidcConfig.post_logout_redirect_uri = oidcConfig.post_logout_redirect_uri + "#?pt=" + tenant.key;
        }

        let authConfig: UserManagerSettings = oidcConfig;
        this.userManager = new UserManager(authConfig);

        var subscription = this.eventAggregator.subscribe('voltospa:router-configured', () => {
            var home = this.router.routes.find(r => r.route == '');
            this.router.handleUnknownRoutes(async () => {
                return home;
            });
        });
    }

    public async login(returnUrl: string): Promise<void> {
        let m = (await this.configProvider.getConfiguration()).authmocked

        if ((await this.configProvider.getConfiguration()).authmocked) {
            this.fakeLogin();
        } else {
            await this.userManager.signinRedirect({
                state: { returnUrl: returnUrl, tenant: this.tenantProvider.getTenant().key }
            });
        }
    }

    public async logout(returnUrl: string): Promise<void> {
        if ((await this.configProvider.getConfiguration()).authmocked) {
            this.fakeLogout();
        } else {
            this.eventAggregator.publish('voltospa:authentication-logout');
            return await this.userManager.signoutRedirect();
        }
    }

    private redirect(returnUrl: string): void {
        if (returnUrl == document.location.href)
            document.location.reload();
        else
            document.location.href = returnUrl;
    }

    public async isAuthenticated(): Promise<boolean> {
        if ((await this.configProvider.getConfiguration()).authmocked) {
            return this.fakeIsAuthenticated();
        } else {
            var user = await this.getUser();
            return user != null;
        }
    }

    public async getUser(): Promise<User> {
        if ((await this.configProvider.getConfiguration()).authmocked) {
            var result = new User();
            result.accessToken = "accesstoken";
            result.userId = "1";
            result.name = "Maxi Musterfrau";
            return result;
        } else {
            if (!this.userManager)
                return null;

            var user = await this.userManager.getUser();
            if (user == null)
                return null;

            var result = new User();
            result.accessToken = user.access_token;
            result.userId = user.profile["sub"];
            result.name = user.profile["name"] != null ? user.profile["name"] : user.profile["sub"];
            result.claims = {};
            for (var claim in user.profile) {
                result.claims[claim] = user.profile[claim];
            }

            result.tenantId = result.claims['tenant_id'];
            result.kvnr = result.claims['preferred_username'];
            return result;
        }
    }

    private async processLogin(): Promise<void> {
        try {
            var user = await this.userManager.signinRedirectCallback();
            if (user != null && user.state != null) {
                this.eventAggregator.publish('voltospa:authentication-login');
                let view = new TenantViewModel();
                view.ui = this.config.get("Tenants." + user.profile.tenant_id);
                var resUrl = this.userManager.settings.redirect_uri +'/#/'+ view.ui.uiKey +'/start?'
                let tenantService : TenantService = await this.container.get(TenantService);
                
                let programType = tenantService.getProgramTypeFromGivenUrl(resUrl);
                let userProgramType = (await this.getUser()).claims["program_type"];
               
                if(userProgramType != undefined){
                    var longUserProgramType = TenantMapping.ProgramTypeToEnum(userProgramType);
                    if(programType != longUserProgramType)
                    {
                        var oidcKey = "oidc_" + longUserProgramType;
                        if(this.getIsPreEnvironment())
                            oidcKey = oidcKey + "_PRE";
                        var oidcConfig = (await this.configProvider.getConfiguration())[oidcKey];
                        
                        if (oidcConfig == null)
                            return;
                
                        if(this.config.get<boolean>(Constants.aokRouterPushState)){
                            oidcConfig.post_logout_redirect_uri = oidcConfig.post_logout_redirect_uri + "?pt=" + view.ui.uiKey;

                        }else{
                            oidcConfig.post_logout_redirect_uri = oidcConfig.post_logout_redirect_uri + "#?pt=" + view.ui.uiKey;
                        }
                
                        let authConfig: UserManagerSettings = oidcConfig;
                        this.userManager = new UserManager(authConfig);
                        resUrl = this.userManager.settings.redirect_uri +'/#/'+ view.ui.uiKey +'/start?autologin=true'
                    }
                }
                    
                let tenants: Array<TenantViewModel> = await  tenantService.getTenantsByProgramTypeFromUrl(resUrl);
                var myTenant = tenants.filter(f => f.tenantId == user.profile.tenant_id)[0];
                var tmpTenant = this.tenantProvider.getTenant();
                if( tmpTenant!= undefined && tmpTenant.tenantId != myTenant.tenantId){
                    
                    this.tenantProvider.setTenant(myTenant,true);
                }
                this.redirect(resUrl);

                await new Promise<void>(resolve => {
                    setTimeout(resolve, 2000); // warten auf den redirect
                });
            }
        }
        catch {
            console.log("Error beim Login!");
        }
    }

    private async processLogout(): Promise<void> {
        var user = null;
        try {
            var user: any = await this.userManager.signoutRedirectCallback();
            if (user != null && user.state != null) {
                this.redirect(user.state.returnUrl);
                this.eventAggregator.publish('voltospa:authentication-logout');
            }
        }
        catch {
            console.log("Error beim Logout!");
        }
    }

    public async handleAuthenticationCallbacks() {
        await this.processLogin();
        await this.processLogout();
    }


    handleEvents() {
        this.subscriptions = [];
        this.subscriptions.push(this.eventAggregator.subscribe(Constants.tenantChangedEvent, async (data) => {
            await this.init(null);
        }));
    }



    authenticationActive(): boolean {
        return true;
    }


    //Fake
    private fakeLogin(): void {
        this.storage.SetItem(StorageKey.Token, "Bin eingeloggt");
    }


    private fakeLogout() {
        for (const key in StorageKey) {
            if (StorageKey[key] != StorageKey.Tenant) {
                this.storage.RemoveItem(StorageKey[StorageKey[key]]);
            };
        }
    }


    private getIsPreEnvironment():boolean{
        return document.location.origin.indexOf('pre.aok')>=0;
    }

    private fakeIsAuthenticated(): boolean {
        let token = this.storage.GetItem(StorageKey.Token);

        if (!token)
            return false;

        return true;
    }
    //Fake
}


export class User {
    userId: string;
    name: string;
    kvnr: string;
    accessToken: string;
    claims: HashTable<string>;
    tenantId: string;
}