import { BindingEngine } from 'aurelia-binding';
import { Subscription } from 'aurelia-event-aggregator';
import { FormControlHelper } from './../../services/binding/formControlHelper';
import { bindable, autoinject, bindingMode, observable } from 'aurelia-framework';
import { FormElement } from './formElement';

@autoinject
export class VoltoCheckboxes extends FormElement {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: Array<any>;
    public selectedValues: any = [];
    @bindable public options: object[];
    @bindable public optionsLabel: string;
    @bindable public optionsValue: string;
    private arraySubscription:Subscription;
    private propertySubscription:Subscription;

    constructor(element: Element, formControlHelper: FormControlHelper, private bindingEngine: BindingEngine) {
        super(element, formControlHelper);
        this.createArraySubscription();
        this.propertySubscription = this.bindingEngine.propertyObserver(this, "selectedValues").subscribe(() => this.createArraySubscription());
    }
    
    public bind(context, overrideContext) {
        super.bind(context, overrideContext);
        this.setValue();
    }

    public detached() {
        if (this.arraySubscription != null)
            this.arraySubscription.dispose();
        if (this.arraySubscription != null)
            this.propertySubscription.dispose();
    }

    private setValue(): void {
        let resultArray = [];
        this.value.forEach(element => {
            resultArray.push(element[this.optionsValue]);
        });
        if(!this.formControlHelper.AreArraysEqual(this.selectedValues, resultArray))
            this.selectedValues = resultArray;
    }

    public valueChanged(): void {
         this.setValue();
    }

    public selectedValuesChanged() :void {
        let resultArray = [];
        this.selectedValues.forEach(element => {
            resultArray.push(this.options.find(o => o[this.optionsValue] == element));
        });
        this.value = resultArray;
    }

    private createArraySubscription(){
        if (this.arraySubscription != null)
            this.arraySubscription.dispose();
        this.bindingEngine.collectionObserver(this.selectedValues).subscribe(() => this.selectedValuesChanged());
    }
}