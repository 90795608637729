import { ParticipantNewsMapping } from './../../mappings/participant-news-mapping';
import { ParticipantNewsViewModel } from './../../view-models/participant-news-view-model';
import { StorageKey, StorageService } from "../storage/storage-service";
import { TenantContext } from "../tenant/tenant-context";
import { TenantService } from "../tenant/tenant-service";
import { AuthenticationType, RestClient } from "../api/rest-client";
import { autoinject } from 'aurelia-framework';
import { QueryParticipantAnouncementResultDTO, QueryParticipantAnouncementRequestDTO, ParticipantAnnouncementDTO } from "../api/aok-rest-api";

@autoinject
export class ParticipantNewsService {

    constructor(private rest: RestClient, private tenantProvider: TenantContext, private tenantService: TenantService, private storageService: StorageService) {

    }

    public async getCountOfNewParticpantNews(): Promise<number> {
        let count = this.storageService.GetObject<number>(StorageKey.ParticipantNewsCount);

        if (count != null) {
            return count;
        }
        try {
            var result = await this.queryParticipantNews(false, 0, 100);
            if (result.announcements != null && result.announcements.length > 0) {
                count = result.announcements.filter(t => t.new === true).length;
            }
            else {
                count = 0;
            }
        }
        catch (error) {
            count = 0;
        }
        this.storageService.SetObject(StorageKey.ParticipantNewsCount, count);
        return count;
    }

    public async queryParticipantNews(updateNewsAsRead: boolean, pageNumber: number, pageQuantity: number): Promise<QueryParticipantAnouncementResultDTO> {
        let client = await this.rest.getRestApi(AuthenticationType.BEARER);
        let result = new QueryParticipantAnouncementResultDTO();
        let requestBody = new QueryParticipantAnouncementRequestDTO();
        requestBody.updateAppLastUsed = updateNewsAsRead;
        requestBody.pageNumber = pageNumber;
        requestBody.pageQuantity = pageQuantity;
        result = await client.queryParticipantAnnouncementsUsingPOST(requestBody);
        return result;
    }

    public async getParticipantNews(pageNumber: number, pageQuantity): Promise<ParticipantNewsViewModel> {
        try {
            var result = await this.queryParticipantNews(true, pageNumber, pageQuantity);
            var newsView = ParticipantNewsMapping.QueryParticipantAnouncementResultDtoToParticipantNewsViewModel(result);
            return newsView;
        }
        catch (error) {
        }
        return null;
      }
}