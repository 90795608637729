import { TenantService } from './../service/tenant/tenant-service';
import { PageBase } from './contents/Base/page-base';
import { autoinject, observable } from 'aurelia-framework';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantViewModel } from '../view-models/tenant-view-models';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";

@autoinject
export class Home extends PageBase {

  public tenants: TenantViewModel[] = [];
  @observable() public selectedTenant: TenantViewModel;
  public buttonDisabled: boolean = true;

  constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, public nav: UiNavigationService) {
    super(tenantService, config, tenantProvider, layout, logger, nav);
    layout.setStartLayout();
  }

  async attached() {
    //this.tenantProvider.removeTenant();
    this.tenants = await this.tenantService.getTenantsByProgramType();
    if(this.tenants.length == 1){
      this.tenantProvider.setTenant(this.tenants[0]);
    }
  }

  async selectedTenantChanged() {
    if (this.selectedTenant) {
      this.buttonDisabled = false;
    }
    else {
      this.buttonDisabled = true;
    }
  }

  async navigate() {
    this.tenantProvider.setTenant(this.selectedTenant);
  }
}
