import { bindable, autoinject, bindingMode, computedFrom } from 'aurelia-framework';
import { ValidationController, ValidationRules } from "aurelia-validation";
import { CoCollectorViewModel } from '../../../view-models/co-collector-view-model';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Constants } from "../../../constants/constants";

@autoinject
export class AokVersichertennummer{
    
    @bindable({ defaultBindingMode: bindingMode.twoWay }) value: CoCollectorViewModel = new CoCollectorViewModel();
    @bindable() index: string;

    private subscriptions: Array<any>;
    
    @computedFrom('index')
    get internalIndex(): string {
        return "CoCollectorNumber" + this.index;
    }

    constructor(private readonly validationController: ValidationController, private eventAggregator: EventAggregator) {
        this.subscriptions = [];
         this.subscriptions.push(this.eventAggregator.subscribe(Constants.inputValidatedEvent, async () => {
             await this.validationController.validate();
         }));
       
        ValidationRules
            .ensure((item: CoCollectorViewModel) => item.kvnr)
            .minLength(9).withMessage("Mindestens 9 Zeichen.")
            .maxLength(10).withMessage("Nicht mehr als 10 Zeichen.")
            .matches(new RegExp(/^[a-zA-Z]?\d{9}$/)).withMessage("Bitte überprüfen Sie Ihre Eingabe.")
            .on(CoCollectorViewModel);
    }

    async detached(){
        this.subscriptions.forEach(subscription => {
            subscription.dispose();
        });
    }
}