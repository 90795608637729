import * as $ from 'jquery';

function initShyHeader() {
    if (window.scrollY > 0) {
        $("body").addClass("header-hidden");
    } else {
        $("body").removeClass("header-hidden");
    }
}

export function toggleShyHeader(value) {
    if (value) {
        document.addEventListener("scroll", initShyHeader);
    } else {
        document.removeEventListener("scroll", initShyHeader);
    }
}