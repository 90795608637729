import { autoinject } from 'aurelia-framework';
import { EnvironmentConfigurationService } from '../configuration/environment-configuration-service';

@autoinject()
export class StorageService {

    // private property
    private _keyStr: string = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    constructor(private env: EnvironmentConfigurationService) {

    }

    public SetItem(key: StorageKey, value: string): boolean {
        localStorage.setItem(StorageKey[key], btoa(value));
        return true;
    }


    public SetObject(key: StorageKey, value: any): boolean {
        localStorage.setItem(StorageKey[key], this.encode(JSON.stringify(value)));
        return true;
    }


    public GetItem(key: StorageKey): string {
        return this.decodeBase64StorageValue(key);
    }


    public GetObject<T>(key: StorageKey): T {
        let value = this.decodeBase64StorageValue(key);
        return JSON.parse(value, this.dateTimeReviver) as T;
    }

    private decodeBase64StorageValue(key: StorageKey): string {
        let value: string = localStorage.getItem(StorageKey[key]);
        if (!value)
            return null;

        value = this.decode(value); 
        if (!value)
            return null;

        return value;
    }


    public RemoveItem(key: StorageKey) {
        localStorage.removeItem(StorageKey[key])
    }


    public Clear() {
        localStorage.clear();
    }


    dateTimeReviver = function (key, value) {
        var dateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}Z$/;
        var dateTimeFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z$/;

        if (typeof value === "string" && (dateFormat.test(value) || dateTimeFormat.test(value))) {
            return new Date(value);
        }

        return value;
    }

    /**
    *
    *  Base64 encode / decode
    *  http://www.webtoolkit.info
    *
    **/
    // public method for encoding
    public encode (inputString: string): string
    {
        var output: string = "";
        var chr1, chr2, chr3, enc1, enc2, enc3, enc4;
        var i = 0;

        inputString = this.utf8_encode(inputString);

        while (i < inputString.length)
        {
            chr1 = inputString.charCodeAt(i++);
            chr2 = inputString.charCodeAt(i++);
            chr3 = inputString.charCodeAt(i++);

            enc1 = chr1 >> 2;
            enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
            enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
            enc4 = chr3 & 63;

            if (isNaN(chr2))
            {
                enc3 = enc4 = 64;
            }
            else if (isNaN(chr3))
            {
                enc4 = 64;
            }

            output = output +
                this._keyStr.charAt(enc1) + this._keyStr.charAt(enc2) +
                this._keyStr.charAt(enc3) + this._keyStr.charAt(enc4);
        } // Whend 

        return output;
    } // End Function encode 
    
    
        // public method for decoding
    public decode (inputstring: string): string
    {
        var output: string = "";
        var chr1, chr2, chr3;
        var enc1, enc2, enc3, enc4;
        var i = 0;

        inputstring = inputstring.replace(/[^A-Za-z0-9\+\/\=]/g, "");
        while (i < inputstring.length)
        {
            enc1 = this._keyStr.indexOf(inputstring.charAt(i++));
            enc2 = this._keyStr.indexOf(inputstring.charAt(i++));
            enc3 = this._keyStr.indexOf(inputstring.charAt(i++));
            enc4 = this._keyStr.indexOf(inputstring.charAt(i++));

            chr1 = (enc1 << 2) | (enc2 >> 4);
            chr2 = ((enc2 & 15) << 4) | (enc3 >> 2);
            chr3 = ((enc3 & 3) << 6) | enc4;

            output = output + String.fromCharCode(chr1);

            if (enc3 != 64)
            {
                output = output + String.fromCharCode(chr2);
            }

            if (enc4 != 64)
            {
                output = output + String.fromCharCode(chr3);
            }

        } // Whend 

        output = this.utf8_decode(output);

        return output;
    } // End Function decode 
    
    
        // private method for UTF-8 encoding
    public utf8_encode(inputstring: string): string
    {
        var utftext = "";
        inputstring = inputstring.replace(/\r\n/g, "\n");

        for (var n = 0; n < inputstring.length; n++)
        {
            var c = inputstring.charCodeAt(n);

            if (c < 128)
            {
                utftext += String.fromCharCode(c);
            }
            else if ((c > 127) && (c < 2048))
            {
                utftext += String.fromCharCode((c >> 6) | 192);
                utftext += String.fromCharCode((c & 63) | 128);
            }
            else
            {
                utftext += String.fromCharCode((c >> 12) | 224);
                utftext += String.fromCharCode(((c >> 6) & 63) | 128);
                utftext += String.fromCharCode((c & 63) | 128);
            }

        } // Next n 

        return utftext;
    } // End Function _utf8_encode 
    
        // private method for UTF-8 decoding
    public utf8_decode(utftext: string): string
    {
        var mystring = "";
        var i = 0;
        var c, c1, c2, c3;
        c = c1 = c2 = 0;

        while (i < utftext.length)
        {
            c = utftext.charCodeAt(i);

            if (c < 128)
            {
                mystring += String.fromCharCode(c);
                i++;
            }
            else if ((c > 191) && (c < 224))
            {
                c2 = utftext.charCodeAt(i + 1);
                mystring += String.fromCharCode(((c & 31) << 6) | (c2 & 63));
                i += 2;
            }
            else
            {
                c2 = utftext.charCodeAt(i + 1);
                c3 = utftext.charCodeAt(i + 2);
                mystring += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63));
                i += 3;
            }

        } // Whend 

        return mystring;
    } // End Function _utf8_decode 
}


export enum StorageKey {
    Tenant = "Tenant",
    Token = "Token",
    MembershipStatus = "MembershipStatus",
    RewardGroup = "RewardGroup",
    ShoppingCart = "ShoppingCart",
    UserProfile = "UserProfile",
    AccountBalance = "AccountBalance",
    OrderItemStatus = "OrderItemStatus",
    OrderItemType = "OrderItemType",
    Salutation = "Salutation",
    ParticipantNewsCount = "ParticipantNewsCount"
}