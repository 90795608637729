import { bindable, autoinject, bindingMode } from 'aurelia-framework';

@autoinject
export class AokSelect {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: any = null;
    @bindable public options: object[];
    @bindable public optionsLabel: string;
    @bindable public optionsValue: string;
    @bindable public disabledField: string;
    @bindable public label: string;
    @bindable public id: string;
    @bindable public showPleaseSelect: boolean = true;
}