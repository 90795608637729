import * as $ from 'jquery';

/* Logout */
export function initLogOut() {

    $("#logoutModalDialog").on("click", function (e) {
        $("body").append(logoutHtml);
        $("#logoutModal").delay(100).modal("show");

        var logoutTitle = $(this).data("logout-title");
        var logoutMessage = $(this).data("logout-message");
        var logoutNo = $(this).data("logout-no");
        var logoutYes = $(this).data("logout-yes");
        var logoutHref = $(this).attr("href");

        if (logoutTitle != "") {
            $("#logoutTitle").text(logoutTitle);
        }
        if (logoutMessage != "") {
            $("#logoutMessage").text(logoutMessage);
        }
        if (logoutNo != "") {
            $("#closeLogout").text(logoutNo);
        }
        if (logoutYes != "") {
            $("#yesLogout").text(logoutYes);
        }
        if (logoutHref != "") {
            $("#yesLogout").data("logout-href", logoutHref);
        }

        return false;
    });

    $(document).on("click", "#closeLogout", function () {
        $("#logoutModal").modal('hide');
        $("#logoutModal").remove();
    });

    $(document).on("click", "#yesLogout", function () {
        let logoutHref = $("#yesLogout").data("logout-href");
        if (logoutHref != "") {
            window.location.href = logoutHref;
        }
    });

    let logoutHtml = "<div id='logoutModal' class='modal fade-scale' tabindex='-1' role='dialog' aria-labelledby='logoutModalLabel' aria-hidden='true' data-backdrop='static'>" +
        "<div class='modal-dialog modal-sm'>" +
        "<div class='modal-content'>" +
        "<div class='container'>" +
        "<div class='modal-header'>" +
        "<h1 class='modal-title' id='logoutModalLabel'><span class='fal fa-sign-out'></span><span id='logoutTitle'> Logout</span></h1>"+
        "</div>" +
        "<div class='modal-body'>" +
        "<p id='logoutMessage'>Do you really want to logout?</p>" +
        "</div>" +
        "<div class='modal-footer'>" +
        "<button type='button' class='btn btn-logout-cancel' id='closeLogout'>No</button>" +
        "<button type='button' class='btn btn-logout-primary' id='yesLogout'>Yes</button>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>" +
        "</div>";
}
