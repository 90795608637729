import { EventAggregator } from 'aurelia-event-aggregator';
import { RewardViewModel } from './../../view-models/reward-view-model';
import { autoinject } from 'aurelia-framework';
import { ConfigProvider, Logger } from "voltospa";
import { AuthService } from "../../service/auth/auth-service";
import { ResourceService } from '../../service/resource/resource-service';
import { RewardsService } from "../../service/rewards/rewards-service";
import { TenantContext } from "../../service/tenant/tenant-context";
import { TenantService } from "../../service/tenant/tenant-service";
import { UILayoutService } from "../../service/ui/ui-layout-service";
import { UiNavigationService } from "../../service/ui/ui-navigation-service";
import { RewardGroupViewModel } from '../../view-models/reward-group-view-model';
import { TenantViewModel } from '../../view-models/tenant-view-models';
import { PageBase } from "../contents/Base/page-base";
import { Constants } from '../../constants/constants';
import { ImageSize } from '../../service/api/resource-rest-api';

@autoinject()
export class CategoryDetails extends PageBase{
    
    public activeTenant: TenantViewModel;
    public rewardGroup: RewardGroupViewModel;
    public backgroundImageStylestring: string = "";
    public imageUrl: string;
    public rewards: RewardViewModel[] = [];
    public rewardsToDisplay: RewardViewModel[] = [];

    private rewardKey: string;
    private subscriptions: Array<any> ;
    
    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, public layout: UILayoutService, logger: Logger, 
        public nav: UiNavigationService, private auth: AuthService, private rewardsService: RewardsService, private resourceService: ResourceService, 
        private eventAggregator: EventAggregator) {
        
        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.activeTenant = tenantProvider.getTenant();
        this.handleEvents();
    }

    public async activate(params: any) {
        this.rewardKey = params.id;

        var loyalityProgram = this.getLoyalityProgram();
        var rewardGroups = await this.rewardsService.getRewardGroups(this.activeTenant.id, loyalityProgram.id);
        this.rewardGroup = rewardGroups.filter(rg => rg.id == params.id)[0];
        if(this.rewardGroup && this.rewardGroup.thumbnail){
            try {
                let imageFile = await this.resourceService.getResourceById(this.rewardGroup.thumbnail.resourceId, ImageSize.L);
                this.imageUrl = URL.createObjectURL(imageFile);
                this.backgroundImageStylestring = "background-image: url(" + this.imageUrl + ");";    
            } catch {}
            
        }
        try {
            this.rewards = await this.rewardsService.getRewardForGroup(this.activeTenant.id, loyalityProgram.id, this.rewardKey, true);
        } catch {}
        
    }

    public async detached(){
        this.subscriptions.forEach(subscription => {
            subscription.dispose();
        });
    }

    private handleEvents() {
        this.subscriptions = [];

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.pageChangedEvent, (data) =>{
            this.pageChangedEvent(data)
        }));
    }

    private pageChangedEvent(data: any){
        var startIndex = data.pageIndex * data.pageSize;
        this.rewardsToDisplay = this.rewards.slice(startIndex, startIndex + data.pageSize);
    }
}