import { inject } from 'aurelia-framework';
import { HttpClient, RequestInit } from 'aurelia-fetch-client';
import { ImageSize, IResourceRestApi, RequestDownloadChequeBookDTO } from './resource-rest-api';
import { Constants } from '../../constants/constants';
import { ConfigProvider } from 'voltospa';


@inject(String, HttpClient)
export class ResourceRestApiMock implements IResourceRestApi {

    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    private http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
    private baseUrl: string;

    constructor(private config: ConfigProvider) {
    }
    getChequeBookPdfUsingPOST(membershipId: number, requestDownloadChequeBookDTO: RequestDownloadChequeBookDTO): Promise<Blob> {
        throw new Error('Method not implemented.');
    }


    /**
     * getResource
     * @param rid rid
     * @param size size
     * @return Successfully got requested image
     */
    async getResourceUsingGET(resourceId: number, size: string): Promise<Blob> {
        var root = this.config.get<string>(Constants.aokRootPath);

        let imageSize: string = "";
        if (size != undefined) {
            imageSize = size;
        }

        if(imageSize != ImageSize.S){
            imageSize = "";
        }

        var filename = root + "Assets/temp" + resourceId + imageSize + ".jpg";
        var response = await fetch(filename);
        return await response.blob();
    }
}
