export class TenantViewModel {

    ftamid?: string | undefined;
    id?: number | undefined;
    insuranceId?: string | undefined;
    key?: string | undefined;
    mediaCodes?: MediaCodeViewModel[] | undefined;
    name?: string | undefined;
    programs?: LoyaltyProgramViewModel[] | undefined;
    tenantId?: string | undefined;

    ui: UITenantValues | undefined;

    public getLoyaltyProgram(): LoyaltyProgramViewModel {

        // Laut Martin Bö. ist hier immer nur eins vom gleichen Program Type da
        if (!this.programs || this.programs.length != 1) {
            throw "Der Mandant " + this.name + " hat nicht genau 1 Bonusprogramm!";
        }

        return this.programs[0];
    }

}


export enum MediaCodeStatus {
    DELETED = <any>"DELETED",
    VALID = <any>"VALID",
    INVALID = <any>"INVALID",
}

export class MediaCodeViewModel {
    description?: string | undefined;
    id?: number | undefined;
    name?: string | undefined;
    status?: MediaCodeStatus | undefined;
}

export class LoyaltyProgramViewModel {
    id?: number | undefined;
    name?: string | undefined;
    useActivityLevels?: boolean | undefined;
    programType?: ProgramType;
    participantInterfaceType?: ParticipantInterfaceType;
}

export interface UITenantValues {
    uiKey?: string;
    title?: string;
    insuranceNumber?: string;
    programs?: ProgramInfo[];

}

export interface ProgramInfo {
    key: string;
    title: string;
    name: string;
    longName: string;
    logo?: string;
    logoName?: string;
    coCollectors?: string;
}

export enum ProgramType {
    PREMIUM = "PREMIUM", FAMILY_BONUS = "FAMILY_BONUS"
}

export enum ParticipantInterfaceType {
    PHV_PMV = "PHV_PMV", HSA_MSA = "HSA_MSA"
}