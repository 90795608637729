import { LocalizationProvider } from '../localization/localizationProvider';
import { StarterCommand } from '../dependencies/starterCommand';
import { autoinject } from 'aurelia-framework';
import { MenuProvider } from '../navigation/menuProvider';
import { LayoutProvider } from '../layout/layoutProvider';

@autoinject
export class SetDefaultLayoutOptions extends StarterCommand {
  constructor(private menuProvider: MenuProvider, private layoutProvider: LayoutProvider, private localizationProvider: LocalizationProvider) {
    super();
  }
  public async execute(): Promise<void> {
    var menu = await this.menuProvider.getMenu();
    var footer = await this.menuProvider.getFooter();
    this.layoutProvider.hasMenu = menu != null;
    this.layoutProvider.hasFooter = footer != null;
    this.layoutProvider.hasContainer = false; 
    var languages = await this.localizationProvider.getLanguages();
    this.layoutProvider.hasLanguageSelect = languages != null && languages.length > 1;
  }
}