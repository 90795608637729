import { ChangePasswordDTO } from '../service/api/aok-rest-api';
import { ChangePasswordViewModel } from './../view-models/change-password-view-model';

export class ChangePasswordMapping {

    public static ChangePasswordViewModelToChangePasswordDTO(from?: ChangePasswordViewModel): ChangePasswordDTO {
        if (!from) {
            return null;
        }
        let to = new ChangePasswordDTO();
        to.oldPassword = from.oldPassword;
        to.newPassword = from.newPassword;
        return to;
    }
}