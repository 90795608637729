import { autoinject } from 'aurelia-framework';
import { AuthenticationType, RestClient } from "../api/rest-client";
import { ParticipantMapping } from './../../mappings/participant-mapping';
import { ConfigProvider } from "voltospa";
import { ParticipantViewModel } from '../../view-models/participant-view-model';
import { CreateOnlineAccessRequestDTO, ErrorDTO, RegistrationFormResultDTO, GetStatusResultDTO, SwaggerException } from '../api/aok-rest-api';
import { CoCollectorViewModel } from '../../view-models/co-collector-view-model';
import { RegistrationResult } from '../../view-models/registration-result';
import * as moment from 'moment';

@autoinject
export class ParticipationService {

    constructor(private rest: RestClient, private config: ConfigProvider) {

    }

    public async createRegistrationParticipant(id: number, requestData: ParticipantViewModel): Promise<RegistrationResult> {
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let requestParamsDto = ParticipantMapping.ParticiapntViewModelToRegistrationFormRequestDTO(requestData)
        let res = new RegistrationResult();
        let result: RegistrationFormResultDTO;
        try {
            result = await client.createRegistrationRequestUsingPOST(id, requestParamsDto);
            res = ParticipantMapping.RegistrationFormResponseDTOtoRegistrationResult(result);
        } catch (error) {
            if (error instanceof ErrorDTO) {
                var errorDto: ErrorDTO = error as ErrorDTO;
                res.exception = errorDto.exception;
                res.errorCode = errorDto.errorCode;
                res.errorMessages = errorDto.errorMessages;
            }
            return res;
        }
        return res;
    }

    public async createRegistrationCoCollector(id: number, requestData: CoCollectorViewModel): Promise<RegistrationResult> {
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let requestParamsDto = ParticipantMapping.CoCollectorViewModelToRegistrationFormRequestDTO(requestData)
        let res = new RegistrationResult();
        let result: RegistrationFormResultDTO;
        try {
            result = await client.createRegistrationRequestUsingPOST(id, requestParamsDto);
            res = ParticipantMapping.RegistrationFormResponseDTOtoRegistrationResult(result);
        } catch (error) {
            if (error instanceof ErrorDTO) {
                var errorDto: ErrorDTO = error as ErrorDTO;
                res.exception = errorDto.exception;
                res.errorCode = errorDto.errorCode;
                res.errorMessages = errorDto.errorMessages;
            }
            return res;
        }
        return res;
    }

    public async getStatus(tenant: number | null | undefined, id: number, kvnr: string): Promise<GetStatusResultDTO> {
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result = await client.checkKvnrUsingGET(id, kvnr);
        return result;
    }

    public async createOnlineAccess(tenant: number | null | undefined, id: number, kvnr: string, email: string, telefon: string, dateOfBirth: Date) {
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let request = new CreateOnlineAccessRequestDTO();
        request.birthDate = moment.utc({ year: dateOfBirth.getFullYear(), month: dateOfBirth.getMonth(), day: dateOfBirth.getDate() });
        request.kvnr = kvnr;
        request.email = email;
        request.phone2FA = telefon;
        await client.createOnlineAccessUsingPOST(id, request);
    }

}


