import { inject } from 'aurelia-framework';
import { HttpClient, RequestInit } from 'aurelia-fetch-client';


export interface IResourceRestApi {
    getResourceUsingGET(rid: number, size: string): Promise<Blob>
    /**
     * UC A-005 getChequeBookPdfUsingPOST
     * @param membershipId membershipId
     * @param requestDownloadChequeBookDTO requestDownloadChequeBookDTO
     * @return Successfully found information and generated pdf
     */
    getChequeBookPdfUsingPOST(membershipId: number, requestDownloadChequeBookDTO: RequestDownloadChequeBookDTO): Promise<Blob>;

}


@inject(String, HttpClient)
export class ResourceRestApi implements IResourceRestApi {

    protected jsonParseReviver: ((key: string, value: any) => any) | undefined = undefined;
    private http: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> };
    private baseUrl: string;

    constructor(baseUrl?: string, http?: { fetch(url: RequestInfo, init?: RequestInit): Promise<Response> }) {
        this.http = http ? http : <any>window;
        this.baseUrl = baseUrl ? baseUrl : "10.16.72.14:8080/";
    }


    /**
     * getResource
     * @param rid rid
     * @param size size
     * @return Successfully got requested image
     */
    getResourceUsingGET(rid: number, size: string): Promise<Blob> {
        let url_ = this.baseUrl + "/resource/{rid}";
        if (rid === undefined || rid === null)
            throw new Error("The parameter 'rid' must be defined.");
        url_ = url_.replace("{rid}", encodeURIComponent("" + rid));
        if (size === undefined || size === null)
            throw new Error("The parameter 'size' must be defined.");
        url_ = url_.replace("{size}", encodeURIComponent("" + size));
        url_ = url_.replace(/[?&]$/, "");

        let options_ = <RequestInit>{
            method: "GET",
            headers: {
                "Accept": "image/png"
            }
        };

        return this.http.fetch(url_, options_)
            .then(response => response.blob());
    }

    /**
   * UC A-005 getChequeBookPdfUsingPOST
   * @param membershipId membershipId
   * @param requestDownloadChequeBookDTO requestDownloadChequeBookDTO
   * @return Successfully found information and generated pdf
   */
    getChequeBookPdfUsingPOST(membershipId: number, requestDownloadChequeBookDTO: RequestDownloadChequeBookDTO): Promise<Blob> {
        let url_ = this.baseUrl + "/app/memberships/{membershipId}/chequebooks/download";
        if (membershipId === undefined || membershipId === null)
            throw new Error("The parameter 'membershipId' must be defined.");
        url_ = url_.replace("{membershipId}", encodeURIComponent("" + membershipId));
        url_ = url_.replace(/[?&]$/, "");

        const content_ = JSON.stringify(requestDownloadChequeBookDTO);

        let options_ = <RequestInit>{
            body: content_,
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/pdf"
            }
        };

        return this.http.fetch(url_, options_).then((_response: Response) => {
            return this.processGetChequeBookPdfUsingPOST(_response);
        });
    }

    protected processGetChequeBookPdfUsingPOST(response: Response): Promise<Blob> {
        const status = response.status;
        let _headers: any = {}; if (response.headers && response.headers.forEach) { response.headers.forEach((v: any, k: any) => _headers[k] = v); };
        if (status === 200 || status === 206) {
            const contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
            const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
            const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
            return response.blob();
        } else if (status === 201) {
            const contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
            const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
            const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
            return response.blob();
        } else if (status === 400) {
            return response.text().then((_responseText) => {
                let result400: any = null;
                let resultData400 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result400 = ErrorDTO.fromJS(resultData400);
                return this.throwException("Bad Request, e.g. Activity Already Redeemed Exception", status, _responseText, _headers, result400);
            });
        } else if (status === 401) {
            return response.text().then((_responseText) => {
                let result401: any = null;
                let resultData401 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result401 = ErrorDTO.fromJS(resultData401);
                return this.throwException("Unauthorized access", status, _responseText, _headers, result401);
            });
        } else if (status === 403) {
            return response.text().then((_responseText) => {
                let result403: any = null;
                let resultData403 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result403 = ErrorDTO.fromJS(resultData403);
                return this.throwException("Unauthorized access", status, _responseText, _headers, result403);
            });
        } else if (status === 404) {
            return response.text().then((_responseText) => {
                let result404: any = null;
                let resultData404 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result404 = ErrorDTO.fromJS(resultData404);
                return this.throwException("No data found (Participant/Membership/Activity)", status, _responseText, _headers, result404);
            });
        } else if (status === 500) {
            return response.text().then((_responseText) => {
                let result500: any = null;
                let resultData500 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
                result500 = ErrorDTO.fromJS(resultData500);
                return this.throwException("Technical Error", status, _responseText, _headers, result500);
            });
        } else if (status !== 200 && status !== 204) {
            return response.text().then((_responseText) => {
                return this.throwException("An unexpected server error occurred.", status, _responseText, _headers);
            });
        }
        return Promise.resolve<Blob>(<any>null);
    }

    protected throwException(message: string, status: number, response: string, headers: { [key: string]: any; }, result?: any): any {
        if (result !== null && result !== undefined)
            throw result;
        else
            throw new SwaggerException(message, status, response, headers, null);
    }
}



export enum ImageSize {
    S = 'S',
    M = 'M',
    L = 'L'
}


export interface IRequestDownloadChequeBookDTO {
    activityIds?: number[] | undefined;
}

export class RequestDownloadChequeBookDTO implements IRequestDownloadChequeBookDTO {
    activityIds?: number[] | undefined;

    constructor(data?: IRequestDownloadChequeBookDTO) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            if (Array.isArray(_data["activityIds"])) {
                this.activityIds = [] as any;
                for (let item of _data["activityIds"])
                    this.activityIds.push(item);
            }
        }
    }
}


/** Domain error information */
export interface IErrorDTO {
    /** Error-Code of application */
    errorCode?: string | undefined;
    /** Exception message */
    errorMessages?: string[] | undefined;
    /** Subcode for more specific error */
    errorSubcode?: string | undefined;
    /** Exception which has occured */
    exception?: string | undefined;
}

export class ErrorDTO implements IErrorDTO {
    /** Error-Code of application */
    errorCode?: string | undefined;
    /** Exception message */
    errorMessages?: string[] | undefined;
    /** Subcode for more specific error */
    errorSubcode?: string | undefined;
    /** Exception which has occured */
    exception?: string | undefined;

    constructor(data?: IErrorDTO) {
        if (data) {
            for (var property in data) {
                if (data.hasOwnProperty(property))
                    (<any>this)[property] = (<any>data)[property];
            }
        }
    }

    init(_data?: any) {
        if (_data) {
            this.errorCode = _data["errorCode"];
            if (Array.isArray(_data["errorMessages"])) {
                this.errorMessages = [] as any;
                for (let item of _data["errorMessages"])
                    this.errorMessages.push(item);
            }
            this.errorSubcode = _data["errorSubcode"];
            this.exception = _data["exception"];
        }
    }

    static fromJS(data: any): ErrorDTO {
        data = typeof data === 'object' ? data : {};
        let result = new ErrorDTO();
        result.init(data);
        return result;
    }

    toJSON(data?: any) {
        data = typeof data === 'object' ? data : {};
        data["errorCode"] = this.errorCode;
        if (Array.isArray(this.errorMessages)) {
            data["errorMessages"] = [];
            for (let item of this.errorMessages)
                data["errorMessages"].push(item);
        }
        data["errorSubcode"] = this.errorSubcode;
        data["exception"] = this.exception;
        return data;
    }
}


export class SwaggerException extends Error {
    message: string;
    status: number;
    response: string;
    headers: { [key: string]: any; };
    result: any;

    constructor(message: string, status: number, response: string, headers: { [key: string]: any; }, result: any) {
        super();

        this.message = message;
        this.status = status;
        this.response = response;
        this.headers = headers;
        this.result = result;
    }

    protected isSwaggerException = true;

    static isSwaggerException(obj: any): obj is SwaggerException {
        return obj.isSwaggerException === true;
    }
}