import { Constants } from './constants/constants';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration, Redirect } from 'aurelia-router';
import { AuthenticationProvider, ConfigProvider } from 'voltospa';
import { TenantContext } from "./service/tenant/tenant-context";
import { UiNavigationService } from './service/ui/ui-navigation-service';
import { TenantService } from './service/tenant/tenant-service';
import { UILayoutService } from "./service/ui/ui-layout-service";
import { AuthService } from './service/auth/auth-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ProgressIndicatorTypes, showProgressIndicator, hideProgressIndicator } from 'volto';
@autoinject
export class App {

    private router: Router;
    private subscriptions;
    private progressIndicator: HTMLDivElement;
    private showProgressIndicator: boolean;

    constructor(private layout: UILayoutService, private nav: UiNavigationService, private config: ConfigProvider,
        private tenantService: TenantService, private authProvider: AuthenticationProvider, private eventAggregator: EventAggregator) {
        this.progressIndicator = showProgressIndicator(ProgressIndicatorTypes.Spinner);
        hideProgressIndicator(this.progressIndicator);
        this.handleEvents();
    }


    get watchWidth(): string {
        if (!window.matchMedia("(max-width: 1023px)").matches) {
            this.nav.closeMobileMenu();
        }
        return '';
    }


    handleEvents() {
        this.subscriptions = [];
        this.subscriptions.push(this.eventAggregator.subscribe(Constants.voltospaFetchStartEvent, (event) => {
            this.showProgressIndicator = true;
            setTimeout(() => { if (this.showProgressIndicator) $(this.progressIndicator).show(); }, 800);
        }));

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.voltospaFetchCompleteEvent, (event: Response) => {
            this.showProgressIndicator = false;
            hideProgressIndicator(this.progressIndicator);
            try {
                if (event.status === 401) {
                    this.authProvider.login(window.location.href.indexOf('?') != -1 ? (window.location.href + "&") : (window.location.href + "?"));
                }
            } catch (error) { }
        }));
    };


    async activate() {
        await this.authProvider.handleAuthenticationCallbacks();
        window.addEventListener("scroll", this.scrollPageTopButton);
    }

    configureRouter(config: RouterConfiguration, router: Router) {

        config.addPreActivateStep(InitializeTenant);
        config.addPipelineStep('postcomplete', PostCompleteStep);

        var program = this.tenantService.getProgramTypeFromUrl();
        var programName = this.config.get<any>(Constants.aokConfigurationProgramInfo + program).name;

        config.options.pushState = this.config.get<boolean>(Constants.aokRouterPushState);
        config.options.root = this.config.get<string>(Constants.aokRootPath);

        config.map([
            { route: ['/', '/:tenant?/error', '/:tenant?/state'], name: 'home', moduleId: PLATFORM.moduleName('./pages/home'), title: 'Home' },
            {
                route: '/:tenant/start', name: 'start', moduleId: PLATFORM.moduleName('./pages/start'), title: 'Start'
            },
            {
                route: '/:tenant/impressum', name: 'impressum', moduleId: PLATFORM.moduleName('./pages/impressum'), title: 'Impressum',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/sitemap', name: 'sitemap', moduleId: PLATFORM.moduleName('./pages/sitemap'), title: 'Sitemap',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/datenschutz', name: 'datenschutz', moduleId: PLATFORM.moduleName('./pages/datenschutz'), title: 'Datenschutz / Haftung',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/faq', name: 'faq', moduleId: PLATFORM.moduleName('./pages/faq'), title: 'FAQs',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/news/:id', name: 'news', moduleId: PLATFORM.moduleName('./pages/news'), title: 'News',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'News', routeName: 'start', routeParams: ['tenant'], isClickable: false }
                    ]
                }
            },
            {
                route: '/:tenant/teilnahme-anfordern/:kvnr', name: 'teilnahme-anfordern', moduleId: PLATFORM.moduleName('./pages/teilnahme/teilnahme-anfordern'), title: 'Teilnahme anfordern',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/teilnehmen', name: 'teilnehmen', moduleId: PLATFORM.moduleName('./pages/teilnahme/index'), title: 'Jetzt registrieren',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/mitteilung', name: 'mitteilung', moduleId: PLATFORM.moduleName('./pages/mitteilung'), title: 'Mitteilung',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant']}
                    ]
                }
            },
            {
                route: '/:tenant/online-zugang-anfordern/:kvnr', name: 'online-zugang-anfordern', moduleId: PLATFORM.moduleName('./pages/teilnahme/online-login-request'), title: 'Onlinezugang beantragen',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/so-funktionierts', name: 'so-funktionierts', moduleId: PLATFORM.moduleName('./pages/so-funktionierts'), title: 'So funktionierts',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/praemien-shop', name: 'premium-shop', moduleId: PLATFORM.moduleName('./pages/premium-shop/overview'), title: 'Prämienshop',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/praemien-shop/:id', name: 'premium-shop-category-detail', moduleId: PLATFORM.moduleName('./pages/premium-shop/category-details'), title: 'Prämienauswahl',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Prämienshop', routeName: 'premium-shop', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/praemien-shop/:id/premium/:premiumId', name: 'premium-details', moduleId: PLATFORM.moduleName('./pages/premium-shop/premium-details'), title: 'Prämien Details',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Prämienshop', routeName: 'premium-shop', routeParams: ['tenant'] },
                        { name: 'Prämienauswahl', routeName: 'premium-shop-category-detail', routeParams: ['tenant', 'id'] }
                    ]
                }
            },
            {
                route: '/:tenant/kontakt', name: 'kontakt', moduleId: PLATFORM.moduleName('./pages/kontakt'), title: 'Kontakt',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/passwort', name: 'passwort ändern', moduleId: PLATFORM.moduleName('./pages/passwort-aendern'), title: 'Passwort ändern',
                settings: {
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/email-bestaetigung', name: 'email-bestaetigung', moduleId: PLATFORM.moduleName('./pages/email-bestaetigung'), title: 'E-Mail Bestätigung',
            },

            // Seiten mit Authorisierung
            {
                route: '/:tenant/profil', name: 'profil', moduleId: PLATFORM.moduleName('./pages/profile'), title: 'Mein Profil',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Mein Konto', routeName: 'start', routeParams: ['tenant'], isClickable: false }
                    ]
                }
            },
            {
                route: '/:tenant/kontohistorie', name: 'kontohistorie', moduleId: PLATFORM.moduleName('./pages/kontohistorie'), title: 'Meine Punkteübersicht',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Mein Konto', routeName: 'start', routeParams: ['tenant'], isClickable: false }
                    ]
                }
            },
            {
                route: '/:tenant/bestellungen', name: 'bestellhistorie', moduleId: PLATFORM.moduleName('./pages/bestellungen/index'), title: 'Meine Bestellungen',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Mein Konto', routeName: 'start', routeParams: ['tenant'], isClickable: false }
                    ]
                }
            },
            {
                route: '/:tenant/scheckheft', name: 'scheckheft', moduleId: PLATFORM.moduleName('./pages/scheckheft/index'), title: 'Mein Scheckheft',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Mein Konto', routeName: 'start', routeParams: ['tenant'], isClickable: false }
                    ]
                }
            },
            {
                route: '/:tenant/scheckheft/:membershipId/:name', name: 'scheckheft-details', moduleId: PLATFORM.moduleName('./pages/scheckheft/detail'), title: 'Scheckheft Details',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Mein Konto', routeName: 'start', routeParams: ['tenant'], isClickable: false },
                        { name: 'Mein Scheckheft', routeName: 'scheckheft', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/scheckheft/:membershipId?/einreichen', name: 'scheckheft-einreichen', moduleId: PLATFORM.moduleName('./pages/scheckheft/einreichen'), title: 'Maßnahme einreichen',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Mein Konto', routeName: 'start', routeParams: ['tenant'], isClickable: false },
                        { name: 'Mein Scheckheft', routeName: 'scheckheft', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/warenkorb', name: 'warenkorb', moduleId: PLATFORM.moduleName('./pages/warenkorb/warenkorb'), title: 'Warenkorb',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/teilnehmer-news', name: 'teilnehmer-news', moduleId: PLATFORM.moduleName('./pages/teilnehmer-news'), title: 'Mein Postfach',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] }
                    ]
                }
            },
            {
                route: '/:tenant/bestellen', name: 'bestellen', moduleId: PLATFORM.moduleName('./pages/warenkorb/bestellen'), title: 'Prämien bestellen',
                settings: {
                    auth: true,
                    parentRoute: [
                        { name: programName, routeName: 'start', routeParams: ['tenant'] },
                        { name: 'Warenkorb', routeName: 'warenkorb', routeParams: ['tenant'] }
                    ]
                }
            }
        ]);

        config.fallbackRoute('/');
        config.addPipelineStep('authorize', AuthorizeStep);
        this.router = router;
    }

    public closeMenu() {
        this.nav.closeMobileMenu();
    }

    public scrollPageTopButton() {
        let y = window.pageYOffset;

        if (y > 500) {
            $('#btnScrollToTop').addClass("show");
        } else {
            $('#btnScrollToTop').removeClass("show");
        }
    }

    public scrollPageToTop() {
        window.scroll({ top: 0, behavior: 'smooth' });
    }
}


@autoinject()
class InitializeTenant {

    constructor(private tenantContext: TenantContext, private router: Router, private tenantService: TenantService, private config: ConfigProvider,
        private authProvider: AuthenticationProvider,) {

    }

    async run(navigationInstruction, next) {
        let vm = this;
        if (navigationInstruction.config.name === 'home') {
            if (navigationInstruction.queryParams.pt) {
                var currentTenant = this.tenantContext.getTenant();

                if (currentTenant && currentTenant.key == navigationInstruction.queryParams.pt) {
                    var url: string = navigationInstruction.router.generate("start", { tenant: currentTenant.key });
                    var rootpath = this.config.get<string>(Constants.aokRootPath);
                    rootpath = rootpath.substring(0, rootpath.length - 1);
                    document.location.href = rootpath + url;
                }
            }
        } else if (navigationInstruction.config.name == 'email-bestaetigung') {
            // Do Nothing - Pass through to the webpage
        }
        else if (navigationInstruction.queryParams.autologin) {
            var tenants = await this.tenantService.getTenantsByProgramType();
            var tenant = tenants.find(x => x.key == navigationInstruction.params.tenant);
            if (tenant) {

                this.tenantContext.setTenant(tenant);
                await this.authProvider.init(null);
                await this.authProvider.login(window.location.href.indexOf('?') != -1 ? (window.location.href + "&") : (window.location.href + "?"));
            }
        }
        else if (this.tenantContext.isTenantSet()) {
            if (navigationInstruction.params.tenant != this.tenantContext.getTenant().key) { //Tenant wurde in der URL geändert
                navigationInstruction.router.navigateToRoute('start', { tenant: this.tenantContext.getTenant().key });
            }
        }
        else {
            await this.fallbacktoDefault(navigationInstruction);
        }

        return next();
    }

    async fallbacktoDefault(navigationInstruction) {
        let tenant = (await this.tenantService.getTenantsByProgramType()).filter(t => t.key === navigationInstruction.params.tenant);
        if (tenant)
            this.tenantContext.setTenant(tenant[0]);
        else
            navigationInstruction.router.navigateToRoute('home');
    }
}

class PostCompleteStep {
    run(routingContext, next) {
        window.scroll({ top: 0, behavior: 'auto' });

        return next();
    }
}

@autoinject()
class AuthorizeStep {
    // https://gist.github.com/martonsagi/b70a094db52e0cc34fd7c44de550f046
    constructor(private auth: AuthService, private tenantContext: TenantContext, private router: Router) {

    }

    run(navigationInstruction, next) {

        //currently active route config
        let currentRoute = navigationInstruction.config;

        // settings object will be preserved during navigation
        let loginRequired: boolean = currentRoute.settings && currentRoute.settings.auth === true;

        if (loginRequired && !this.auth.IsAuthenticated()) {
            return next.cancel(new Redirect(this.getRedirectUrlOrDefault("start", this.router.options["base"])));
        }

        return next();
    }


    private getRedirectUrlOrDefault(routeName: string, basePath: string) {
        if (!basePath)
            basePath = "/";
        let redirectUrl = `${window.location.origin}${basePath}`;

        try {
            let tenant = this.tenantContext.getTenant();
            if (routeName && tenant) {

                let routeUrl = this.router.generate(routeName, { tenant: tenant.key });
                redirectUrl = `${window.location.origin}${basePath}${routeUrl}`;
            }
        } finally {
            return redirectUrl;
        }
    }
}
