import { AccountHistoryMapping } from './../../mappings/account-history-mapping';
import { QueryAccountStatementRequestDTO } from './../api/aok-rest-api';
import { StorageKey, StorageService } from './../storage/storage-service';
import { AuthenticationType, RestClient } from './../api/rest-client';
import { autoinject } from 'aurelia-framework';
import { AccountBalanceResponseDTO, ExpiringPointsResponseDTO, GetAccountBalanceRequestDTO } from '../api/aok-rest-api';
import * as moment from 'moment';
import { TenantContext } from '../tenant/tenant-context';
import { AccountHistoryViewModel } from '../../view-models/account-history-view-model';

@autoinject
export class AccountService{

    constructor(private rest: RestClient, private storageService:StorageService, private tenantProvider: TenantContext) {

    }

    public async getAccountBalance(membershipId: number): Promise<AccountBalanceResponseDTO>{
        let client = await this.rest.getRestApi(AuthenticationType.BEARER);
        let result = new AccountBalanceResponseDTO();
        let requestBody = new GetAccountBalanceRequestDTO();      
        result = await client.getAccountBalanceAppUsingPOST(membershipId, requestBody);
        return result;
    }

    public async getExpiringPoints(membershipId: number): Promise<ExpiringPointsResponseDTO>{
        var tenant = this.tenantProvider.getTenant();
        
        if(tenant.key.toLowerCase() == 'bayern' || tenant.key.toLowerCase() == 'baden_wuerttemberg'){
            let client = await this.rest.getRestApi(AuthenticationType.BEARER);
            let result = await client.getNextExpiringPointsAppUsingGET(membershipId);
            return result;
        }else{
            return null;
        }
    }

    public async getAccountStatement(membershipId: number, pageIndex: number, pageQuantity: number): Promise<AccountHistoryViewModel>{
        let client = await this.rest.getRestApi(AuthenticationType.BEARER);
        let result = new AccountHistoryViewModel();
        let request =  new QueryAccountStatementRequestDTO();
        request.pageNumber = pageIndex;
        request.pageQuantity = pageQuantity;
        request.statementStartDate = moment(new Date(2000, 1, 1));
        let response = await client.queryAccountStatementAppUsingPOST(membershipId, request);
        result = AccountHistoryMapping.AccountStatementResponseDTOToAccountHistoryViewModel(response);
        return result;
    }
}