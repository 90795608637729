// jquery-bootstrap-scrolling-tabs

import * as $ from 'jquery';
import 'jquery-bootstrap-scrolling-tabs/dist/jquery.scrolling-tabs.js';
//require 'jquery-bootstrap-scrolling-tabs/dist/jquery.scrolling-tabs.min.css';

export function initScrollingTabs(selector)  {
    
    $(selector).scrollingTabs({
            bootstrapVersion: 4,

            //cssClassLeftArrow: 'fal fa-chevron-left',
            //cssClassRightArrow: 'fal fa-chevron-right',
            leftArrowContent: [
                '<div class="scrtabs-tab-scroll-arrow scrtabs-tab-scroll-arrow-left waves-effect waves-dark">',
                '   <span class="fal fa-chevron-left"></span>',
                '</div>'
            ].join(''),
            rightArrowContent: [
                '<div class="scrtabs-tab-scroll-arrow scrtabs-tab-scroll-arrow-right waves-effect waves-dark">',
                '   <span class="fal fa-chevron-right"></span>',
                '</div>'
            ].join('')


            
    });
}