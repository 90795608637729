import { bindable, computedFrom, inject, containerless } from 'aurelia-framework';
import { NotificationService } from '../../../services/notifications/notificationService';

@inject(Element, NotificationService)
@containerless
export class VoltoModal {
    @bindable title: string;
    @bindable titleFallback: string;
    @bindable id: string;
    @bindable size: string;
    @bindable showclose: boolean = true;

    @bindable onClosing: () => boolean = () => true;
    @bindable onClosed: () => void = () => null;

    constructor(public element, private notifier: NotificationService) {

    }

    @computedFrom('showclose')
    get showcloseInternal(): boolean {
        return this.showclose.toString() == "true";
    }

    public attached() {
        let self = this;

        this.modalDialogElement().on('hidden.bs.modal', function (e) {
            self.onClosed();
        });

        this.modalDialogElement().on('hide.bs.modal', function (e) {
            if (!self.onClosing()) {
                e.preventDefault();
            }
        });

        this.modalDialogElement().on('show.bs.modal', () => this.notifier.enable(this.modalDialogElement()));
        this.modalDialogElement().on('hide.bs.modal', () => this.notifier.disable(this.modalDialogElement()));
    }

    public detached() {
        this.modalDialogElement().unbind();
    }

    private modalDialogElement(): JQuery<HTMLElement> {
        let modal = $('#' + this.id)
        return modal;
    }

    get modalDialogClasses(): string {
        var classes = ['modal-dialog'];

        if (this.size === 'Small')
            classes.push('modal-sm');

        if (this.size === 'Large')
            classes.push('modal-lg');

        return classes.join(' ');
    }
}