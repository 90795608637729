import {IConfigurationDTO} from "../service/api/aok-rest-api";
import {ConfigurationView} from "../view-models/configuration-view-models";


export class ConfigurationMappings {

    public static ConfigurationDTOtoConfigurationView(from?: IConfigurationDTO): ConfigurationView {
        if(!from) {
            return undefined;
        }

        let to = new ConfigurationView();

        to.displayValue = from.displayValue;
        to.key = from.key;

        return to;
    }

}