import { LoyaltyProgramFAQDTO } from "../service/api/aok-rest-api";
import { FaqViewModel } from "../view-models/faq-view-model";
import { yeast } from 'yeast';
import { AccordionViewModel } from "../view-models/accordion-view-model";

export class FaqMapping{

    public static LoyaltyProgramFAQDTOtoFaqViewViewModel(from?: LoyaltyProgramFAQDTO) : FaqViewModel{
        var yeast = require('yeast');

        if(!from) {
            return null;
        }

        let to = new FaqViewModel();
        to.category = from.category;
        to.formattedText = from.formattedAnswer;
        to.title = from.question;
        to.accordionId = "collapse_" + yeast().replace(".","_"); // + from.question.replace(" ","_"); // "collapse" + String(Math.random() * 100+1 | 0);
        return to;

    }

    
}