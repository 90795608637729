import { NotificationService } from './../notifications/notificationService';
import { IoC } from './../dependencies/ioc';
import { FormRenderer } from './formRenderer';
import { StandardValidator, ValidationRules, ValidationControllerFactory, ValidationController, ValidateInstruction, ControllerValidateResult, validateTrigger, ValidationMessageProvider, ValidationMessageParser, validationMessages, Rule } from 'aurelia-validation';
import { autoinject, transient, TaskQueue } from 'aurelia-framework';
import { LocalizationProvider } from '../localization/localizationProvider';
import { Expression } from 'aurelia-binding';

@autoinject
@transient()
export class ValidationProvider {

    public validationController: ValidationController;

    constructor(public validationControllerFactory: ValidationControllerFactory, renderer: FormRenderer, private notifier: NotificationService, private localizationProvider: LocalizationProvider, private validationMessageParser: ValidationMessageParser) {
        this.validationController = validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(renderer);
        this.validationController.validateTrigger = validateTrigger.change;

        (<any>ValidationMessageProvider).prototype.getMessage = (key) => {
            if(!key.startsWith('I18N_'))
            {
                if(validationMessages[key] != null && validationMessages[key].startsWith('I18N_'))
                    key = validationMessages[key];
                else
                    key = "I18N_VOLTOSPA_VALIDATION_" + key.toUpperCase();
            }
            var result = this.localizationProvider.translate(key, validationMessages[key]);
            return validationMessageParser.parse(result);
        };

        (<any>ValidationMessageProvider).prototype.getDisplayName = (propertyName: string, displayName?: string | null | (() => string)) => {
            var fallback = null;
            if (displayName !== null && displayName !== undefined) {
                fallback = (displayName instanceof Function) ? displayName() : displayName as string;
            }
            else {
                const words = propertyName.split(/(?=[A-Z])/).join(' ');
                fallback = words.charAt(0).toUpperCase() + words.slice(1);
            }
            return this.localizationProvider.translate(fallback);
        };
    }

    public async isValid(object?: object, rules?: ValidationRules): Promise<boolean> {
        this.validationController.reset()
        let result: ControllerValidateResult;
        if (object || rules) {
            result = await this.validationController.validate({ object: object, rules: rules });
        }
        else {
            result = await this.validationController.validate();
        }
        return result.valid;
    }

    public clear() {
        this.validationController.changeTrigger(validateTrigger.manual);
        this.validationController.reset();
        this.notifier.clear(true);
        window.setTimeout(() => this.validationController.changeTrigger(validateTrigger.change), 100);
    }
}