import { Container } from 'aurelia-dependency-injection';

export class IoC {
    public static register(key: any, implementation: any): void {
        Container.instance.root.autoRegister(key, implementation);
    }

    public static replace(key: any, implementation: any): void {
        Container.instance.root.unregister(key);
        this.register(key, implementation);
    }

    public static get<T>(key: any): T {
        return Container.instance.root.get(key) as T;
    }

    public static getAll<T>(key: any): T[] {
        return Container.instance.root.getAll(key) as T[];
    }
}