import { autoinject } from 'aurelia-framework';
import { Logger } from '../logging/logger';

@autoinject
export class ModalDialogService {

    constructor(private logger: Logger) {

    }

    public show(modalDialogId: string) {        
        this.modalDialogElement(modalDialogId).modal({
            show: true,
            keyboard: false,
            backdrop: 'static'
          });
    }

    public hide(modalDialogId: string) {
        this.modalDialogElement(modalDialogId).modal('hide');
    }

    private modalDialogElement(modalDialogId: string): JQuery<HTMLElement>{
        let modal = $('#' + modalDialogId) 

        if(modal.length === 0){
            this.logger.error('volto-modal element with id=' + modalDialogId + ' was not found.');
        }

        return modal;
    }
}