import { autoinject, FrameworkConfiguration, PLATFORM } from 'aurelia-framework';
import { Language } from '../../models/localization/language';
import { LocalizationProvider } from './localizationProvider';
import { ConfigProvider } from '../configuration/configProvider';
import * as Cookies from 'js-cookie';
import { I18N, Backend } from 'aurelia-i18n';
import * as i18Nxhr from 'i18next-xhr-backend';

@autoinject
export class DefaultLocalizationProvider extends LocalizationProvider {

    constructor(private configProvider: ConfigProvider, private i18n: I18N) {
        super();
    }

    public async getLanguages(): Promise<Language[]> {
        return new Promise<Language[]>(resolve => {
            var languages = this.configProvider.get<Language[]>('languages');
            if(languages==null)
                resolve(null);
            var currentLocale = this.i18n.getLocale();
            for (var i = 0; i < languages.length; i++) {
                languages[i].isActive = languages[i].culture == currentLocale;
            }
            resolve(languages)
        });
    }

    public async setLocale(languageId: string): Promise<void> {
        Cookies.set('locale', languageId);
        return this.i18n.setLocale(languageId);
    }

    public async getLocale(): Promise<string> {

        return new Promise<string>(resolve => {
            var locale = Cookies.get('locale');
            if (locale == null) {
                locale = this.configProvider.get<string>('defaultCulture');
            }
            resolve(locale);
        });
    }

    public translate(key: string, fallback?: string): string {
        let exists = this.i18n.i18next.exists(key);
        return !exists ? (fallback ? fallback : key) : this.i18n.tr(key);
    }

    public async init(config: FrameworkConfiguration): Promise<void> {
        var defaultPath = 'config/{{lng}}.json';
        var path = this.configProvider.get("translationFilesPath");
        config.plugin(PLATFORM.moduleName('aurelia-i18n'), async (instance) => {
            instance.i18next.use(i18Nxhr);
            var locale = await this.getLocale();
            return instance.setup({
                backend: {
                    loadPath: path != null ? path : window["rootPath"] ? window["rootPath"] + defaultPath : defaultPath,
                },
                lng: locale,
                fallbackLng: false,
                load: 'currentOnly',
                debug: false
            });
        });
    }
}