import { AccordionViewModel } from "./accordion-view-model";

export class FaqViewModel extends  AccordionViewModel{
    category?: string | undefined;
}

export class GroupedFaqViewModel{
    category: string | undefined;
    questions: FaqViewModel[] | undefined;
}

