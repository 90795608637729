import { Constants } from './../../../constants/constants';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';

@autoinject()
export class Pagination{

    @bindable
    public pagesize: number;

    @bindable
    public itemcount: number;

    public currentPage: number;
    public totalPages:number;

    constructor(private eventAggregator: EventAggregator){
        
    }

    public async attached(){
        if(this.pagesize && this.itemcount){

            if(this.itemcount > 0 && this.pagesize > 0){
                this.totalPages = Math.ceil( this.itemcount / this.pagesize);
                this.currentPage = 1;
                this.publishPageChangeEvent();
            }
        }
    }

    public gotoFirstPage(){
        this.currentPage = 1;
        this.publishPageChangeEvent();
    }

    public gotoLastPage(){
        this.currentPage = this.totalPages;
        this.publishPageChangeEvent();
    }

    public gotoNextPage(){
        this.currentPage = this.currentPage + 1;
        this.publishPageChangeEvent();
    }

    public gotoPreviousPage(){
        this.currentPage = this.currentPage - 1;
        this.publishPageChangeEvent();
    }

    private publishPageChangeEvent(){
        this.eventAggregator.publish(Constants.pageChangedEvent, {pageIndex: (this.currentPage - 1), pageSize: this.pagesize});
        
        var divScrollTotop = document.getElementById('divPagerTop');
        if(divScrollTotop){
            divScrollTotop.scrollIntoView();
        }
    }
}