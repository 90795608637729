import * as $ from 'jquery';
/*
 * Mobile Checkbox-Toggles
 */

export function initMobileCheckboxes(selector) {
    
    $(selector).each(function (index, item) {
        let checkboxContainerElem = $(item);
        let checkboxInputElem = checkboxContainerElem.find("input");
        let checkboxLabelElem = checkboxContainerElem.find("label");
        checkboxInputElem.css({ "visibility": "hidden", "position": "absolute" });
        checkboxContainerElem.css({ "padding-left": "0" });
        let toggleElem = document.createElement("div");
        toggleElem.setAttribute("class", "toggle");
        let toggleSlideElem = document.createElement("div");
        toggleSlideElem.setAttribute("class", "slide");
        let toggleHandleElem = document.createElement("div");
        toggleHandleElem.setAttribute("class", "handle");
        toggleElem.appendChild(toggleSlideElem);
        toggleElem.appendChild(toggleHandleElem);
        item.appendChild(toggleElem);

        checkboxLabelElem.on("click",
        function (e) {
            e.preventDefault();
        });

        checkboxContainerElem.on("click", function (e) {
            checkboxContainerElem.toggleClass("checked");
            checkboxInputElem.prop("checked", !checkboxInputElem.prop("checked"));
            var event = document.createEvent("Event");
            event.initEvent("change", false, true); 
            checkboxInputElem.get(0).dispatchEvent(event);
        });
    });
}