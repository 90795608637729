import * as $ from 'jquery';
import 'bootstrap-datepicker/dist/js/bootstrap-datepicker.min.js';
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.min.css';

export function initDatePicker(selector) {
    $(selector).datepicker({
         format: 'dd.mm.yyyy',
         //clearBtn: true,
         language: 'de-DE',
         clear: 'Clears',
         todayBtn: 'linked'
     
    });
}