import { ConfigProvider, Logger } from 'voltospa';
import { autoinject } from 'aurelia-framework';
import { TenantService } from '../../../service/tenant/tenant-service';
import { TenantContext } from '../../../service/tenant/tenant-context';
import { LoyaltyProgramViewModel, ProgramType } from "../../../view-models/tenant-view-models";
import { UILayoutService } from "../../../service/ui/ui-layout-service";
import { UiNavigationService } from "../../../service/ui/ui-navigation-service";
import { Constants } from '../../../constants/constants';
import * as moment from 'moment';

@autoinject
export class PageBase {

    program?: ProgramType;
    public rootPath: string = "/";

    constructor(public tenantService: TenantService, public config: ConfigProvider, public tenantProvider: TenantContext, public layout: UILayoutService, public logger: Logger, public nav: UiNavigationService) {
        this.program = tenantService.getProgramTypeFromUrl();
        this.rootPath = this.config.get<string>(Constants.aokRootPath);
        layout.setMainLayout();
    }

    async detached() {
        this.nav.closeMobileMenu();
    }

    public getLoyalityProgram(): LoyaltyProgramViewModel {
        var activeTenant = this.tenantProvider.getTenant();
        return activeTenant.programs.filter(lp => lp.programType == this.program)[0];
    }

    public getDateFromString(value: string, format: string = "DD.MM.YYYY"): moment.Moment {

        var date = moment(value, format);
        if (!date.isValid()) {
            date = moment(date);
        }

        return date;
    }

    public scrollPageToTop() {
        window.scroll({ top: 0, behavior: 'auto' });
    }
}