import { Language } from '../../models/localization/language';
import { LocalizationProvider } from './localizationProvider';
import { FrameworkConfiguration } from 'aurelia-framework';

export class NoLocalizationProvider extends LocalizationProvider {
    public async getLanguages(): Promise<Language[]> {
        return Promise.resolve(null);
    }

    public async setLocale(languageId: string): Promise<void> {
        return Promise.resolve();
    }

    public async getLocale(): Promise<string> {
        return Promise.resolve(null);
    }

    public translate(key: string, fallback?: string): string {
        return fallback ? fallback : key;
    }
    
    public async init(config: FrameworkConfiguration): Promise<void> {
        return Promise.resolve();
    }
}