import { FrameworkConfiguration } from 'aurelia-framework';
import { User } from "../../models/security/user";

export abstract class AuthenticationProvider {
    public abstract async init(config: FrameworkConfiguration): Promise<void>;
    public abstract login(returnUrl: string): Promise<void>;
    public abstract isAuthenticated(): Promise<boolean>;
    public abstract getUser(): Promise<User>;
    public abstract logout(returnUrl: string): Promise<void>;
    public abstract authenticationActive(): boolean;
    public abstract async handleAuthenticationCallbacks(): Promise<void>;
}