import { HttpClient } from 'aurelia-fetch-client';
import { ConsoleAppender } from 'aurelia-logging-console';
import { ConfigOptions } from './../../ConfigOptions';
import { inject, FrameworkConfiguration, PLATFORM, LogManager, NewInstance } from 'aurelia-framework';
import { ConfigProvider } from './configProvider';

@inject(NewInstance.of(HttpClient))
export class DefaultConfigProvider extends ConfigProvider {

    private config;

    constructor(private http: HttpClient) {
        super();
    }

    public async init(config: FrameworkConfiguration, options: ConfigOptions): Promise<void> {
        var response = await this.http.fetch(options.configPath);
        if (response.status > 299 || response.status < 200) {
            return null;
        }
        this.config = await response.json();
    }

    public get<T>(key: string): T {
        if (this.config == null)
            return null;
        if (key.indexOf('.') === -1) {
            return (this.config[key] ? this.config[key] : null) as T;
        }
        if (key.indexOf('.') !== -1) {
            let splitKey = key.split('.');
            let parent = splitKey[0];
            let child = splitKey[1];

            if (this.config[parent]) {
                return (this.config[parent][child] ? this.config[parent][child] : null) as T;
            }
        }
        return null;
    }
}