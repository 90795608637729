import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { PageBase } from '../contents/Base/page-base';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../../service/tenant/tenant-context';
import { TenantService } from '../../service/tenant/tenant-service';
import { UILayoutService } from "../../service/ui/ui-layout-service";
import { UiNavigationService } from "../../service/ui/ui-navigation-service";
import { OrderViewModel, OrderItemViewModel } from '../../view-models/order-view-model';
import { AddressViewModel } from '../../view-models/address-view-model';
import { Constants } from '../../constants/constants';
import { AuthService } from '../../service/auth/auth-service';
import { OrderItemDTOStatus } from '../../service/api/aok-rest-api';
import { BackendConfigurationService } from '../../service/configuration/backend-configuration-service';
import { ConfigurationView } from '../../view-models/configuration-view-models';
import { OrderService } from '../../service/order/order-service';
import { ProfileService } from '../../service/profile/profile-service';
import { ProfileViewModel } from '../../view-models/profile-view-model';

@autoinject()
export class Index extends PageBase {

    public orders: OrderViewModel[] = [];
    public ordersCount: number = 0;
    public hasOrders: boolean = false;
    private statusValues: ConfigurationView[];
    private userProfile: ProfileViewModel = null;
    private subscriptions: Array<any>;
    public ordersToDisplay: OrderViewModel[] = [];

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        private auth: AuthService, public nav: UiNavigationService, private eventAggregator: EventAggregator, 
        private backendConfigurationService: BackendConfigurationService, private orderService: OrderService,
        private profileService: ProfileService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        if (!this.auth.IsAuthenticated()) {
            this.nav.navigate("start");
        }

        this.handleEvents();
    }

    async attached() {
        this.userProfile = await this.profileService.getUserProfile(false);
        this.orders = await this.orderService.getOrders(this.userProfile.mainInsurer.membership.membershipId);

        if (this.orders && this.orders.length > 0) {
            this.hasOrders = true;
        }

        this.ordersCount = this.orders.length;
    }

    //Pager
    public async detached() {
        this.subscriptions.forEach(subscription => {
            subscription.dispose();
        });
    }

    private handleEvents() {
        this.subscriptions = [];

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.pageChangedEvent, (data) => {
            this.pageChangedEvent(data)
        }));
    }

    private pageChangedEvent(data: any) {
        var startIndex = data.pageIndex * data.pageSize;
        this.ordersToDisplay = this.orders.slice(startIndex, startIndex + data.pageSize);
    }

    public GetTypeTranslation(from: OrderItemDTOStatus): string{
        let result: string="";
        result=this.statusValues.filter(c => c.key == from.toString())[0].displayValue;

        return result;
    }

}
