import { autoinject } from 'aurelia-framework';
import { PageBase } from './contents/Base/page-base';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { GroupedFaqViewModel } from '../view-models/faq-view-model';
import { FaqService } from '../service/faq/faq-service';

@autoinject()
export class Faq extends PageBase {


    public faqs: GroupedFaqViewModel[] = [];

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, public nav: UiNavigationService, public faqService: FaqService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
    }

    async attached() {
        this.faqs = await this.faqService.getFaqByTenantandProgramType();
    }

}