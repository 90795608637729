import { AdditionalInsuredRegistrationDTO, RegistrationFormRequestDTO, RegistrationFormRequestDTOChequeBookDelivery, RegistrationFormRequestDTOSalutation, RegistrationFormResultDTO, RegistrationFormResultDTOAddressValidationResult } from '../service/api/aok-rest-api';
import { ParticipantViewModel } from './../view-models/participant-view-model';
import * as moment from 'moment';
import { CoCollectorViewModel } from '../view-models/co-collector-view-model';
import { AddressValidationResult, RegistrationResult } from '../view-models/registration-result';

export class ParticipantMapping {

    public static ParticiapntViewModelToRegistrationFormRequestDTO(from?: ParticipantViewModel): RegistrationFormRequestDTO {
        if (!from) {
            return null;
        }
        let to = new RegistrationFormRequestDTO();
        to.registerAdditionalInsured = false
        to.mediaCode = undefined;

        to.additionalInsured = new Array<AdditionalInsuredRegistrationDTO>();
        from.coCollectorsArray.forEach(element => {
            var additionalInsured = new AdditionalInsuredRegistrationDTO();
            if (element.kvnr != undefined) {
                additionalInsured.kvnr = element.kvnr.toUpperCase();
                to.additionalInsured.push(additionalInsured);
            }
        });
        to.chequeBookDelivery = RegistrationFormRequestDTOChequeBookDelivery[from.chequeBookDelivery];
        to.dataUsageConsentDate = moment();
        to.salutation = RegistrationFormRequestDTOSalutation[from.salutation];
        to.birthdate = moment.utc({ year: from.birthdate.getFullYear(), month: from.birthdate.getMonth(), day: from.birthdate.getDate() });
        to.onlineAccessRequested = from.onlineAccessRequested;
        to.addressSupplement = from.addressSupplement;
        to.email = from.email;
        to.kvnr = from.kvnr.toUpperCase();
        to.countryCode = from.countryCode;
        to.city = from.city;
        to.phone1 = from.phone1;
        if (to.onlineAccessRequested == true) {            
            to.mobilePhone2FA = this.prepareTelno(from.mobilePhone2FA);
        }
        to.phone2 = from.phone2;
        to.street = from.street;
        to.streetNo = from.streetNo;
        if (from.taxNumber)
            to.taxNumber = from.taxNumber.length > 0 ? from.taxNumber : undefined;
        to.title = from.title;
        to.zip = from.zip;
        to.firstname = from.firstname;
        to.lastname = from.lastname;
        to.mainCollectorKVNR = undefined;
        to.registerAdditionalInsured = from.coCollectors;
        to.namePrefix = from.namePrefix;
        if (from.allowAdvertisingConsent) {
            to.advertisingConsentDate = moment();
        }


        return to;
    }

    public static CoCollectorViewModelToRegistrationFormRequestDTO(from?: CoCollectorViewModel): RegistrationFormRequestDTO {

        if (!from) {
            return null;
        }

        let to = new RegistrationFormRequestDTO();
        to.registerAdditionalInsured = true

        to.mediaCode = undefined;
        to.chequeBookDelivery = RegistrationFormRequestDTOChequeBookDelivery[from.chequeBookDelivery];
        to.dataUsageConsentDate = moment();
        to.salutation = RegistrationFormRequestDTOSalutation[from.salutation];
        to.birthdate = moment.utc({ year: from.birthdate.getFullYear(), month: from.birthdate.getMonth(), day: from.birthdate.getDate() });
        to.onlineAccessRequested = from.onlineAccessRequested;
        to.addressSupplement = from.addressSupplement;
        to.email = from.email;
        to.kvnr = from.kvnr.toUpperCase();
        to.countryCode = from.countryCode;
        to.city = from.city;
        to.phone1 = from.phone1;
        if (to.onlineAccessRequested == true) {
            to.mobilePhone2FA = this.prepareTelno(from.mobilePhone2FA);
        }
        to.phone2 = from.phone2;
        to.street = from.street;
        to.streetNo = from.streetNo;
        to.taxNumber = from.taxNumber.length > 0 ? from.taxNumber : undefined;
        to.title = from.title;
        to.zip = from.zip;
        to.firstname = from.firstname;
        to.lastname = from.lastname;
        to.dataUsageConsentDate
        to.mainCollectorKVNR = from.mainCollectorKVNR;
        to.namePrefix = from.namePrefix;
        if (from.allowAdvertisingConsent) {
            to.advertisingConsentDate = moment();
        }
        return to;
    }

    public static RegistrationFormResponseDTOtoRegistrationResult(from: RegistrationFormResultDTO): RegistrationResult {
        let to = new RegistrationResult();

        switch (from.addressValidationResult) {
            case RegistrationFormResultDTOAddressValidationResult.ADDRESS_AMBIGUOUS:
                to.addressValidationResult = AddressValidationResult.ADDRESS_AMBIGUOUS;
                break;
            case RegistrationFormResultDTOAddressValidationResult.ADDRESS_CORRECTED:
                to.addressValidationResult = AddressValidationResult.ADDRESS_CORRECTED;
                break;
            case RegistrationFormResultDTOAddressValidationResult.ADDRESS_NOT_OK:
                to.addressValidationResult = AddressValidationResult.ADDRESS_NOT_OK;
                break;
            case RegistrationFormResultDTOAddressValidationResult.ADDRESS_OK:
                to.addressValidationResult = AddressValidationResult.ADDRESS_OK;
        }

        to.participantId = from.participantId;
        return to;
    }

    private static prepareTelno(telno: string): string {
        telno = telno.replace(new RegExp(/[\s\/\-]/g), '');
        return telno.replace('+', '00');
    }
}