import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { CountryViewModel } from '../../../view-models/country-view-model';
import { Validator } from 'aurelia-validation';
import { ParticipantViewModel } from '../../../view-models/participant-view-model';
import { SalutationViewModel } from '../../../view-models/salutation-view-model';

@autoinject()
export class AokAdresse {
    //export class AokAdresse {    

    public tenant: string = '';
    @bindable helptext: string;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: ParticipantViewModel = new ParticipantViewModel();
    public anrede: Array<SalutationViewModel> = new Array<SalutationViewModel>();
    public laender: CountryViewModel[];

    constructor(public validator: Validator) {

        this.anrede = [
            { salutationId: "MALE", description: "Herr" },
            { salutationId: "FEMALE", description: "Frau" },
            { salutationId: "DIVERSE", description: "Divers" }
        ];

        this.laender = [
            { countryCode: "DE", description: "Deutschland", disabled: false },
            { countryCode: "XXX", description: "-------------------", disabled: true },
            { countryCode: "F", description: "Frankreich", disabled: false },
            { countryCode: "NL", description: "Niederlande", disabled: false },
            { countryCode: "A", description: "Österreich", disabled: false },
            { countryCode: "PL", description: "Polen", disabled: false },
            { countryCode: "CH", description: "Schweiz", disabled: false },
            { countryCode: "CZ", description: "Tschechische Republik", disabled: false },
            
        ];
    }

    async attached() {

    }
}