export class AccountHistoryViewModel{

    accountId?: number | undefined;
    pageNumber?: number | undefined;
    transactionCount?: number | undefined;
    transactions?: AccountTransaction[] | undefined;
}

export class AccountTransaction{

    description?: string | undefined;
    points?: number | undefined;
    transactionDate?: Date | undefined;
    transactionType?: string | undefined;
    postingDate?: Date | undefined;
}