import { autoinject } from 'aurelia-framework';
import { TenantContext } from "../tenant/tenant-context";
import { TenantService } from "../tenant/tenant-service";
import { AuthenticationType, RestClient } from "../api/rest-client";
import { ConfigProvider } from "voltospa";
import { LoyaltyProgramFAQDTO, TenantDTO } from "../api/aok-rest-api";
import { FaqViewModel,GroupedFaqViewModel } from '../../view-models/faq-view-model';
import { FaqMapping } from '../../mappings/faq-mapping';


@autoinject
export class FaqService {
    private sortArray = ['Beendigung', 'Teilnahme', 'Aktivitäten', 'Prämien', 'Login']

    constructor(private rest: RestClient, private config: ConfigProvider, private tenantContext: TenantContext, private tenantService: TenantService) {

    }

    public async getFaqByTenantandProgramType(): Promise<GroupedFaqViewModel[]> {
        let tenant = this.tenantContext.getTenant();
        let programType = this.tenantService.getProgramTypeFromUrl();
        let program = tenant.programs.filter(p => p.programType == programType)[0];
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result: LoyaltyProgramFAQDTO[] = await client.getFAQUsingGET(program.id);
        let vm = this;

        let mappedresponse = result.map((t: LoyaltyProgramFAQDTO) => vm.mapFaq(t));
        let groupedResult: GroupedFaqViewModel[] = [];
        var uniqueCat = mappedresponse.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index);
        uniqueCat = this.sortCategories(uniqueCat);
        for (let cat in uniqueCat) {
            var o = new GroupedFaqViewModel();
            o.category = uniqueCat[cat];
            o.questions = mappedresponse.filter(m => m.category === uniqueCat[cat]);
            groupedResult.push(o);
        }

        return groupedResult;
    }

    private sortCategories(categories: string[]): string[] {
        var newArray = [];
        for (var sortValue of this.sortArray) {
            var cat = categories.find(x => x.startsWith(sortValue));
            if (cat)
                newArray.push(cat);
        }
        categories = categories.filter(function (el) { return newArray.indexOf(el) < 0; });
        newArray=newArray.concat(categories);

        return newArray;
    }


    private mapFaq(t: LoyaltyProgramFAQDTO): FaqViewModel {
        // Mapping DTO to view
        return FaqMapping.LoyaltyProgramFAQDTOtoFaqViewViewModel(t);;
    }
}