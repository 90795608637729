import { MenuItem } from './../../models/navigation/menuItem';
import { autoinject, PLATFORM } from 'aurelia-framework';
import { Router, RouterConfiguration, RouteConfig } from 'aurelia-router';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { LocalizationProvider } from '../localization/localizationProvider';
import { RouterAuthorizeStepProvider } from '../security/routerAuthorizeStepProvider';
import { AuthorizationProvider } from "../security/authorizationProvider";
import { AuthenticationProvider } from "../security/authenticationProvider";

@autoinject
export abstract class MenuProvider {
    public abstract async getMenu(): Promise<MenuItem[]>;
    public abstract async getFooter(): Promise<MenuItem[]>;
    public allItems = new Array<MenuItem>();

    private navigationSubscription: Subscription

    constructor(public router: Router, public eventAggregator: EventAggregator, public localizationProvider: LocalizationProvider, public authorizationProvider: AuthorizationProvider, public authenticationProvider: AuthenticationProvider) {
        this.navigationSubscription = this.eventAggregator.subscribe('router:navigation:success', () => {
            this.setActiveItem();
        });
    }

    public setActiveItem(): void {
        this.allItems.forEach(m => m.active = false);
        if (this.router.currentInstruction != null) {
            var item = this.allItems.find(m => m.path == this.router.currentInstruction.config.name);
            if(item != null) {
                item.active = true;
                this.eventAggregator.publish('voltospa:navigate');
                document.title = this.localizationProvider.translate(item.text);
            }
        }
    }

    private populateMenuLevel(menuItems: MenuItem[], routeEntries: RouteConfig[]): void {
        if (menuItems == null)
            return;
        for (let menuItem of menuItems) {
            this.allItems.push(menuItem);
            this.fillParents(menuItem);
            if (menuItem.url != null && menuItem.path != null) {
                routeEntries.push({
                    route: menuItem.url,
                    moduleId: PLATFORM.moduleName(menuItem.path),
                    name: menuItem.path,
                    permissions: menuItem.permissions
                });
            }
            this.populateMenuLevel(menuItem.children, routeEntries);
        }
    }

    public async getBreadcrumb(): Promise<MenuItem[]> {
        return new Promise<MenuItem[]>(resolve => {
            var item = this.allItems.find(m => m.active);
            if (item == null)
                return null;
            var parents = new Array<MenuItem>();
            parents.push(item);
            while (item.parent != null) {
                parents.push(item.parent);
                item = item.parent;
            }
            parents.reverse();
            resolve(parents);
        });
    }

    public async getAuthorizedMenuItems(): Promise<MenuItem[]>{
        let menu = await this.getMenu();        
        return await this.getFilterdMenuItemsByPermission(menu);
    }

    private async getFilterdMenuItemsByPermission(menuItems: MenuItem[]): Promise<MenuItem[]>{        
        let authenticationActive = this.authenticationProvider.authenticationActive();
        if (!authenticationActive) 
            return menuItems;

        let filterdMenuItemsByPermission: MenuItem[] = [];
        for (let index = 0; index < menuItems.length; index++) {
            const menuItem = menuItems[index];
            let hasMenuItemPermission: boolean = await this.hasUserMenuItemPermission(menuItem); 
            if(hasMenuItemPermission){
                if(menuItem.children && menuItem.children.length > 0){
                    menuItem.children = await this.getFilterdMenuItemsByPermission(menuItem.children);
                }
                filterdMenuItemsByPermission.push(menuItem);
            }
        }

        return filterdMenuItemsByPermission;
    }

    private async hasUserMenuItemPermission(menuItem: MenuItem):Promise<boolean>{
        let isUserPermited = true;
        let requiredPermissions: string[] = menuItem.permissions;
        if (requiredPermissions == null || requiredPermissions.length == 0)            
            return isUserPermited;

        for (let index = 0; index < requiredPermissions.length; index++) {
            const requiredPermission = requiredPermissions[index];
            isUserPermited = await this.authorizationProvider.hasPermission(requiredPermission);
            if(isUserPermited)
                break;
        }    

        return isUserPermited;
    }

    public async populateRouter(): Promise<void> {
        let menuItems = await this.getMenu();
        let routeConfig: RouterConfiguration = new RouterConfiguration();
        let routeEntries: RouteConfig[] = new Array<RouteConfig>();
        this.populateMenuLevel(menuItems, routeEntries);
        routeConfig.addAuthorizeStep(RouterAuthorizeStepProvider)
        routeConfig.map(routeEntries);        
        this.router.configure(routeConfig);
        routeConfig.exportToRouter(this.router);
        this.router.refreshNavigation();
        
        this.eventAggregator.publish('voltospa:router-configured');
    }

    protected fillParents(parent: MenuItem): void {
        if (parent.children == null)
            return;
        for (let item of parent.children) {
            item.parent = parent;
        }
    }
}