import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject, bindable } from 'aurelia-framework';
import { Constants } from '../../constants/constants';
import { OrderItemDTOStatus, OrderItemDTOType } from '../../service/api/aok-rest-api';
import { ImageSize } from '../../service/api/resource-rest-api';
import { AuthService } from '../../service/auth/auth-service';
import { ProfileService } from '../../service/profile/profile-service';
import { ResourceService } from '../../service/resource/resource-service';
import { StorageKey, StorageService } from '../../service/storage/storage-service';
import { UILayoutService } from '../../service/ui/ui-layout-service';
import { UiNavigationService } from '../../service/ui/ui-navigation-service';
import { OrderItemViewModel } from '../../view-models/order-view-model';
import { ProfileViewModel } from '../../view-models/profile-view-model';
import { RewardViewModel } from '../../view-models/reward-view-model';


@autoinject()
export class AokPremiumShopCard {

    @bindable
    public card: RewardViewModel = new RewardViewModel();

    @bindable
    public rewardgroupId: string;

    public isInShoppingCart: boolean = false;
    public imageUrl: string;
    public backgroundImageStylestring: string = "";
    public userProfile  = new ProfileViewModel();

    constructor(private layout: UILayoutService, public nav: UiNavigationService, private resourceService: ResourceService, 
        private storageService: StorageService, private eventAggregator:EventAggregator,   private profileService: ProfileService,
        private auth: AuthService) {

    }

    async navigateToDetail() {
        await this.nav.navigate('premium-details', { id: this.rewardgroupId, premiumId: this.card.id });
    }

    async attached() {
        if(this.auth.IsAuthenticated()){
            this.userProfile = await this.profileService.getUserProfile(false);    
        }
        
        if (this.card && this.card.thumbnail) {
            let imageFile = await this.resourceService.getResourceById(this.card.thumbnail.resourceId, ImageSize.M);
            this.imageUrl = URL.createObjectURL(imageFile);
            this.backgroundImageStylestring = "background-image: url(" + this.imageUrl + ");";
            this.isInShoppingCart= await this.checkIfOrderIsInShoppingCart();
        }
    }

    async orderReward() {
        let orders: OrderItemViewModel[] = [];
        
        if(await this.checkIfOrderIsInShoppingCart() == false){
            let order = this.setNewOrder(this.card);

            let shoppingCart = await this.storageService.GetObject<RewardViewModel[]>(StorageKey.ShoppingCart);
            if (shoppingCart != undefined) {
                orders = shoppingCart;
            }
            orders.push(order);
            await this.storageService.SetObject(StorageKey.ShoppingCart, orders);
            this.isInShoppingCart=true;
            await this.eventAggregator.publish(Constants.warenkorbItemChangedEvent);
        }
    }

    private setNewOrder(card: RewardViewModel): OrderItemViewModel{
        let order = new OrderItemViewModel();
        order.amount = 1;
        order.articleNumber=card.articleNumber;
        order.description= card.shortDescription;
        order.name = card.title;
        order.status = OrderItemDTOStatus.CREATED;
        order.valuePerUnit = card.points; 
        //order.unitCount = 1;
        order.resourceId = card.thumbnail.resourceId;
        order.rewardId = card.rewardId;
        order.type = OrderItemDTOType[card.extendedType.toString()];
        return order;
    }

    private async checkIfOrderIsInShoppingCart() : Promise<boolean>{
        let shoppingCart = await this.storageService.GetObject<RewardViewModel[]>(StorageKey.ShoppingCart);
        if(shoppingCart == undefined){ 
            return false;
        }
        else if(shoppingCart.every(x => x.articleNumber != this.card.articleNumber)) { 
            return false
        }
        else {
            return true;
        }
    }
}