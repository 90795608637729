export class ScheckheftDetailViewModel {
    selectAllForPrint?: boolean | undefined = false;
    year?: number | undefined;
    activities?: ActivityViewModel[] | undefined;
    categories?: string[] | undefined;
}

export class ActivityViewModel {
    id?: number | undefined;
    name?: string | undefined;
    category?: string | undefined;
    key?: string | undefined;
    description?: string | undefined;
    points?: number | undefined;
    status?: string | undefined;
    displayStatus?: string | undefined;
    selectedForPrint?: boolean | undefined = false;
    proof?: string | undefined;
}

export class YearViewModel {
    displayString?: string | undefined;
    year?: number | undefined;
    id?: string | undefined;
}