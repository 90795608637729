import { BootstrapErrorRenderer } from './../contents/CustomElements/BootstrapErrorRenderer';
import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { PageBase } from './../contents/Base/page-base';
import { ConfigProvider, Logger, NotificationService } from 'voltospa';
import { TenantContext } from '../../service/tenant/tenant-context';
import { TenantService } from '../../service/tenant/tenant-service';
import { UILayoutService } from "../../service/ui/ui-layout-service";
import { UiNavigationService } from "../../service/ui/ui-navigation-service";
import { ValidationController, ValidationRules, ValidationControllerFactory } from 'aurelia-validation';
import { DeliveryViewModel } from '../../view-models/delivery-view-model';
import { ParticipantViewModel } from '../../view-models/participant-view-model';
import { LoyaltyProgramViewModel, ProgramInfo, TenantViewModel } from '../../view-models/tenant-view-models';
import { EventAggregator } from 'aurelia-event-aggregator';
import { Constants } from './../../constants/constants';
import { CoCollectorViewModel } from '../../view-models/co-collector-view-model';
import { ParticipationService } from '../../service/participation/participation-service';
import { AddressValidationResult, RegistrationResult } from '../../view-models/registration-result';
import { CountryViewModel } from '../../view-models/country-view-model';
import { SalutationViewModel } from '../../view-models/salutation-view-model';


@autoinject()
export class TeilnahmeAnfordern extends PageBase {

    public tenant: string = '';
    public tenantInfo: ProgramInfo;
    public activeTenant: TenantViewModel;
    public coCollectorsTenantType: string;
    public captureCoCollector: boolean = false;
    public participationRequestSucceeded: boolean = false;
    public coCollectorRequestSucceeded: boolean = false;
    public enableButton:boolean=true;
    //public programId: number;
    public loyaltyProgram: LoyaltyProgramViewModel;
    public view: string = "participant";

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public adressuebernahme: boolean = false;

    public controlReadonly: boolean = true;
    public privacyStatementAcceptet: boolean = false;
    public allowAdvertisingConsent: boolean = false;
    public versichertennummerHelptext: string = this.controlReadonly ? "" : "Ihre Versichertennummer finden Sie auf Ihrer Gesundheitskarte.";

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public teilnehmer: ParticipantViewModel = new ParticipantViewModel();

    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public mitsammler: CoCollectorViewModel = new CoCollectorViewModel();

    public anrede: Array<string> = new Array<string>();
    public zustellweg: DeliveryViewModel[];

    private validationController: ValidationController;

    public mitsammlerAnrede: Array<SalutationViewModel> = new Array<SalutationViewModel>();
    public laender: CountryViewModel[];


    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, public nav: UiNavigationService,
        private validationControllerFactory: ValidationControllerFactory, private eventAggregator: EventAggregator, private participationService: ParticipationService, private notificationService: NotificationService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        this.tenant = this.tenantProvider.getTenant().key;

        this.zustellweg = [
            { deliveryId: "LETTER", Description: "per Post" },
            { deliveryId: "ONLINE", Description: "online" }
        ];

        this.mitsammlerAnrede = [
            { salutationId: "MALE", description: "Herr" },
            { salutationId: "FEMALE", description: "Frau" },
            { salutationId: "DIVERSE", description: "Divers" }
        ];

        this.laender = [
            { countryCode: "DE", description: "Deutschland", disabled: false },
            { countryCode: "XXX", description: "-------------------", disabled: true },
            { countryCode: "F", description: "Frankreich", disabled: false },
            { countryCode: "NL", description: "Niederlande", disabled: false },
            { countryCode: "A", description: "Österreich", disabled: false },
            { countryCode: "PL", description: "Polen", disabled: false },
            { countryCode: "CH", description: "Schweiz", disabled: false },
            { countryCode: "CZ", description: "Tschechische Republik", disabled: false },

        ];

        this.teilnehmer.firstRequest = true;
        this.teilnehmer.chequeBookDelivery = this.zustellweg.find(e => e.deliveryId == "ONLINE").deliveryId;
        this.teilnehmer.coCollectors = false;

        this.setActiveTenant();
        if (this.coCollectorsTenantType === "manual") {
            this.teilnehmer.coCollectorsArray.push(new CoCollectorViewModel());
        }
        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapErrorRenderer());
        this.loyaltyProgram = this.getLoyalityProgram();
        this.setValidationRules();

    }
    async activate(params: any, route, navigationInstruction) {
        if (params.kvnr) {
            this.teilnehmer.kvnr = params.kvnr.toUpperCase();
        } else {
            this.nav.navigate('teilnehmen');
        }
    }

    public async save() {        
        this.enableButton=false;
        this.notificationService.clear();
        this.teilnehmer.serverNotifications.clear();
        var isValid = await this.validationController.validate();
        this.eventAggregator.publish(Constants.inputValidatedEvent);

        if (isValid.valid == true) {
            try {
                this.teilnehmer.allowAdvertisingConsent = this.allowAdvertisingConsent;
                let response = await this.participationService.createRegistrationParticipant(this.loyaltyProgram.id, this.teilnehmer);
                if (response.exception != undefined) {
                    //handle exception
                    this.handleParticipantException(response);
                    isValid = await this.validationController.validate();
                    this.setNotificationForCheckingEntries();
                }
                if (response.addressValidationResult === AddressValidationResult.ADDRESS_OK) {
                    this.participationRequestSucceeded = true;
                }
                else if (response.addressValidationResult === AddressValidationResult.ADDRESS_NOT_OK) {
                    this.notificationService.error("Die angegebene Adresse ist nicht gültig.");
                }
                this.teilnehmer.firstRequest = false;
            } catch (error) {
                this.notificationService.error(error);
                this.scrollPageToTop();
            }
        }
        else {
            this.setNotificationForCheckingEntries();
        }        
        this.enableButton=true;
    }

    public async saveCoCollector() {
        this.enableButton=false;
        this.notificationService.clear();
        this.mitsammler.serverNotifications.clear();
        var isValid = await this.validationController.validate();
        this.eventAggregator.publish(Constants.inputValidatedEvent);

        if (isValid.valid) {
            this.mitsammler.mainCollectorKVNR = this.teilnehmer.kvnr;
            try {
                this.mitsammler.allowAdvertisingConsent = this.allowAdvertisingConsent;
                let response = await this.participationService.createRegistrationCoCollector(this.loyaltyProgram.id, this.mitsammler);
                if (response.exception != undefined) {
                    this.handleCoCollectorException(response);
                    isValid = await this.validationController.validate();
                    this.setNotificationForCheckingEntries();
                }
                if (response.addressValidationResult === AddressValidationResult.ADDRESS_OK) {
                    this.participationRequestSucceeded = true;
                    var cocollector = new CoCollectorViewModel();
                    cocollector.firstname = this.mitsammler.firstname;
                    cocollector.lastname = this.mitsammler.lastname;
                    this.teilnehmer.coCollectorsArray.push(cocollector);
                    this.adressuebernahme = false;
                    this.coCollectorRequestSucceeded = true;
                }
                else if (response.addressValidationResult === AddressValidationResult.ADDRESS_NOT_OK) {
                    this.notificationService.error("Die angegebene Adresse ist nicht gültig.");
                }
                this.teilnehmer.firstRequest = false;
            } catch (error) {
                this.notificationService.error(error);
            }
        }
        else {
            this.setNotificationForCheckingEntries();
        }
        this.enableButton=true;
    }

    public async addCoCollector() {
        if (this.teilnehmer.coCollectorsArray.length < 10) {
            this.teilnehmer.coCollectorsArray.push(new CoCollectorViewModel());
        }
    }

    public async removeCoCollector(index: number) {
        if (this.teilnehmer.coCollectorsArray.length === 1) {
            this.teilnehmer.coCollectorsArray[0].kvnr = undefined;
        }
        else {
            this.teilnehmer.coCollectorsArray.splice(index, 1);
        }
    }

    public async addDetailedCoCollector() {
        this.mitsammler.mainCollectorKVNR = this.teilnehmer.kvnr;
        this.mitsammler.addressSupplement = "";
        this.mitsammler.salutation = "";
        this.mitsammler.emailVerification = "";
        this.mitsammler.privacyStatementAcceptet = false;
        this.privacyStatementAcceptet = false;
        this.mitsammler.allowAdvertisingConsent = false;
        this.allowAdvertisingConsent = false;
        this.mitsammler.consent1 = false;
        this.mitsammler.consent2 = false;
        this.mitsammler.email = "";
        this.mitsammler.namePrefix="";
        this.mitsammler.firstRequest = true;
        this.mitsammler.birthdate = undefined;
        this.mitsammler.streetNo = "";
        this.mitsammler.countryCode = undefined;
        this.mitsammler.lastname = "";
        this.mitsammler.onlineAccessRequested = false;
        this.mitsammler.city = "";
        this.mitsammler.zip = "";
        this.mitsammler.taxNumber = "";
        this.mitsammler.street = "";
        this.mitsammler.conditionsForParticipationAcceptet = false;
        this.mitsammler.phone1 = "";
        this.mitsammler.phone2 = "";
        this.mitsammler.title = "";
        this.mitsammler.kvnr = "";
        this.mitsammler.firstname = "";
        this.mitsammler.chequeBookDelivery = undefined;
        this.coCollectorRequestSucceeded = false;
        this.view = "detailedcocollector";
    }



    public async addressTransfer() {
        if (this.adressuebernahme) {
            this.mitsammler.street = this.teilnehmer.street;
            this.mitsammler.streetNo = this.teilnehmer.streetNo;
            this.mitsammler.addressSupplement = this.teilnehmer.addressSupplement;
            this.mitsammler.zip = this.teilnehmer.zip;
            this.mitsammler.city = this.teilnehmer.city;
            this.mitsammler.countryCode = this.teilnehmer.countryCode;
        }
        else {
            this.mitsammler.street = "";
            this.mitsammler.streetNo = "";
            this.mitsammler.addressSupplement = "";
            this.mitsammler.zip = "";
            this.mitsammler.city = "";
            this.mitsammler.countryCode = undefined;
        }
    }

    private setNotificationForCheckingEntries() {
        this.notificationService.error("Bitte überprüfen Sie Ihre Angaben!");
    }

    setActiveTenant() {
        this.activeTenant = this.tenantProvider.getTenant();
        this.tenantInfo = this.activeTenant.ui.programs[0];
        if (this.tenantInfo.coCollectors != undefined) {
            this.coCollectorsTenantType = this.tenantInfo.coCollectors;
        }
        else {
            this.coCollectorsTenantType = "none";
        }
    }

    private setValidationRules() {

        ValidationRules
            .ensure((item: ParticipantViewModel) => item.kvnr)
            .required().withMessage('Bitte geben Sie Ihre Versichertennummer an.')
            .minLength(9).withMessage("Mindestens 9 Zeichen. ")
            .maxLength(10).withMessage("Nicht mehr als 10 Zeichen. ")
            .matches(new RegExp(/^[a-zA-Z]?\d{9}$/)).withMessage("Bitte überprüfen Sie Ihre Eingabe. ")
            .satisfies((val, obj) => this.hasServerError("Versichertennummer") === false).withMessage('Ungültige Versichertennummer.')
            .satisfies((val, obj) => this.hasServerError("Versichertennummer2") === false).withMessage('Versichertennummer ist bereits registriert. ')
            .then().satisfies((val, obj) => this.hasServerError("kvnr") === false).withMessage("Bitte geben Sie Ihre Versichertennummer an. ")

            .ensure((item: ParticipantViewModel) => item.email)
            .required()
            .withMessage("Bitte geben Sie eine gültige E-Mail-Adresse an. ")
            .when((item: ParticipantViewModel) => item.chequeBookDelivery === "ONLINE").withMessage("Bitte geben Sie Ihre E-Mail-Adresse an. ")
            .email().withMessage("Bitte geben Sie eine gültige E-Mail-Adresse ab. ")            
            .maxLength(40).withMessage("Nicht mehr als 40 Zeichen. ")
            .satisfies((val: any, obj: ParticipantViewModel) => obj.email === obj.emailVerification).withMessage("Die E-Mail-Adressen müssen übereinstimmen. ")
            .then().satisfies((val, obj) => this.hasServerError("Email") === false).withMessage('Ungültige E-Mail-Adresse. ')
            .then().satisfies((val, obj) => this.hasServerError("EmailDuplicate") === false).withMessage('Die E-Mail-Adresse ist bereits vergeben. ')

            .ensure((item: ParticipantViewModel) => item.emailVerification)
            .required()
            .when((item: ParticipantViewModel) => item.chequeBookDelivery === "ONLINE").withMessage("Bitte bestätigen Sie Ihre E-Mail-Adresse. ")
            .email().withMessage("Bitte geben Sie eine gültige E-Mail-Adresse an. ")
            .satisfies((val: any, obj: ParticipantViewModel) => obj.email === obj.emailVerification).withMessage("Die E-Mail-Adressen müssen übereinstimmen. ")
            .then().satisfies((val, obj) => this.hasServerError("Email") === false).withMessage('Ungültige E-Mail-Adresse.')
            .then().satisfies((val, obj) => this.hasServerError("EmailDuplicate") === false).withMessage('Die E-Mail-Adresse ist bereits vergeben. ')

            .ensure((item: ParticipantViewModel) => item.phone1)              
            .maxLength(20).withMessage("Nicht mehr als 20 Zeichen.")
            .ensure((item: ParticipantViewModel) => item.phone2)              
            .maxLength(20).withMessage("Nicht mehr als 20 Zeichen.")
            // .required().when((item: ParticipantViewModel) => item.onlineAccessRequested === true).withMessage('Bitte füllen Sie Telefonnummer1 aus wenn der Online-Zugang erwünscht ist.')

            .ensure((item: ParticipantViewModel) => item.mobilePhone2FA)
            .required().when((item: ParticipantViewModel) => item.onlineAccessRequested === true).withMessage('Bitte geben Sie Ihre Handynummer an, sofern Sie einen Online-Zugang wünschen. ')
            .then().matches(new RegExp(/^((0{1,2}|(\+49))[1-9][0-9\-\/\s]*?)$/)).withMessage('Bitte geben Sie eine gültige Handynummer an.')
            .then().minLength(7).withMessage("Mindestens 7 Zeichen inklusive Vorwahl.")

            .ensure((item: ParticipantViewModel) => item.onlineAccessRequested)
            .satisfies((val, obj: ParticipantViewModel) => obj.onlineAccessRequested === true).when((item: ParticipantViewModel) => item.chequeBookDelivery === "ONLINE").withMessage("Wenn Sie das Online-Scheckheft nutzen möchten, beantragen Sie bitte einen Online-Zugang. ")

            .ensure((item: ParticipantViewModel) => item.firstname)
            .required().withMessage('Bitte füllen Sie den Vornamen aus.')       
            .maxLength(30).withMessage("Nicht mehr als 30 Zeichen. ")
            .then().satisfies((val, obj) => this.hasServerError("firstname") === false).withMessage('Bitte den Vornamen überprüfen.')

            .ensure((item: ParticipantViewModel) => item.lastname)
            .required().withMessage('Bitte füllen Sie den Nachnamen aus.')
            .maxLength(30).withMessage("Nicht mehr als 30 Zeichen. ")
            .then().satisfies((val, obj) => this.hasServerError("lastname") === false).withMessage('Bitte den Nachnamen überprüfen.')

            .ensure((item: ParticipantViewModel) => item.birthdate)
            .required().withMessage('Bitte geben Sie Ihr Geburtsdatum an.')
            .then().satisfies((date: Date) => {
                return date < new Date();
            }).withMessage("Das Geburtsdatum liegt in der Zukunft.")

            .ensure((item: ParticipantViewModel) => item.street)
            .required().withMessage('Bitte geben Sie den Straßennamen an.')
            .maxLength(46).withMessage("Nicht mehr als 46 Zeichen. ")
            .then().satisfies((val, obj) => this.hasServerError("street") === false).withMessage("Bitte die Straße überprüfen.")

            .ensure((item: ParticipantViewModel) => item.zip)
            .required().withMessage('Bitte geben Sie die Postleitzahl an. ')
            .maxLength(7).withMessage("Nicht mehr als 7 Zeichen. ")
            .then()
            .satisfies((val, obj) => this.hasServerError("Postleitzahl") === false).withMessage("Postleitzahl")
            .satisfies((val, obj) => this.hasServerError("zip") === false).withMessage("Bitte die Postleitzahl überprüfen. ")

            .ensure((item: ParticipantViewModel) => item.city)
            .required().withMessage('Bitte geben Sie den Ort an.')
            .maxLength(30).withMessage("Nicht mehr als 30 Zeichen. ")
            .then().satisfies((val, obj) => this.hasServerError("city") === false).withMessage("Bitte den Ort überprüfen.")

            .ensure((item: ParticipantViewModel) => item.streetNo)
            .required().withMessage('Bitte geben Sie die Hausnummer an.')
            .maxLength(8).withMessage("Nicht mehr als 8 Zeichen. ")

            .ensure((item: ParticipantViewModel) => item.addressSupplement)            
            .maxLength(30).withMessage("Nicht mehr als 30 Zeichen. ")

            .ensure((item: ParticipantViewModel) => item.namePrefix)            
            .maxLength(20).withMessage("Nicht mehr als 20 Zeichen. ")

            .ensure((item: ParticipantViewModel) => item.title)            
            .maxLength(20).withMessage("Nicht mehr als 20 Zeichen. ")

            .ensure((item: ParticipantViewModel) => item.salutation)
            .required().withMessage('Bitte wählen Sie eine Anrede aus.')
            .then().satisfies((val, obj) => this.hasServerError("salutation") === false).withMessage("Bitte die Anrede überprüfen.")

            .ensure((item: ParticipantViewModel) => item.countryCode)
            .required().withMessage('Bitte wählen Sie ein Land aus.')

            .ensure((item: ParticipantViewModel) => item.chequeBookDelivery)
            .required().withMessage('Bitte wählen Sie einen Zustellweg aus.')

            .ensure((item: ParticipantViewModel) => item.taxNumber)         
            .matches(new RegExp(/^[0-9]{11}$/)).withMessage("Bitte geben Sie eine gültige Steuer-Id an. Diese besteht aus 11 Ziffern.") 
            .then().satisfies((val, obj) => this.hasServerError("taxNumber") === false).withMessage("Bitte geben Sie eine gültige Steuer-Id an. Diese besteht aus 11 Ziffern.")


            // .ensure((item: ParticipantViewModel) => item.privacyStatementAcceptet)
            // .satisfies((val: any, obj: ParticipantViewModel) => obj.privacyStatementAcceptet == true).withMessage("Der Datenschutzhinweis muss akzeptiert werden.")

            .ensure((item: ParticipantViewModel) => item.conditionsForParticipationAcceptet)
            .satisfies((val: any, obj: ParticipantViewModel) => obj.conditionsForParticipationAcceptet == true).withMessage("Die Teilnahme-Bedingungen müssen akzeptiert werden.")
            .on(this.teilnehmer);

        ValidationRules
            .ensure('privacyStatementAcceptet')
            .equals(true).withMessage("Der Datenschutzhinweis muss akzeptiert werden.")
            .on(this);

        ValidationRules
            .ensure((item: CoCollectorViewModel) => item.kvnr)
            .required().withMessage('Bitte geben Sie die Versichertennummer des Mitsammlers an. ')
            .minLength(9).withMessage("Mindestens 9 Zeichen. ")
            .maxLength(10).withMessage("Nicht mehr als 10 Zeichen. ")
            .matches(new RegExp(/^[a-zA-z]?\d{9}$/)).withMessage("Bitte überprüfen Sie Ihre Eingabe. ")
            .satisfies((val, obj) => this.hasServerErrorForCoCollector("Versichertennummer") === false).withMessage('Ungültige Versichertennummer. ')
            .satisfies((val, obj) => this.hasServerErrorForCoCollector("Versichertennummer2") === false).withMessage('Versichertennummer ist bereits registriert. ')
            .satisfies((val, obj) => this.hasServerErrorForCoCollector("kvnr") === false).withMessage("Bitte geben Sie Ihre Versichertennummer an. ")

            .ensure((item: CoCollectorViewModel) => item.email)
            .required().when((item: CoCollectorViewModel) => item.chequeBookDelivery === "ONLINE").withMessage("Bitte füllen Sie E-Mail aus wenn der Zustellungsweg 'Online' ist. ")
            .email().withMessage("Bitte geben Sie eine gültige E-Mail-Adresse an. ")                 
            .maxLength(40).withMessage("Nicht mehr als 40 Zeichen. ")
            .satisfies((val: any, obj: CoCollectorViewModel) => obj.email === obj.emailVerification).withMessage("Die E-Mail-Adressen müssen übereinstimmen. ")
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("Email") === false).withMessage('Ungültige E-Mail-Adresse. ')            
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("EmailDuplicate") === false).withMessage('Die E-Mail-Adresse ist bereits vergeben. ')
            .ensure((item: CoCollectorViewModel) => item.emailVerification)
            .required().when((item: CoCollectorViewModel) => item.chequeBookDelivery === "ONLINE").withMessage("Bitte füllen Sie Bestätigungs-E-Mail aus wenn der Zustellungsweg 'Online' ist. ")
            .email().withMessage("Bitte geben Sie eine gültige E-Mail-Adresse ein. ")
            .satisfies((val: any, obj: CoCollectorViewModel) => obj.email === obj.emailVerification).withMessage("Die E-Mail-Adressen müssen übereinstimmen. ")
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("Email") === false).withMessage('Ungültige E-Mail-Adresse. ')
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("EmailDuplicate") === false).withMessage('Die E-Mail-Adresse ist bereits vergeben. ')
            .ensure((item: CoCollectorViewModel) => item.mobilePhone2FA)
            .required().when((item: CoCollectorViewModel) => item.onlineAccessRequested === true).withMessage('Bitte geben Sie Ihre Handynummer an, sofern Sie einen Online-Zugang wünschen. ')
            .then().matches(new RegExp(/^((0{1,2}|(\+49))[1-9][0-9\-\/\s]*?)$/)).withMessage('Bitte geben Sie eine gültige Handynummer an.')
            .then().minLength(7).withMessage("Mindestens 7 Zeichen inklusive Vorwahl.")
            
            .ensure((item: CoCollectorViewModel) => item.firstname)
            .required().withMessage('Bitte füllen Sie den Vornamen aus.')
            .maxLength(30).withMessage("Nicht mehr als 30 Zeichen. ")
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("firstname") === false).withMessage('Bitte den Vornamen überprüfen.')

            .ensure((item: CoCollectorViewModel) => item.lastname)
            .required().withMessage('Bitte füllen Sie den Nachnamen aus.')
            .maxLength(30).withMessage("Nicht mehr als 30 Zeichen. ")
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("lastname") === false).withMessage('Bitte den Nachnamen überprüfen.')

            .ensure((item: CoCollectorViewModel) => item.birthdate)
            .required().withMessage('Bitte geben Sie Ihr Geburtsdatum an.')
            .then().satisfies((date: Date) => {
                return date < new Date();
            }).withMessage("Das Geburtsdatum liegt in der Zukunft.")

            .ensure((item: CoCollectorViewModel) => item.namePrefix)            
            .maxLength(20).withMessage("Nicht mehr als 20 Zeichen. ")

            .ensure((item: CoCollectorViewModel) => item.street)
            .required().withMessage('Bitte geben Sie den Straßennamen an.')
            .maxLength(46).withMessage("Nicht mehr als 46 Zeichen. ")
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("street") === false).withMessage("Bitte die Straße überprüfen.")

            .ensure((item: CoCollectorViewModel) => item.zip)
            .required().withMessage('Bitte geben Sie die Postleitzahl an.')
            .maxLength(7).withMessage("Nicht mehr als 7 Zeichen. ")
            .matches(new RegExp(/^\d{1,}$/))
            .then()
            .satisfies((val, obj) => this.hasServerErrorForCoCollector("Postleitzahl") === false).withMessage("Postleitzahl")
            .satisfies((val, obj) => this.hasServerErrorForCoCollector("zip") === false).withMessage("Bitte die Postleitzahl überprüfen.")

            .ensure((item: CoCollectorViewModel) => item.city)
            .required().withMessage('Bitte geben Sie den Ort an.')
            .maxLength(30).withMessage("Nicht mehr als 30 Zeichen. ")
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("city") === false).withMessage("Bitte den Ort überprüfen.")

            .ensure((item: CoCollectorViewModel) => item.streetNo)
            .required().withMessage('Bitte geben Sie die Hausnummer an.')
            .maxLength(8).withMessage("Nicht mehr als 8 Zeichen. ")

            .ensure((item: CoCollectorViewModel) => item.addressSupplement)            
            .maxLength(30).withMessage("Nicht mehr als 30 Zeichen. ")

            .ensure((item: CoCollectorViewModel) => item.phone1)              
            .maxLength(20).withMessage("Nicht mehr als 20 Zeichen.")
            .ensure((item: CoCollectorViewModel) => item.phone2)              
            .maxLength(20).withMessage("Nicht mehr als 20 Zeichen.")
            
            .ensure((item: CoCollectorViewModel) => item.title)            
            .maxLength(20).withMessage("Nicht mehr als 20 Zeichen. ")

            .ensure((item: CoCollectorViewModel) => item.salutation)
            .required().withMessage('Bitte wählen Sie eine Anrede aus.')
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("salutation") === false).withMessage("Bitte die Anrede überprüfen.")

            .ensure((item: CoCollectorViewModel) => item.onlineAccessRequested)
            .satisfies((val, obj: CoCollectorViewModel) => obj.onlineAccessRequested === true).when((item: CoCollectorViewModel) => item.chequeBookDelivery === "ONLINE").withMessage("Wenn Sie das Online-Scheckheft nutzen möchten, beantragen Sie bitte einen Online-Zugang.")

            .ensure((item: CoCollectorViewModel) => item.countryCode)
            .required().withMessage('Bitte wählen Sie ein Land aus.')
            .ensure((item: CoCollectorViewModel) => item.chequeBookDelivery)
            .required().withMessage('Bitte wählen Sie einen Zustellweg aus.')
            .ensure((item: CoCollectorViewModel) => item.taxNumber)
            .matches(new RegExp(/^[0-9]{11}$/)).withMessage("Bitte geben Sie eine gültige Steuer-Id an. Diese besteht aus 11 Ziffern.")
            .then().satisfies((val, obj) => this.hasServerErrorForCoCollector("taxNumber") === false).withMessage("Bitte geben Sie eine gültige Steuer-Id an. Diese besteht aus 11 Ziffern.")
            // .ensure((item: CoCollectorViewModel) => item.privacyStatementAcceptet)
            // .satisfies((val: any, obj: CoCollectorViewModel) => obj.privacyStatementAcceptet == true).withMessage("Der Datenschutzhinweis muss akzeptiert werden.")
            .ensure((item: CoCollectorViewModel) => item.conditionsForParticipationAcceptet)
            .satisfies((val: any, obj: CoCollectorViewModel) => obj.conditionsForParticipationAcceptet == true).withMessage("Die Teilnahme-Bedingungen müssen akzeptiert werden.")
            .ensure((item: CoCollectorViewModel) => item.consent1)
            .satisfies((val: any, obj: CoCollectorViewModel) => obj.consent1 == true).withMessage("Die Einwilligung des Mitsammlers muss vorliegen.")
            .ensure((item: CoCollectorViewModel) => item.consent1)
            .satisfies((val: any, obj: CoCollectorViewModel) => obj.consent2 == true).withMessage("Die Einwilligung des Mitsammlers muss vorliegen.")
            .on(this.mitsammler);

    }

    private handleParticipantException(exception: RegistrationResult) {
        switch (exception.exception.toLowerCase()) {
            case "methodargumentnotvalidexception":
                exception.errorMessages.forEach(element => {
                    switch (element) {
                        case "registrationFormRequestDTO.lastname":
                            this.teilnehmer.serverNotifications.set("lastname", "")
                            break;
                        case "registrationFormRequestDTO.firstname":
                            this.teilnehmer.serverNotifications.set("lastname", "")
                            break;
                        case "registrationFormRequestDTO.zip":
                            this.teilnehmer.serverNotifications.set("zip", "")
                            break;
                        case "registrationFormRequestDTO.city":
                            this.teilnehmer.serverNotifications.set("city", "")
                            break;
                        case "registrationFormRequestDTO.salutation":
                            this.teilnehmer.serverNotifications.set("salutation", "")
                            break;
                        case "registrationFormRequestDTO.kvnr":
                            this.teilnehmer.serverNotifications.set("kvnr", "")
                            break;
                        case "registrationFormRequestDTO.street":
                            this.teilnehmer.serverNotifications.set("street", "")
                            break;
                        case "registrationFormRequestDTO.taxNumber":
                            this.teilnehmer.serverNotifications.set("taxNumber", "")
                            break;
                    }
                });
                this.notificationService.error("Es sind Fehler aufgetreten.");
                break;
            case "invalidzipexception":
                this.teilnehmer.serverNotifications.set("Postleitzahl", "Ungültige Postleitzahl")
                break;

            case "invalidemailexception":
                this.teilnehmer.serverNotifications.set("Email", "Ungültige Email")
                break;
            case "emailisnotuniqueexception":
                this.teilnehmer.serverNotifications.set("EmailDuplicate", "E-Mail ist nicht eindeutig");
                break;
            case "invalidkvnrexception":
            case "invalidinsurancenumberexception":
                this.teilnehmer.serverNotifications.set("Versichertennummer", "Ungültige Versichertennummer")
                break;
            case "kvnralreadyexistingexception":
                this.teilnehmer.serverNotifications.set("Versichertennummer2", "Ungültige Versichertennummer")
                break;
            case "invalidtaxnumberexception":
                this.teilnehmer.serverNotifications.set("taxNumber", "")
                break;
            case "addressvalidationnotavailable":
                this.notificationService.error("Bitte prüfen Sie die Eingabe Ihrer Adresse.");
                break;
            default:
                this.notificationService.error(exception.errorMessages.join());
                break;
        }

    }

    private handleCoCollectorException(exception: RegistrationResult) {
        switch (exception.exception.toLowerCase()) {
            case "methodargumentnotvalidexception":
                exception.errorMessages.forEach(element => {
                    switch (element) {
                        case "registrationFormRequestDTO.lastname":
                            this.mitsammler.serverNotifications.set("lastname", "")
                            break;
                        case "registrationFormRequestDTO.firstname":
                            this.mitsammler.serverNotifications.set("lastname", "")
                            break;
                        case "registrationFormRequestDTO.zip":
                            this.mitsammler.serverNotifications.set("zip", "")
                            break;
                        case "registrationFormRequestDTO.city":
                            this.mitsammler.serverNotifications.set("city", "")
                            break;
                        case "registrationFormRequestDTO.salutation":
                            this.mitsammler.serverNotifications.set("salutation", "")
                            break;
                        case "registrationFormRequestDTO.kvnr":
                            this.mitsammler.serverNotifications.set("kvnr", "")
                            break;
                        case "registrationFormRequestDTO.street":
                            this.mitsammler.serverNotifications.set("street", "")
                            break;
                        case "registrationFormRequestDTO.taxNumber":
                            this.mitsammler.serverNotifications.set("taxNumber", "")
                            break;
                    }
                });
                this.notificationService.error("Es sind Fehler aufgetreten.");
                break;
            case "invalidzipexception":
                //this.validationController.addError("plZ", this.teilnehmer, "Postleitzahl");
                this.mitsammler.serverNotifications.set("Postleitzahl", "Ungültige Postleitzahl")
                break;

            case "invalidemailexception":
                this.mitsammler.serverNotifications.set("Email", "Ungültige Email")
                break;
            case "emailisnotuniqueexception":
                this.mitsammler.serverNotifications.set("EmailDuplicate", "E-Mail ist nicht eindeutig");
                break;
            case "invalidkvnrexception":
            case "invalidinsurancenumberexception":
                this.mitsammler.serverNotifications.set("Versichertennummer", "Ungültige Versichertennummer")
                break;
            case "kvnralreadyexistingexception":
                this.mitsammler.serverNotifications.set("Versichertennummer2", "Ungültige Versichertennummer")
                break;
            case "invalidtaxnumberexception":
                this.mitsammler.serverNotifications.set("taxNumber", "")
                break;
            case "addressvalidationnotavailable":
                this.notificationService.error("Bitte prüfen Sie die Eingabe der Adresse.");
                break;
            default:
                this.notificationService.error(exception.errorMessages.join());
                break;


        }

    }

    private hasServerError(field: string): boolean {
        if (this.teilnehmer.serverNotifications.has(field)) {
            return true;
        }
        return false;
    }

    private hasServerErrorForCoCollector(field: string): boolean {
        if (this.mitsammler.serverNotifications.has(field)) {
            return true;
        }
        return false;
    }



}
