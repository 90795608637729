import { RewardMapping } from './../../mappings/reward-mapping';
import { RewardViewModel } from './../../view-models/reward-view-model';
import { ExtendedRewardDTO, QueryRewardsDTO } from './../api/aok-rest-api';
import { RewardGroupViewModel } from './../../view-models/reward-group-view-model';
import { autoinject } from 'aurelia-framework';
import { AuthenticationType, RestClient } from '../api/rest-client';
import { RewardGroupDTO } from '../api/aok-rest-api';
import { BackendConfigurationService } from '../configuration/backend-configuration-service';
import { RewardgroupMapping } from '../../mappings/reward-group-mapping';

@autoinject()
export class RewardsService{

    constructor(private rest: RestClient, private backendConfigurationService: BackendConfigurationService){

    }

    public async getRewardGroups(tenant: number, loyaltyProgramID: number): Promise<RewardGroupViewModel[]>{
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result: RewardGroupDTO[] = await client.listGroups(loyaltyProgramID);
        let config = await this.backendConfigurationService.getRewardGroupValues();
        let mappedResult: RewardGroupViewModel[] = [];
        mappedResult = result.map(rewardGroup => RewardgroupMapping.RewardGroupDTOToRewardGroupViewModel(rewardGroup, config));
        return mappedResult;
    }

    public async getRewardForGroup(tenant: number, loyaltyProgramID: number, group: string, onlyValid:boolean): Promise<RewardGroupViewModel[]>{
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        var queryRewards = new QueryRewardsDTO();
        queryRewards.onlyValid = onlyValid;
        let result: ExtendedRewardDTO[] = await client.listByLoyaltyProgramAndGroup(loyaltyProgramID, group, queryRewards );
        let mappedResult: RewardViewModel[] = [];
        mappedResult = result.map(reward => RewardMapping.ExtendedRewardDTOToRewardViewModel(reward));
        let sortedResult = mappedResult.sort((a,b) => a.points - b.points);
        return sortedResult;
    }

    public async getRewardById(id: number): Promise<RewardViewModel>{
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result: ExtendedRewardDTO = await client.getRewardById(id);
        let mappedResult = RewardMapping.ExtendedRewardDTOToRewardViewModel(result);
        return mappedResult;
    }
}