import * as $ from 'jquery';

/*
 * showPopoverAlert
 *
 * Display an alert as popover and change it's appearance and behaviour by the following arguments:
 *
 * title [string] - bold text thats displayed at the top of the alert, right of the icon
 * message [string] - content positioned underneath a title or instead of a title
 * type ["success" | "error" | "warning" | "info"] - determines the alerts visual appearance
 * secToFade [int] - if > 0, the amount of seconds the alert will stay visible until faded out
 * position ["left" | "center" | "right"] - if set, the alert doesnt span the full viewport width and is either aligned left, center or right
 * dismissable [bool] - toggles the visibility of the close icon
 * showIcon [bool] - toggles the visibility of alert-type icon
 */

export function showPopoverAlert(title, message, type, secToFade?: number, position?, dismissable?, showIcon?) {
    let alertClass = "alert-info";
    let iconClass = "fa-info-circle";
    switch (type) {
        case "success":
            alertClass = "alert-success";
            iconClass = "fa-check";
            break;
        case "error":
            alertClass = "alert-error";
            iconClass = "fa-times-circle";
            break;
        case "warning":
            alertClass = "alert-warning";
            iconClass = "fa-exclamation-triangle";
            break;
    }

    if (position) {
        if (position.toLowerCase() === "left")
            alertClass += " alert-left";
        if (position.toLowerCase() === "center")
            alertClass += " alert-center";
        if (position.toLowerCase() === "right")
            alertClass += " alert-right";
    }

    let outerElem = document.createElement("div");
    outerElem.setAttribute("class", "alert " + alertClass + " alert-dismissible fade show");
    outerElem.setAttribute("role", "alert");

    if (showIcon !== false) {
        var iconElem = document.createElement("span");
        iconElem.setAttribute("class", "fa " + iconClass + " mr-2");
    }

    if (title) {
        let titleElem = document.createElement("h5");
        titleElem.setAttribute("class", "alert-heading");
        if (iconElem) {
            titleElem.appendChild(iconElem);
        }
        titleElem.appendChild(document.createTextNode(title));
        outerElem.appendChild(titleElem);
    } else {
        if (iconElem) {
            outerElem.appendChild(iconElem);
        }
    }

    let textElem = document.createElement("span");
    textElem.appendChild(document.createTextNode(message));
    outerElem.appendChild(textElem);

    if (dismissable !== false) {
        let closeButton = document.createElement("button");
        closeButton.setAttribute("type", "button");
        closeButton.setAttribute("class", "close");
        closeButton.setAttribute("data-dismiss", "alert");
        closeButton.setAttribute("aria-label", "Close");
        let closeButtonIcon = document.createElement("span");
        closeButtonIcon.setAttribute("aria-hidden", "true");
        closeButtonIcon.innerHTML = "&times;";
        closeButton.appendChild(closeButtonIcon);
        outerElem.appendChild(closeButton);
    }

    let popoverAlertContainer = $("#popoverAlertContainer");

    let lastAlert = popoverAlertContainer[0].appendChild(outerElem);

    if (secToFade && secToFade > 0) {
        window.setTimeout(function () {
            $(lastAlert).alert("close");
        }, secToFade * 1000);
    }
    popoverAlertContainer[0].style.display = "flex";

}
