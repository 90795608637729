import { autoinject, bindable } from 'aurelia-framework';
import { ProgramCategoryViewModel } from '../../../../../view-models/activity-prototype-view-model';
import { UiNavigationService } from "../../../../../service/ui/ui-navigation-service";
import { AuthService } from "../../../../../service/auth/auth-service";

@autoinject()
export class Sofunktionierts {

    @bindable 
    public groupedData: ProgramCategoryViewModel[];

    constructor(public nav: UiNavigationService, private auth: AuthService){
    }
   
    activate(model) {
        this.groupedData = model;
    }

    async navigateToScheckheft() {
        if (this.auth.IsAuthenticated()) {
            this.nav.navigate('scheckheft');
        }
        else {   
            this.nav.navigate('teilnehmen');
        }
        
    }
    
}