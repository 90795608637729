import { autoinject } from 'aurelia-framework';
import { PageBase } from './contents/Base/page-base';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";

@autoinject()
export class Impressum extends PageBase {

    public tenant: string = '';

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, public nav: UiNavigationService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.tenant = this.tenantProvider.getTenant().key;
    }

    async navigateToContact() {
        await this.nav.navigate('kontakt');
    }

}