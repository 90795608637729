import { ParticipantInterfaceType } from './../view-models/tenant-view-models';
import {LoyaltyProgramDTO, MediaCodeDTO, TenantDTO} from "../service/api/aok-rest-api";
import {
    LoyaltyProgramViewModel, MediaCodeStatus,
    MediaCodeViewModel,
    ProgramType,
    TenantViewModel
} from "../view-models/tenant-view-models";


export class TenantMapping {

    public static LoyaltyProgramDTOtoLoyaltyProgramViewModel(from?: LoyaltyProgramDTO): LoyaltyProgramViewModel {
        if(!from) {
            return null;
        }

        let to = new LoyaltyProgramViewModel();
        to.id = from.id;
        to.name = from.name;
        to.useActivityLevels = from.useActivityLevels;

        to.programType = this.ProgramTypeToEnum(from.programType);
        to.participantInterfaceType = this.ParticipantInterfaceType(from.programType);

        return to;
    }

    public static MediaCodeDTOtoMediaCodeViewModel(from?: MediaCodeDTO): MediaCodeViewModel {
        if(!from) {
            return null;
        }

        let to = new MediaCodeViewModel();
        to.description = from.description;
        to.id = from.id;
        to.name = from.name;
        to.status = MediaCodeStatus[from.status.toString()];

        return to;
    }

    public static TenantDTOtoTenantViewModel(from?: TenantDTO): TenantViewModel {
        if(!from) {
            return null;
        }

        let to = new TenantViewModel();
        to.key = from.key;
        to.name = from.name;
        to.ftamid = from.ftamid;
        to.insuranceId = from.insuranceId;
        to.id = from.id;
        to.tenantId = from.tenantId;

        if(from.mediaCodes)
            to.mediaCodes = from.mediaCodes.map(mc => this.MediaCodeDTOtoMediaCodeViewModel(mc));
        if(from.programs)
            to.programs = from.programs.map(off => this.LoyaltyProgramDTOtoLoyaltyProgramViewModel(off));

        return to;
    }

    public static ProgramTypeToEnum(type?: string): ProgramType {
        switch (type) {
            case "S00":
            case "S01":
                return ProgramType.PREMIUM;
            case "F01":
                return ProgramType.FAMILY_BONUS;
        }
        return null;
    }

    public static ParticipantInterfaceType(type?: string): ParticipantInterfaceType{
        switch (type) {
            case "S00":
                return ParticipantInterfaceType.PHV_PMV;
            case "S01":
            case "F01":
                return ParticipantInterfaceType.HSA_MSA;
        }
        return null;
    }
}