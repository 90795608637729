import { SortDirection } from './../../models/search/sortDirection';
import { SearchParameter } from './../../models/search/searchParameter';
import { bindable, inject, observable, autoinject, bindingMode, TaskQueue, containerless, ViewResources } from 'aurelia-framework';
import { computedFrom } from 'aurelia-binding';
import { FormControlHelper } from '../../services/binding/formControlHelper';

@containerless
@autoinject
export class VoltoGrid {
    @bindable({ changeHandler: 'bind', defaultBindingMode: bindingMode.twoWay }) public searchparameter: SearchParameter = null;
    @bindable({ changeHandler: 'bind' }) public data: { items: any[], count: number } = null;
    @bindable() public keys: string[] = [];
    @bindable() public columns: string[] = [];
    @bindable() public valueconverters: Map<string, any> = new Map<string, any>();
    @bindable() public allowSorting: boolean = true;
    @bindable() public allowPaging: boolean = true;
    @bindable() public allowSelecting: boolean = true;
    @bindable() public selectedItem: any = null;
    @bindable public onSelect: ({ item: any }) => void = () => null;
    @bindable public onPaging: ({ page: number }) => void = () => null;
    @bindable public onSorting: ({ sortdirection: SortDirection, columnSorting: string }) => void = () => null;
    @bindable public onBind: () => void = () => null;

    constructor(private viewResources : ViewResources, public formControlHelper: FormControlHelper, private taskQueue: TaskQueue) {

    }

    @computedFrom('allowSelecting')
    get allowSelectingInternal(): boolean {
        return this.allowSelecting.toString() == "true";
    }

    @computedFrom('allowSorting')
    get allowSortingInternal(): boolean {
        return this.allowSorting.toString() == "true";
    }

    @computedFrom('allowPaging')
    get allowPagingInternal(): boolean {
        return this.allowPaging.toString() == "true";
    }

    private bind() {
        if (this.data == null || this.searchparameter == null) return;
        if (!this.allowPagingInternal)
            this.searchparameter.pageSize = this.data ? this.data.items.length : 0;

        if (this.keys === null || this.keys.length === 0) {

            for (var key in this.data[0]) {
                this.keys.push(key);
                this.columns.push(this.formControlHelper.prettifyPropertyName(key));
            }
        }
    }

    private getPropertyValue<T>(item: object, key : string) {
        let propertyValue = this.formControlHelper.getPropertyValue(item, key);
        let convertername = this.valueconverters[key];
        if(convertername){
            let converterInstance = this.viewResources.getValueConverter(convertername) as any;
            if ('toView' in converterInstance){
                propertyValue = converterInstance.toView.apply(converterInstance, [propertyValue]);
            }
        }
        return propertyValue;
    }

    private select(p_item: any) {
        this.selectedItem = p_item;
        this.onSelect({ item: p_item });
    }

    private changePage() {
        this.taskQueue.queueMicroTask(()=>{
            this.onPaging({ page: this.searchparameter.currentPageIndex });
            this.onBind();
        });
    }

    private changeSorting(p_datakey: string) {
        if (p_datakey === this.searchparameter.columnSorting && this.searchparameter.sortDirection === SortDirection.Ascending)
            this.searchparameter.sortDirection = SortDirection.Descending;
        else
            this.searchparameter.sortDirection = SortDirection.Ascending;

        this.searchparameter.columnSorting = p_datakey;
        this.onSorting({ sortdirection: this.searchparameter.sortDirection, columnSorting: p_datakey });
        this.onBind();
    }

    public areEqual(p_item1:any, p_item2): boolean {
        if(p_item1 == null || p_item2 == null)
            return false;
        return JSON.stringify(p_item1) == JSON.stringify(p_item2);
    }
}