import { autoinject } from 'aurelia-framework';
import { ConfigProvider } from 'voltospa';
import { ImageSize } from '../api/resource-rest-api';
import { AuthenticationType, RestClient } from '../api/rest-client';

@autoinject()
export class ResourceService {

    constructor(private rest: RestClient, private config: ConfigProvider) {

    }

    public async getResourceById(id: number, size: ImageSize): Promise<Blob> {
        let client = await this.rest.getDefaultRestApi(AuthenticationType.BASIC);
        let result: Blob = await client.getResourceUsingGET(id, size);
        return result;
    }
}