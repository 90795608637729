import { PhvDTO, MembershipAokDTO, CommunityMemberDTO, MembershipAokDTOChequebookDelivery } from './../service/api/aok-rest-api';
import { ProfileInformation, ProfileViewModel, MemebershipState, ChequebookDeliveryMethod } from '../view-models/profile-view-model';

export class ProfileMapping {
    private static MEMBERSHIP_STATUS_PARTICIPANT = "PARTICIPANT";

    public static PhvDTOToProfileViewModel(from?: PhvDTO): ProfileViewModel {

        if (!from) {
            return null;
        }

        let to = new ProfileViewModel();

        to.mainInsurer = new ProfileInformation();
        to.subInsurers = new Array<ProfileInformation>();

        to.mainInsurer.city = from.city;
        to.mainInsurer.firstname = from.firstname;
        to.mainInsurer.kvnr = from.kvnr;
        to.mainInsurer.lastname = from.lastname;
        to.mainInsurer.mainCollector = true;
        to.mainInsurer.membership = this.MembershipAokDTOTOMembershipState(from.membership);
        to.mainInsurer.salutation = from.salutation;
        to.mainInsurer.street = from.street;
        to.mainInsurer.streetNo = from.streetNo;
        to.mainInsurer.title = from.title;
        to.mainInsurer.zip = from.zip;
        to.mainInsurer.addressSupplement = from.addressSupplement;
        to.mainInsurer.countryCode = from.countryCode;
        to.mainInsurer.namePrefix = from.namePrefix;

        from.additionalInsured.forEach(additionalInsurer => {
            let additionalInsurerProfile = new ProfileInformation();
            additionalInsurerProfile.addressSupplement = additionalInsurer.addressSupplement;
            additionalInsurerProfile.city = additionalInsurer.city;
            additionalInsurerProfile.firstname = additionalInsurer.firstname;
            additionalInsurerProfile.kvnr = additionalInsurer.kvnr;
            additionalInsurerProfile.lastname = additionalInsurer.lastname;
            additionalInsurerProfile.salutation = additionalInsurer.salutation;
            additionalInsurerProfile.street = additionalInsurer.street;
            additionalInsurerProfile.streetNo = additionalInsurer.streetNo;
            additionalInsurerProfile.title = additionalInsurer.title;
            additionalInsurerProfile.zip = additionalInsurer.zip;
            additionalInsurerProfile.countryCode = additionalInsurerProfile.countryCode;
            additionalInsurerProfile.namePrefix = additionalInsurer.namePrefix;
            additionalInsurerProfile.mainCollector = false;
            if (additionalInsurer.membership && additionalInsurer.membership.status == this.MEMBERSHIP_STATUS_PARTICIPANT) {
                additionalInsurerProfile.membership = this.MembershipAokDTOTOMembershipState(additionalInsurer.membership);
            }            
            to.subInsurers.push(additionalInsurerProfile);
        });

        return to;
    }

    public static CommunityMemberDTOTOProfileViewModel(from?: CommunityMemberDTO): ProfileViewModel {
        if (!from) {
            return null;
        }

        let to = new ProfileViewModel();

        to.mainInsurer = new ProfileInformation();
        to.subInsurers = new Array<ProfileInformation>();

        to.mainInsurer.city = from.city;
        to.mainInsurer.firstname = from.firstname;
        to.mainInsurer.kvnr = from.kvnr;
        to.mainInsurer.lastname = from.lastname;
        to.mainInsurer.mainCollector = from.mainCollector;
        to.mainInsurer.membership = this.MembershipAokDTOTOMembershipState(from.membership);
        to.mainInsurer.salutation = from.salutation;
        to.mainInsurer.street = from.street;
        to.mainInsurer.streetNo = from.streetNo;
        to.mainInsurer.title = from.title;
        to.mainInsurer.zip = from.zip;
        to.mainInsurer.addressSupplement = from.addressSupplement;
        to.mainInsurer.countryCode = from.countryCode
        to.mainInsurer.namePrefix = from.namePrefix;

        from.additionalInsured.forEach(additionalInsurer => {
            let additionalInsurerProfile = new ProfileInformation();
            additionalInsurerProfile.firstname = additionalInsurer.firstname;
            additionalInsurerProfile.kvnr = additionalInsurer.kvnr;
            additionalInsurerProfile.lastname = additionalInsurer.lastname;
            additionalInsurerProfile.salutation = additionalInsurer.salutation.toString();
            additionalInsurerProfile.namePrefix = additionalInsurer.namePrefix;
            additionalInsurerProfile.title = additionalInsurer.title;
            additionalInsurerProfile.mainCollector = additionalInsurer.mainCollector;
            if (additionalInsurer.membership && additionalInsurer.membership.status == this.MEMBERSHIP_STATUS_PARTICIPANT)
                additionalInsurerProfile.membership = this.MembershipAokDTOTOMembershipState(additionalInsurer.membership);

            to.subInsurers.push(additionalInsurerProfile);
        });

        return to;
    }

    public static MembershipAokDTOTOMembershipState(from?: MembershipAokDTO): MemebershipState {

        if (!from) {
            return null;
        }

        let to = new MemebershipState();
        to.email = from.email;
        to.loyaltyProgramId = from.loyaltyProgramId;
        to.membershipId = from.membershipId;
        to.status = from.status;

        switch (from.chequebookDelivery) {
            case MembershipAokDTOChequebookDelivery.NONE:
                to.chequebookDelivery = ChequebookDeliveryMethod.NONE;
                break;
            case MembershipAokDTOChequebookDelivery.LETTER:
                to.chequebookDelivery = ChequebookDeliveryMethod.LETTER;
                break;
            case MembershipAokDTOChequebookDelivery.ONLINE:
                to.chequebookDelivery = ChequebookDeliveryMethod.ONLINE;
        }

        if (from.endDate) {
            to.endDate = from.endDate.toDate();
        }

        if (from.registrationDate) {
            to.registrationDate = from.registrationDate.toDate();
        }

        if (from.startDate) {
            to.startDate = from.startDate.toDate();
        }

        return to;
    }
}