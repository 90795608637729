import { ChangePasswordViewModel } from './../../view-models/change-password-view-model';
import { StorageKey, StorageService } from './../storage/storage-service';
import { ProfileMapping } from './../../mappings/profile-mapping';
import { ProfileViewModel, ChequebookDeliveryMethod } from './../../view-models/profile-view-model';
import { LoyaltyProgramViewModel, ParticipantInterfaceType } from './../../view-models/tenant-view-models';
import { AuthenticationType, RestClient } from './../api/rest-client';
import { autoinject } from 'aurelia-framework';
import { ErrorDTO, UpdateChequebookDeliveryDTO } from '../api/aok-rest-api';
import { TenantContext } from '../tenant/tenant-context';
import { TenantService } from '../tenant/tenant-service';
import { ChangePasswordMapping } from '../../mappings/password-mapping';

@autoinject()
export class ProfileService {

    constructor(private rest: RestClient, private tenantProvider: TenantContext, private tenantService: TenantService, private storageService: StorageService) {

    }

    public async getUserProfile(updateLocalstorage:boolean): Promise<ProfileViewModel> {       
        let profile = this.storageService.GetObject<ProfileViewModel>(StorageKey.UserProfile);

        if (!updateLocalstorage && profile != null) {
            return profile;
        }

        let client = await this.rest.getRestApi(AuthenticationType.BEARER);
        let result = new ProfileViewModel();
        let tenant = this.tenantProvider.getTenant();
        let programType = this.tenantService.getProgramTypeFromUrl();
        let loyaltyProgram = tenant.programs.filter(p => p.programType == programType)[0];
        switch (loyaltyProgram.participantInterfaceType) {
            case ParticipantInterfaceType.PHV_PMV:
                var responsePHV = await client.getMainInsuredUsingGET(loyaltyProgram.id);
                result = ProfileMapping.PhvDTOToProfileViewModel(responsePHV);
                break;
            case ParticipantInterfaceType.HSA_MSA:
                var responseHSA = await client.getCommunityMemberUsingPOST(loyaltyProgram.id);
                result = ProfileMapping.CommunityMemberDTOTOProfileViewModel(responseHSA);
                break;
        }

        this.storageService.SetObject(StorageKey.UserProfile, result);
        return result;
    }

    public async updateCheckbookDelivery(chequebookDelivery: ChequebookDeliveryMethod, email?: string) {
                    let client = await this.rest.getRestApi(AuthenticationType.BEARER);
            let request = new UpdateChequebookDeliveryDTO();
            request.chequebookDelivery = chequebookDelivery;
            request.email = email;
            let tenant = this.tenantProvider.getTenant();
            let programType = this.tenantService.getProgramTypeFromUrl();
            let loyaltyProgram = tenant.programs.filter(p => p.programType == programType)[0];
            await client.updateCheckbookDeliveryUsingPOST(loyaltyProgram.id, request);
    }

    public async confirmOptinToken(loyaltyProgramId: number, uuid: string) {
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        await client.confirmOptinTokenUsingGET(loyaltyProgramId, uuid);
    }

    public async changePassword(requestData: ChangePasswordViewModel): Promise<boolean> {
        try {
            let client = await this.rest.getRestApi(AuthenticationType.BEARER);
            let requestParamsDto = ChangePasswordMapping.ChangePasswordViewModelToChangePasswordDTO(requestData);
            await client.changePasswordUsingPOST(requestParamsDto);
            return true;
        }
        catch (error) {
            if (error instanceof ErrorDTO) {
                var knownErrorCodes = "INCORRECT_PASSWORD";
              
                if (knownErrorCodes == error.errorCode)
                    return false;
                else
                    throw new Error(error.errorCode);
            }
            throw new Error('Unknown error');
        }
    }
}