import { AddressViewModel } from "./address-view-model";
import { CoCollectorViewModel } from "./co-collector-view-model";

export class ParticipantViewModel extends AddressViewModel {

    public firstRequest: boolean;
    public kvnr: string;
    public phone1: string;
    public phone2: string;
    public email: string;
    public emailVerification: string;
    public taxNumber: string;
    public chequeBookDelivery: string;
    public onlineAccessRequested: boolean;
    public coCollectors: boolean;
    public coCollectorsArray: Array<CoCollectorViewModel> = new Array<CoCollectorViewModel>();
    public conditionsForParticipationAcceptet: boolean;
    public privacyStatementAcceptet: boolean;
    public serverNotifications: Map<string, string> = new Map<string, string>();
    public allowAdvertisingConsent: boolean;
    public mobilePhone2FA: string;

}