import { Router } from 'aurelia-router';
import { Constants } from './../../../constants/constants';
import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { ProgramInfo, TenantViewModel } from '../../../view-models/tenant-view-models';
import { TenantContext } from '../../../service/tenant/tenant-context';
import { UiNavigationService } from '../../../service/ui/ui-navigation-service';

@autoinject()
export class Breadcrumb {
    
    public activeTenant: TenantViewModel;
    tenantInfo: ProgramInfo;
    breadcrumbs: Array<BreadcrumbItem> = new Array<BreadcrumbItem>();
    private subscriptions: Array<any> ;

    constructor(private router: Router, private eventAggregator: EventAggregator,  public nav: UiNavigationService, private tenantProvider: TenantContext) {
        this.setActiveTenant();    
        this.constructBreadCrumbs(this.router.currentInstruction);
        this.handleEvents();
    }

    handleEvents() {
        this.subscriptions = [];

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.routerNavigationCompleteEvent, (event) =>{            
            this.constructBreadCrumbs(event.instruction);           
        }));
        this.subscriptions.push(this.eventAggregator.subscribe(Constants.tenantChangedEvent, async (data) => {
            await this.setActiveTenant();
        }));
    }

    async detached(){
        this.subscriptions.forEach(subscription => {
            subscription.dispose();
        });
    }

    constructBreadCrumbs(navigationInstruction:any){
        if(!navigationInstruction) return;
        this.breadcrumbs = new Array<BreadcrumbItem>(); 
        var parentRoute = navigationInstruction.config.settings.parentRoute;

        if(parentRoute){
            parentRoute[0].name=this.tenantInfo.name;
            parentRoute.forEach(route => {
                if(route && route.routeName != "home"){
                    var item = new BreadcrumbItem();
                    item.displayName = route.name;
                    item.routeName = route.routeName;
                    item.isActive = route.isClickable == false;
                    var params = {};
                    route.routeParams.forEach(param => {
                        params[param] = navigationInstruction.params[param];
                    });
                    item.routeParams = params;
                    this.breadcrumbs.push(item);
                }
            });
        }

        var item = new BreadcrumbItem();
        item.displayName = navigationInstruction.config.navModel.title;
        item.isActive = true;
        this.breadcrumbs.push(item);
    }

    async navigate(breadcrumb: BreadcrumbItem) {
        await this.router.navigateToRoute(breadcrumb.routeName, breadcrumb.routeParams);
    }

    setActiveTenant() {
        this.activeTenant = this.tenantProvider.getTenant();
        if(this.activeTenant){
            this.tenantInfo = this.activeTenant.ui.programs[0];
        }
    }
}

export class BreadcrumbItem{
    public displayName: string;
    public routeName: string;
    public routeParams: any;
    public isActive: boolean = false;
}