import { Constants } from './../../constants/constants';
import { EventAggregator } from 'aurelia-event-aggregator';
import { autoinject } from 'aurelia-framework';
import { TenantViewModel } from "../../view-models/tenant-view-models";
import { StorageKey, StorageService } from "../storage/storage-service";

let instance: TenantContext = null;

/**
 * A Singleton Object which holds current tenant
 */
@autoinject
export class TenantContext {

    constructor(public storage: StorageService, private eventAggregator: EventAggregator) {
        if (!instance) {
            instance = this;
        }

        return instance;
    }

    /**
     * Set new tenant
     */
    public setTenant(theTenant: TenantViewModel, navigateAfterChange: boolean = true) {
        var currentTenant = this.storage.GetObject<TenantViewModel>(StorageKey.Tenant);

        if(currentTenant == null){
            this.storage.Clear();            
        }
        else if(currentTenant.key != theTenant.key){
            this.storage.Clear();
        }

        this.storage.SetObject(StorageKey.Tenant, theTenant);
        this.eventAggregator.publish(Constants.tenantChangedEvent, {navigate: navigateAfterChange});
    }

    /**
     * Get current tenant
     */
    public getTenant(): TenantViewModel {
        return this.storage.GetObject<TenantViewModel>(StorageKey.Tenant);
    }

    /**
     * Remove current tenant
     */
    public removeTenant() {
        this.storage.RemoveItem(StorageKey.Tenant);
        this.storage.Clear();
        this.eventAggregator.publish(Constants.tenantRemovedEvent);
    }

    /**
     * Check if currently a tenant is set
     */
    public isTenantSet(): boolean {
        return this.getTenant() ? true : false;
    }
}