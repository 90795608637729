import { autoinject, containerless } from 'aurelia-framework';
import { MenuItem } from '../../../models/navigation/menuItem';
import { MenuProvider } from '../../../services/navigation/menuProvider';
import { LayoutProvider } from '../../../services/layout/layoutProvider';

@autoinject
@containerless
export class VoltoFooter {

    public menuItems: MenuItem[];

    constructor(public menuProvider: MenuProvider, public layoutProvider: LayoutProvider) {
    }

    async created() {
        this.menuItems = await this.menuProvider.getFooter();
    }

    public isExternalUrl(menuItem: MenuItem){
        return menuItem.path == null && menuItem.url != null;
    }
}