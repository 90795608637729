export class ResourceViewModel{
    filename?: string|undefined;
    alternativeText?: string|undefined;
    content?: Blob|undefined;
}

export class ResourceReferenceViewModel{
    resourceId?: number|undefined;
    filename?: string|undefined;
    alternativeText?: string|undefined;
}