import { AddressViewModel } from "./address-view-model";

export class FeedbackViewModel extends AddressViewModel{
    public message: string;
    public subject: string;
    public kvnr: string;
    public email: string;
    public phone: string;
    public privacyStatementAcceptet: boolean;
    public serverNotifications: Array<string> = new Array<string>();
}