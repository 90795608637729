import { QueryParticipantAnouncementResultDTO } from './../service/api/aok-rest-api';
import { ParticipantNews, ParticipantNewsViewModel } from './../view-models/participant-news-view-model';
import { ParticipantAnnouncementDTO } from "../service/api/aok-rest-api";
import * as moment from 'moment';

export class ParticipantNewsMapping {

    public static QueryParticipantAnouncementResultDtoToParticipantNewsViewModel(from?: QueryParticipantAnouncementResultDTO): ParticipantNewsViewModel {
        if (!from) {
            return null;
        }
        let to = new ParticipantNewsViewModel();
        to.pageNumber = from.pageNumber;
        to.totalNewsCount = from.totalResultQuantity;
        if (from.announcements != null && from.announcements.length > 0) {
            let today = new Date();
            to.news = from.announcements.map((t: ParticipantAnnouncementDTO) => ParticipantNewsMapping.mapParticipantNews(t)).filter(o => o.date < today);
        }
        else {
            to.news = [];
        }
        return to;
    }


    private static ParticipantAnnouncementDtoToParticipantNews(from?: ParticipantAnnouncementDTO): ParticipantNews {

        if (!from) {
            return null;
        }
        let to = new ParticipantNews();
        to.isNew = from.new;
        to.category = from.category;
        to.date = from.date.toDate();
        to.dateFormatted = moment(to.date).format("DD.MM.YYYY");
        to.text = from.text;
        return to;
    }

    private static mapParticipantNews(pa: ParticipantAnnouncementDTO): ParticipantNews {
        return ParticipantNewsMapping.ParticipantAnnouncementDtoToParticipantNews(pa);
    }
}
