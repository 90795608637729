import { RenderInstruction, ValidateResult } from 'aurelia-validation';
import 'element-closest-polyfill';
  
  export class BootstrapErrorRenderer {
    render(instruction: RenderInstruction) {
      for (let { result, elements } of instruction.unrender) {
        for (let element of elements) {
          this.remove(element, result);
        }
      }
  
      for (let { result, elements } of instruction.render) {
        for (let element of elements) {
          this.add(element, result);
        }
      }
    }
  
    add(element: Element, result: ValidateResult) {
      if (result.valid) {
        return;
      }

      const formGroup = element.closest('.aokValidationControl');
      if (!formGroup) {
        return;
      }
  
      // add the has-error class to the enclosing form-control
      const formControl = formGroup.getElementsByClassName('form-control')[0] || formGroup.getElementsByClassName('form-check-input')[0] ;

      if(!formControl){
        return;
      }

      formControl.classList.add('is-invalid');
      
      const errorBlock = formGroup.getElementsByClassName('invalid-feedback')[0];
      // add error-block
      const message = document.createElement('span');
      message.className = 'help-block validation-message';
      message.innerHTML = result.message;
      message.id = `validation-message-${result.id}`;
      errorBlock.appendChild(message);
    }
  
    remove(element: Element, result: ValidateResult) {
      if (result.valid) {
        return;
      }
  
      const formGroup = element.closest('.aokValidationControl');
      if (!formGroup) {
        return;
      }

      const formControl = formGroup.getElementsByClassName('form-control')[0] || formGroup.getElementsByClassName('form-check-input')[0];
      if(!formControl){
        return;
      }
  
      // remove help-block
      const message = formGroup.querySelector(`#validation-message-${result.id}`);
 
      if (message) {
        const errorBlock = formGroup.getElementsByClassName('invalid-feedback')[0];

        errorBlock.removeChild(message);
        formControl.classList.remove('is-invalid');
      }
    }
  } 