import * as $ from 'jquery';
/*
 * initMobileDropdowns
 *
 * Attaches the functionality for the mobile dropdowns.
 *
 * Searches the document for dropdowns with the specified selector (should point on an element containing the select- and label-element) and binds a mousedown event to the select-element.
 * When mousedown is invoked the options are extracted from the <option>-tags, as well as the text from the <label>-tag.
 * Then the @showModalSelection function is called with the modal-dialog-id, label text and options as paremeters.
 */

export function initMobileDropdowns(selector) {
    $(selector).each(function (index, dropdownMobile) {
        let options = [];
        $(this).find("option:not([data-dropdown-default])").each(function (index, option) {
            options.push($(this)[0].textContent.trim());
        });
        $(dropdownMobile).find("select").on("mousedown", function (e) {
            e.preventDefault();
            $(dropdownMobile).find("select").focus();
            let title = $(dropdownMobile).find("label")[0].textContent.trim();
            showModalSelection("#selectionDialog", title, options).then(function (val) {
                $(dropdownMobile).find("select").val(val);
            });
        });
    });
}

/*
 * showModalSelection
 *
 * invokes a selection-dialog and returns a promise with the selected value as resolve parameter
 *
 */
export function showModalSelection(id, title, options) {

    let modal = $(`${id}`);
    let closePromise = new Promise(function (resolve, reject) {
        $(modal).on("hide.bs.modal", function (e) {
            if ($(modal).find("li.selected a")[0])
                resolve($(modal).find("li.selected a")[0].textContent);
        });
    });
    
    $(modal).find(".modal-title")[0].textContent = title;

    let listElem = document.createElement("ul");

    $(options).each((index, item) => {
        let itemElem = document.createElement("li");
        let itemLinkElem = document.createElement("a");
        itemLinkElem.setAttribute("href", "#");
        itemLinkElem.textContent = item;

        $(itemLinkElem).on("click", function (e) {
            e.preventDefault();
            $(listElem).find("li").removeClass("selected");
            $(this).parent().addClass("selected");
            $(modal).modal("hide");
        });

        itemElem.appendChild(itemLinkElem);
        listElem.appendChild(itemElem);
    });
    $(modal).find(".modal-body")[0].innerHTML = "";
    $(modal).find(".modal-body")[0].appendChild(listElem);

    $(modal).modal("show");

    return closePromise;
}
