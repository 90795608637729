import { LocalizationProvider } from '../../services/localization/localizationProvider';
import { FormElement } from './formElement';
import { Subscription } from 'aurelia-event-aggregator';
import { FormControlHelper } from './../../services/binding/formControlHelper';
import { bindable, autoinject, bindingMode, computedFrom, observable } from 'aurelia-framework';

@autoinject
export class VoltoSelect extends FormElement {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: any = null;
    @bindable public options: object[];
    @bindable public optionsLabel: string;
    @bindable public optionsValue: string;
    @bindable public select2: boolean = false;
    @bindable public multiple: boolean = false;
    @bindable public pleaseSelect: boolean = false;
    @bindable public pleaseSelectText: string = 'I18N_VOLTOSPA_PLEASE_SELECT';
    @bindable public pleaseSelectTextFallback: string = 'Please select';
    @observable public selectedValue: any;
    public outerClass: string;
    public cssClass: string;
    private select2Instance: any;
    private subscription: Subscription;

    @computedFrom('select2')
    get select2Internal(): boolean {
        return this.select2.toString() == "true";
    }

    @computedFrom('multiple')
    get multipleInternal(): boolean {
        return this.multiple.toString() == "true";
    }

    @computedFrom('pleaseSelect')
    get pleaseSelectInternal(): boolean {
        return this.pleaseSelect.toString() == "true";
    }

    constructor(element: Element, formControlHelper: FormControlHelper, private localizationProvider: LocalizationProvider) {
        super(element, formControlHelper);
    }

    public attached() {
        this.cssClass = 'form-control'
        this.outerClass = this.select2Internal ? 'advanced-dropdown' : ''
        if (this.select2Internal) {
            var select2options: any = {
                width: '100%'
            };
            if(this.pleaseSelectInternal)
                select2options.placeholder = this.localizationProvider.translate(this.pleaseSelectText, this.pleaseSelectTextFallback)
            if(this.pleaseSelectInternal || this.multipleInternal)
                select2options.allowClear = true;
            this.select2Instance = (<any>$(this.element).find('select')).select2(select2options);
            this.select2Instance.on('change', evt => {
                var newValue;
                if (this.multipleInternal)
                    newValue = this.getMultiValues();
                else
                    newValue = this.getSingleValue();

                if(newValue != null && !this.areEqual(newValue, this.selectedValue))
                    this.selectedValue = newValue;
            });
        }
        this.setValue();
        
        this.formControlHelper.copyAttributes(this.element, 'select');
        if (!this.requiredIndicator) {
            this.requiredIndicator = this.formControlHelper.isRequired(this.propertyInfo);            
        }
    }

    private areEqual(value1, value2){
        if(Array.isArray(value1) && Array.isArray(value2))
            return this.formControlHelper.AreArraysEqual(value1, value2);
        else
            return value1 == value2;
    }

    public detached() {
        if (this.select2Internal)
            this.select2Instance.select2('destroy');

        if (this.subscription != null)
            this.subscription.dispose();
    }

    private setValue() {
        if(this.value == null || this.value == '')
            this.selectedValue = this.multipleInternal ? [] : '';
        else if (!this.multipleInternal) {
            if(!this.optionsValue)
                this.selectedValue = this.value;
            else
                this.selectedValue = this.value[this.optionsValue];
        }
        else {
            let resultArray = [];
            this.value.forEach(element => {
                resultArray.push(element[this.optionsValue]);
            });
            if(!this.areEqual(this.selectedValue, resultArray))
                this.selectedValue = resultArray;
        }
        if (this.select2Instance) {
            (<any>$(this.element).find('select')).val(this.selectedValue).trigger('change');
        }
    }

    public selectedValueChanged() {
        if (this.options) {
            var newValue;
            if (this.multipleInternal) {
                let resultArray = [];
                this.selectedValue.forEach(element => {
                    resultArray.push(this.options.find(o => o[this.optionsValue] == element));
                });
                newValue = resultArray;
            }
            else {
                if(!this.optionsValue)
                    newValue = this.selectedValue;
                else
                    newValue = this.options.find(o => o[this.optionsValue] == this.selectedValue);
            }

            if(!this.areEqual(this.value, newValue))
                    this.value = newValue;
        }
    }

    public valueChanged() {
        this.setValue();
    }

    private getMultiValues() {
        let select = [];
        
        if (this.select2Instance.val() == '' && this.select2Instance.val() == null)
            return select;

        for (let e of this.select2Instance.val()) {
            select.push(e);
        }
        return select;
    }

    private getSingleValue() {
        return this.select2Instance.val();
    }
}