import { AccountStatementResponseDTO, PostingDTO } from "../service/api/aok-rest-api";
import { AccountHistoryViewModel, AccountTransaction } from "../view-models/account-history-view-model";


export class AccountHistoryMapping{

    public static AccountStatementResponseDTOToAccountHistoryViewModel(from?: AccountStatementResponseDTO):AccountHistoryViewModel{

        if(!from) {
            return null;
        }

        let to = new AccountHistoryViewModel();
        to.pageNumber = from.pageNumber;
        to.transactionCount = from.totalResultQuantity;
        to.accountId = from.accountId;

        if(from.postings)
            to.transactions = from.postings.map(p => this.PostingDTOToAccountTransaction(p));
        else
            to.transactions = [];
            
        return to;
    }

    public static PostingDTOToAccountTransaction(from?: PostingDTO):AccountTransaction{
        
        if(!from) {
            return null;
        }

        let to = new AccountTransaction();
        to.description = from.description;
        to.points = from.value;
        to.transactionDate = from.transactionDate.toDate();
        to.transactionType = from.postingType.toUpperCase();
        to.postingDate = from.postingDate.toDate();

        return to;
    }
}