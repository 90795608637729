import * as $ from 'jquery';
// SimpleBar - (custom scrollbar)

import * as SimpleBar from 'simplebar/dist/simplebar';
import 'simplebar/dist/simplebar.css';

export function initCustomScrollbar(selector): SimpleBar {
    return new SimpleBar($(selector)[0], {
        autoHide: true,
        classNames: {
            // defaults
            content: 'simplebar-content',
            scrollContent: 'simplebar-scroll-content',
            scrollbar: 'simplebar-scrollbar',
            track: 'simplebar-track'
        }
    });
}