import { IoC } from './../dependencies/ioc';
import { LogManager, autoinject, FrameworkConfiguration } from 'aurelia-framework';
import { ConsoleAppender } from 'aurelia-logging-console';
import { LogAppenderProvider } from './logAppenderProvider';
import { ConfigProvider } from '../configuration/configProvider';
import { Logger } from './logger';

@autoinject
export class DefaultLogger extends Logger {

    private log = LogManager.getLogger('VoltoSPA');

    constructor(private configProvider: ConfigProvider) {
        super();
    }

    public async init(config: FrameworkConfiguration): Promise<void> {
        switch (this.configProvider.get<string>('logLevel')) {
            case "debug": {
                LogManager.setLevel(LogManager.logLevel.debug);
                break;
            }
            case "info": {
                LogManager.setLevel(LogManager.logLevel.info);
                break;
            }
            case "warn": {
                LogManager.setLevel(LogManager.logLevel.warn);
                break;
            }
            case "error": {
                LogManager.setLevel(LogManager.logLevel.error);
                break;
            }
        }

        if (this.configProvider.get<boolean>('logConsole')) {
            LogManager.addAppender(new ConsoleAppender());
        }
        var logAppenderProvider = IoC.getAll<LogAppenderProvider>(LogAppenderProvider).forEach(element => {
            LogManager.addAppender(element);
        });
    }

    public debug(message: string, ...rest: any[]) {
        this.log.debug(message, rest);
    }
    public info(message: string, ...rest: any[]) {
        this.log.info(message, rest);
    }
    public warn(message: string, ...rest: any[]) {
        this.log.warn(message, rest);
    }
    public error(message: string, ...rest: any[]) {
        this.log.error(message, rest);
    }
}