import { NewsMapping } from './../../mappings/news-mapping';
import { autoinject } from 'aurelia-framework';
import { AuthenticationType, RestClient } from "../api/rest-client";
import { ConfigProvider } from "voltospa";
import { LoyaltyProgramNewsDTO, TenantDTO } from "../api/aok-rest-api";
import { NewsViewModel } from '../../view-models/news-view-model';

@autoinject
export class NewsService{

    constructor(private rest: RestClient, private config: ConfigProvider ) {

    }

    public async getNewsById(id: number): Promise<NewsViewModel>{
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result: LoyaltyProgramNewsDTO = await client.getNewsRecordUsingGET(id);
        let mappedresponse = NewsMapping.LoyaltyProgrammNewsTOtoNewsViewViewModel(result);

        return mappedresponse;
    }

    public async getNewsForStartPage(tenant: number, loyaltyProgramID: number): Promise<NewsViewModel[]>{
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result: LoyaltyProgramNewsDTO[] = await client.getNewsUsingGET(loyaltyProgramID);

        let mappedResult: NewsViewModel[] = [];
        mappedResult = result.map(news => NewsMapping.LoyaltyProgrammNewsTOtoNewsViewViewModel(news));
        let sortedResult = mappedResult.sort((a,b) => b.validFrom.getTime() - a.validFrom.getTime())
        return sortedResult;
    }
}