
import { bindable, autoinject, bindingMode } from 'aurelia-framework';

@autoinject
export class AokTextarea{
  
  @bindable helptext:string;

  @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: any;
  @bindable public label: string;
  @bindable public id:string;
  
}