import { ChequeBookMapping } from './../../mappings/cheque-book-mapping';
import { ScheckheftDetailViewModel } from '../../view-models/scheckheft-detail-view-model';
import { AuthenticationType, RestClient } from './../api/rest-client';
import { autoinject } from 'aurelia-framework';
import { ActivityRedemptionRequestDTO, FileAttachmentDTO, RequestDownloadChequeBookDTO } from '../api/aok-rest-api';
import * as moment from 'moment';

@autoinject
export class ScheckheftService {

    constructor(private rest: RestClient) {

    }

    public async getActivitiesByYear(membershipId: number, year: number): Promise<ScheckheftDetailViewModel> {
        let client = await this.rest.getRestApi(AuthenticationType.BEARER);
        let result = new ScheckheftDetailViewModel();
        let response = await client.getChequeBookAppUsingGET(membershipId, year, true);
        result = ChequeBookMapping.ChequeBookDTOToScheckheftDetailViewModel(response);
        return result;
    }

    public async redeemChequebookActivity(membershipId: number, activityId: number, date: Date, filesList: Array<File>): Promise<boolean> {
        try {
            let redemptionRequest = new ActivityRedemptionRequestDTO();
            redemptionRequest.activityDate =  moment.utc({ year: date.getFullYear(), month: date.getMonth(), day: date.getDate() });
            redemptionRequest.fileAttachments = new Array<FileAttachmentDTO>();


            for await (var file of filesList) {
                let attachment = new FileAttachmentDTO();
                attachment.mimeType = file.type;
                attachment.content = await (await this.readFile(file)).replace(new RegExp(/^data:.*?base64,/), "");
                redemptionRequest.fileAttachments.push(attachment);
            }

            let client = await this.rest.getRestApi(AuthenticationType.BEARER);
            let result = await client.redeemChequeBookActivityUsingPOST(membershipId, activityId, redemptionRequest);
            return result != null;

        } catch (error) {
            return false;
        }
    }

    private async readFile(file: File): Promise<string> {
        let reader = new FileReader();
        return new Promise<string>((resolve, reject) => {
            reader.onerror = error => reject(error);
            reader.onload = () => resolve(reader.result.toString());
            reader.readAsDataURL(file);
        });
    }

    public async getChequebookPdfFromBackend(membershipId: number, activityIds: number[]): Promise<boolean> {
        try {
            let downloadChequebookRequest = new RequestDownloadChequeBookDTO();
            downloadChequebookRequest.activityIds = activityIds;
            let client = await this.rest.getDefaultRestApi(AuthenticationType.BEARER);
            let response = await client.getChequeBookPdfUsingPOST(membershipId, downloadChequebookRequest);
            if (response) {
                this.generateFile(response);
                return true;
            }

            return false;
        } catch (error) {
            return false;
        }
    }

    private async generateFile(content: Blob) {
        var fileName = "Scheckheft.pdf";
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(content, fileName);
        }
        else {

            const link = document.createElement("a");
            var data = window.URL.createObjectURL(content);
            link.href = data;
            link.download = fileName;
            link.click();
            setTimeout(function () {
                // For Firefox it is necessary to delay revoking the ObjectURL
                window.URL.revokeObjectURL(data);
            }, 100);
        }
    };


}