import { TenantContext } from "../tenant/tenant-context";
import { autoinject } from 'aurelia-framework';
import { Router } from 'aurelia-router';

/**
 * Navigation service to handle tenant in URL
 */
@autoinject()
export class UiNavigationService {

    constructor(private tenantContext: TenantContext, public router: Router) {
    }

    /**
     * Navigation to specific route with automatical adding tenant as path parameter
     * @param route Route name to navigate to
     * @param params additional application params
     */
    public async navigate(route: string, params?: any): Promise<void> {

        let vm = this;

        if (vm.tenantContext.isTenantSet()) {

            if(!params){
                params = {};
            }
            
            params['tenant'] = vm.tenantContext.getTenant().key;

            await vm.router.navigateToRoute(route, params);
        }
        else{
            if(route == 'home'){
                await vm.router.navigateToRoute('home');
            }
        }
    }

    public openMobileMenu(){
        if(!$('#divMainContent').hasClass('page-wrapper--acvite-mobile-nav')){
            $('#divMainContent').addClass('page-wrapper--acvite-mobile-nav');
            $("body").addClass('no-scroll');
            $('#divMobileBackdrop').addClass('mobile-menu-open');
            $('#divMobileBackdrop').removeClass('d-none');
        }
    }

    public closeMobileMenu(){
        if($('#divMainContent').hasClass('page-wrapper--acvite-mobile-nav')){
            $('#divMainContent').removeClass('page-wrapper--acvite-mobile-nav');
            $("body").removeClass('no-scroll');
            $('#divMobileBackdrop').addClass('d-none');
            $('#divMobileBackdrop').removeClass('mobile-menu-open');
        }
    }
}