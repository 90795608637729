import { autoinject, factory, elementConfig, transient } from 'aurelia-framework';
import { NavigationInstruction, Next } from 'aurelia-router';
import { RouterAuthorizeStepProvider } from "./routerAuthorizeStepProvider";
import { AuthorizationProvider } from "./authorizationProvider";
import { AuthenticationProvider } from "./authenticationProvider";

@autoinject
export class DefaultRouterAuthorizeStepProvider extends RouterAuthorizeStepProvider {

    public constructor(private authorizationProvider: AuthorizationProvider, private authenticationProvider: AuthenticationProvider) {
        super();
    }

    public async run(navigationInstruction: NavigationInstruction, next: Next): Promise<any> {

        let isUserPermited: boolean = true;

        if (this.authenticationRequired(navigationInstruction)) {

            let isAuthenticated: boolean = await this.authenticationProvider.isAuthenticated();
            if (!isAuthenticated) {
                this.authenticationProvider.login(document.location.href);
                return next.cancel();
            }

            let requiredPermissions: string[] = this.getRequiredPermissions(navigationInstruction);
            for (let index = 0; index < requiredPermissions.length; index++) {
                const requiredPermission: string = requiredPermissions[index];
                isUserPermited = await this.authorizationProvider.hasPermission(requiredPermission);
                if (isUserPermited)
                    break;
            }
        }

        if (isUserPermited)
            return next();

        return next.cancel();
    }

    private authenticationRequired(navigationInstruction: NavigationInstruction) {
        let authenticationActive = this.authenticationProvider.authenticationActive();
        let requiredPermissions: string[] = this.getRequiredPermissions(navigationInstruction);
        let permissionsRequired = requiredPermissions != null && requiredPermissions.some(p => p != undefined);

        return authenticationActive && permissionsRequired;
    }

    private getRequiredPermissions(navigationInstruction: NavigationInstruction): string[] {
        let requiredPermissions: string[] = navigationInstruction.getAllInstructions().map(i => i.config.permissions)[0];
        return requiredPermissions;
    }
}