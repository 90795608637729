// Waves - optical effect for buttonclicks

import 'node-waves';

export function initWaves() {
    /*
     * WAVE EFFECT
     * Attach Wave effect to elements and initialze
     */

    (<any>window).Waves.attach('.btn-primary', 'waves-dark'); // Wave effect for btn-primary
    (<any>window).Waves.attach('.btn-secondary ', 'waves-dark'); // Wave effect for btn-secondary
    (<any>window).Waves.attach('.btn-cancel ', 'waves-dark'); // Wave effect for btn-cancel
    
    
    (<any>window).Waves.attach('.menu-toggle', 'waves-light'); // Wave effect for Navbar toggle icon"
    (<any>window).Waves.attach('.nav-link.dropdown-toggle', 'waves-light'); // Wave effect for Navbar nav-items
    (<any>window).Waves.init();
}