import { observable, autoinject } from 'aurelia-framework';
import { LayoutProvider } from './layoutProvider';
import { AuthenticationProvider } from "../security/authenticationProvider";

@autoinject
export class DefaultLayoutProvider extends LayoutProvider {

    public constructor(private authenticationProvider: AuthenticationProvider) {
        super();
    }

    hasContainer: boolean = false;
    hasMenu: boolean = true;
    hasFooter: boolean = true;
    hasLanguageSelect: boolean = false;
    hasLogout: boolean = this.authenticationProvider.authenticationActive();
}