import { LoyaltyProgramActivityPrototypeDTO } from "../service/api/aok-rest-api";
import { ActivityPrototypeViewModel } from "../view-models/activity-prototype-view-model";
import { AccordionViewModel } from "../view-models/accordion-view-model";

export class ActivityPrototypeMapping{

    //private static  categoryMap: Map<string, string>;

    public static LoyaltyProgramActivityPrototypeDTOtoActivityPrototypeViewModel(from?: LoyaltyProgramActivityPrototypeDTO) : ActivityPrototypeViewModel{
        var yeast = require('yeast');

        var starIcon = " <svg xmlns=\"http://www.w3.org/2000/svg\" width=\"16\" height=\"16\" fill=\"currentColor\" class=\"bi bi-star-fill\" viewBox=\"0 0 16 16\" style=\"color:#FF9900\">\n" +
            "               <path d=\"M3.612 15.443c-.386.198-.824-.149-.746-.592l.83-4.73L.173 6.765c-.329-.314-.158-.888.283-.95l4.898-.696L7.538.792c.197-.39.73-.39.927 0l2.184 4.327 4.898.696c.441.062.612.636.282.95l-3.522 3.356.83 4.73c.078.443-.36.79-.746.592L8 13.187l-4.389 2.256z\"/>\n" +
            "            </svg>"
        
        if(!from) {
            return null;
        }
        
        let to = new ActivityPrototypeViewModel();
        to.category = this.getCategory(from.category);
        to.categoryExternal = from.categoryExternal;
        to.formattedText = from.formattedDescription;
        if (from.ignoreForMaxPointCalculation){
            to.title = from.nameExternal + starIcon;
        }else{
            to.title = from.nameExternal;
        }
        to.accordionId = "collapse_" + yeast().replace(".","_"); // + from.question.replace(" ","_"); // "collapse" + String(Math.random() * 100+1 | 0);
        to.validFrom = from.validFrom.toDate();
        to.validTo = from.validTo.toDate();
        to.key = from.key;
        return to;
    }

    private static getCategory(cat: string): string{
        var result:string ="none";
        switch (cat.toLocaleLowerCase()){
            case "adult":
                result = "Erwachsene ab 15 Jahren";
                break;
            case "child" : 
                result = "Kinder bis 14 Jahre";
                break;
            
        } 
        return result;
        
     }
}