export class AddressViewModel{

    public salutation: string | undefined ;
    public title: string | undefined ;
    public firstname: string | undefined ;
    public lastname: string | undefined ;
    public birthdate: Date | undefined ;
    public street: string | undefined ;
    public addressSupplement: string | undefined ;
    public streetNo: string | undefined ;
    public zip: string | undefined ;
    public city: string | undefined ;
    public countryCode: string| undefined;
    public namePrefix: string |undefined;
}