import { autoinject, bindable, containerless, TaskQueue } from 'aurelia-framework';
import { MenuItem } from '../../../models/navigation/menuItem';
import { MenuProvider } from '../../../services/navigation/menuProvider';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { LayoutProvider } from '../../../services/layout/layoutProvider';

@autoinject
@containerless
export class VoltoMenu {
    @bindable
    public menuItems: MenuItem[];

    constructor(public menuProvider: MenuProvider, public layoutProvider: LayoutProvider, public eventAggregator: EventAggregator) {
    }

    async attached() {
        await this.initMenuItems();
        this.eventAggregator.subscribe('voltospa:authentication-logout', ()=> this.initMenuItems());
        this.eventAggregator.subscribe('voltospa:authentication-login', ()=> this.initMenuItems());
    }

    async initMenuItems() {
        this.menuItems = (await this.menuProvider.getAuthorizedMenuItems()).filter(m => !m.hidden);
    }

}