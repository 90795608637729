import { ErrorViewModel } from "./error-view-model";

export class RegistrationResult extends ErrorViewModel{
    addressValidationResult?: AddressValidationResult | undefined;
    participantId?: number | undefined;
  
}

export enum AddressValidationResult {
    ADDRESS_OK = <any>"ADDRESS_OK",
    ADDRESS_CORRECTED = <any>"ADDRESS_CORRECTED",
    ADDRESS_AMBIGUOUS = <any>"ADDRESS_AMBIGUOUS",
    ADDRESS_NOT_OK = <any>"ADDRESS_NOT_OK",
}
