import { AddressViewModel } from "./address-view-model";
export class CoCollectorViewModel extends AddressViewModel {
    public firstRequest: boolean | undefined;
    public kvnr: string | undefined;
    public phone1: string | undefined;
    public phone2: string | undefined;
    public mobilePhone2FA: string | undefined;
    public email: string | undefined;
    public emailVerification: string | undefined;
    public taxNumber: string | undefined;
    public chequeBookDelivery: string | undefined;
    public onlineAccessRequested: boolean | undefined;
    public conditionsForParticipationAcceptet: boolean | undefined;
    public privacyStatementAcceptet: boolean | undefined;
    public consent1: boolean | undefined;
    public consent2: boolean | undefined;
    public mainCollectorKVNR: string | undefined;
    public serverNotifications: Map<string, string> = new Map<string, string>();
    public allowAdvertisingConsent: boolean | undefined;
}