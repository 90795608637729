import { autoinject } from 'aurelia-framework';
import { AuthenticationProvider, AuthorizationProvider } from "voltospa";
import { AuthUtil } from "../../util/AuthUtil";


@autoinject
export class AokAuthorizationProvider extends AuthorizationProvider {

    constructor(private authenticationProvider: AuthenticationProvider) {
        super();
    }

    public async hasPermission(permission: string): Promise<boolean> {
        let user = await this.authenticationProvider.getUser();
        return AuthUtil.hasRoleString(user, permission);
    }
}