import { autoinject, bindable, bindingMode, computedFrom } from 'aurelia-framework';
import { BootstrapErrorRenderer } from './../contents/CustomElements/BootstrapErrorRenderer';
import { ConfigProvider, Logger, NotificationService } from 'voltospa';
import { AuthService } from '../../service/auth/auth-service';
import { StorageKey, StorageService } from '../../service/storage/storage-service';
import { TenantContext } from '../../service/tenant/tenant-context';
import { TenantService } from '../../service/tenant/tenant-service';
import { UILayoutService } from '../../service/ui/ui-layout-service';
import { UiNavigationService } from '../../service/ui/ui-navigation-service';
import { PageBase } from '../contents/Base/page-base';
import { MemebershipState, ProfileInformation, ProfileViewModel } from '../../view-models/profile-view-model';
import { ProfileService } from '../../service/profile/profile-service';
import { ValidationController, ValidationRules, ValidationControllerFactory } from 'aurelia-validation';
import { AddressViewModel } from '../../view-models/address-view-model';
import { SalutationViewModel } from '../../view-models/salutation-view-model';
import { CountryViewModel } from '../../view-models/country-view-model';
import { OrderService } from '../../service/order/order-service';
import { ErrorViewModel } from '../../view-models/error-view-model';
import { Constants } from '../../constants/constants';
import { EventAggregator } from 'aurelia-event-aggregator';
import { OrderItemViewModel } from '../../view-models/order-view-model';
import { OrderItemDTOType } from '../../service/api/aok-rest-api';


@autoinject
export class Bestellen extends PageBase {

    public tenant: string = '';
    public userProfile: ProfileViewModel;
    private validationController: ValidationController;
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public deliveryAddress: AddressViewModel = new AddressViewModel();
    public mainInsurerAddress: string;
    public mainInsurerSaluatation: string;
    public orderSubmitted: boolean = false;
    public orderContainsCoupon: boolean = false;
    public anrede: Array<SalutationViewModel> = new Array<SalutationViewModel>();
    public laender: CountryViewModel[];
    @bindable({ defaultBindingMode: bindingMode.twoWay })
    public alternativeAdresse: boolean = false;
    public orderButtonIsEnabled: boolean= true;
   

    @computedFrom('userProfile.mainInsurer.countryCode')
    get CountryCodeDescription(): string {
        let result: string = "";
        var foundCountry: CountryViewModel[] = this.laender.filter(x => x.countryCode === this.userProfile.mainInsurer.countryCode);
        if (foundCountry.length > 0) {
            return foundCountry[0].description;
        }
        return result;
    }

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, public layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private storageService: StorageService, private auth: AuthService, private profileService: ProfileService,
        private eventAggregator:EventAggregator, private validationControllerFactory: ValidationControllerFactory, 
        private orderService: OrderService, private notificationService: NotificationService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        if (!this.auth.IsAuthenticated()) {
            this.nav.navigate("start");
        }
        this.userProfile = new ProfileViewModel();
        this.userProfile.mainInsurer = new ProfileInformation();
        this.userProfile.mainInsurer.membership = new MemebershipState();

        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapErrorRenderer());

        this.tenant = this.tenantProvider.getTenant().key;

        this.setValidationRules();
        this.initializeControls();
        this.checkShoppingCartForCoupons();
    }

    async attached() {
        if (this.auth.IsAuthenticated()) {
            this.userProfile = await this.profileService.getUserProfile(false);
            let mainInsurer = this.userProfile.mainInsurer;
            if(mainInsurer.street != null){
                this.mainInsurerAddress =mainInsurer.street;
            }
            if(mainInsurer.streetNo != null){
                this.mainInsurerAddress += ' ' + mainInsurer.streetNo;
            }
            if(mainInsurer.zip != null){
                this.mainInsurerAddress += ', ' + mainInsurer.zip;
            }
            if(mainInsurer.city != null){
                this.mainInsurerAddress += ' ' + mainInsurer.city;
            }
            this.mainInsurerSaluatation = this.anrede.filter(anrede => anrede.salutationId == mainInsurer.salutation)[0].description;
            this.deliveryAddress.countryCode = "DE";
        }
    }

    public async submit() {
        this.orderButtonIsEnabled=false;
        // wenn alternative Adresse, dann checken
        if (this.alternativeAdresse) {
            var validationObject = await this.validationController.validate();
            if (!validationObject.valid) {
                this.orderButtonIsEnabled=true;
                return;
            }
        }
        try {
            let response = await this.orderService.submitOrder(this.userProfile.mainInsurer.membership.membershipId, this.alternativeAdresse ? this.deliveryAddress : undefined);
            if (response.success == false) {
                //handle exception
                this.handleFeedbackException(response);
                await this.validationController.validate();
            }
            else {
                await this.eventAggregator.publish(Constants.availablePointsChangedEvent);
                this.orderSubmitted = true;
            }            

        } catch (error) {
            // this.notificationService.error(error);
        }
        this.orderButtonIsEnabled=true;
    }

    private setValidationRules() {

        ValidationRules
            .ensure((item: AddressViewModel) => item.firstname)
            .required().withMessage('Bitte füllen Sie den Vornamen aus.')
            .ensure((item: AddressViewModel) => item.lastname)
            .required().withMessage('Bitte füllen Sie den Nachnamen aus.')
            .ensure((item: AddressViewModel) => item.birthdate)
            .required().withMessage('Bitte geben Sie Ihr Geburtsdatum an.')
            .ensure((item: AddressViewModel) => item.street)
            .required().withMessage('Bitte geben Sie den Straße an.')
            .ensure((item: AddressViewModel) => item.zip)
            .required().withMessage('Bitte geben Sie die Postleitzahl an.')
            .ensure((item: AddressViewModel) => item.city)
            .required().withMessage('Bitte geben Sie den Ort an.')
            .ensure((item: AddressViewModel) => item.streetNo)
            .required().withMessage("Nr fehlt")
            .ensure((item: AddressViewModel) => item.salutation)
            .required().withMessage('Bitte wählen Sie eine Anrede aus.')
            .ensure((item: AddressViewModel) => item.countryCode)
            .required().withMessage('Bitte wählen Sie ein Land aus.')
            .on(this.deliveryAddress);

    }

    private handleFeedbackException(exception: ErrorViewModel) {
        if (exception.exception == undefined) {
            this.notificationService.error("Leider ist ein Fehler beim Senden der Daten aufgetreten. Bitte versuchen Sie es erneut.");
            return;
        }

        switch (exception.exception.toLowerCase()) {
            // case "invalidemailexception":

            //     this.kontakt.serverNotifications.push("Email")
            //     break;

            default:
                this.notificationService.error("Leider ist ein Fehler beim Senden der Daten aufgetreten. Bitte versuchen Sie es erneut.");
                break;
        }

    }

    private async initializeControls() {
        this.anrede = [
            { salutationId: "MALE", description: "Herr" },
            { salutationId: "FEMALE", description: "Frau" },
            { salutationId: "DIVERSE", description: "Divers" }
        ];

        this.laender = [
            { countryCode: "DE", description: "Deutschland", disabled: false },
            { countryCode: "XXX", description: "-------------------", disabled: true },
            { countryCode: "F", description: "Frankreich", disabled: false },
            { countryCode: "NL", description: "Niederlande", disabled: false },
            { countryCode: "A", description: "Österreich", disabled: false },
            { countryCode: "PL", description: "Polen", disabled: false },
            { countryCode: "CH", description: "Schweiz", disabled: false },
            { countryCode: "CZ", description: "Tschechische Republik", disabled: false },
        ];
    }

    private async checkShoppingCartForCoupons(){
        var orders = await this.storageService.GetObject<OrderItemViewModel[]>(StorageKey.ShoppingCart);
        if(orders.some(x => x.type == OrderItemDTOType.COUPON)){
            this.orderContainsCoupon = true;
        }
    }
}