import { ChequebookStatus } from './../enums/chequebookStatus';
import { ActivityViewModel, ScheckheftDetailViewModel } from '../view-models/scheckheft-detail-view-model';
import { ChequeBookDTO, ActivityDTO } from './../service/api/aok-rest-api';

export class ChequeBookMapping {

    public static ChequeBookDTOToScheckheftDetailViewModel(from?: ChequeBookDTO): ScheckheftDetailViewModel {

        if (!from) {
            return null;
        }

        let to = new ScheckheftDetailViewModel();

        to.year = from.year;
        to.activities = from.activities.map(a => this.ActivityDTOToActivityViewModel(a));
        to.categories = to.activities.map(a => a.category).filter((value, index, self) => self.indexOf(value) === index);
        to.categories = to.categories.sort((a, b) => a.localeCompare(b));
        return to;
    }

    public static ActivityDTOToActivityViewModel(from?: ActivityDTO): ActivityViewModel {

        if (!from) {
            return null;
        }

        let to = new ActivityViewModel();
        to.category = from.categoryExtern;
        if (from.chequeDocumentDetails != null) {
            to.description = from.chequeDocumentDetails.description;
            to.proof = from.chequeDocumentDetails.signatureText;
        }
        to.id = from.id;
        to.key = from.key;
        to.name = from.name;
        to.points = from.value;
        if (from.request != null && from.request.length > 0) {
            to.status = from.request[from.request.length-1].status;           
        } 
        else if(from.redemptionDate != null){
            to.status = "Accepted";
        }
        to.displayStatus= this.getActivityDisplayStatus(to.status);

        to.selectedForPrint = false;

        return to;
    }

    private static getActivityDisplayStatus(status: string): string {
        if (status == null)
            return "";
        switch (status.toLowerCase()) {
            case "open":
                return ChequebookStatus.Open;
            case "rejected":
                return ChequebookStatus.Rejected
            case "accepted":
                return ChequebookStatus.Accepted;
            case "locked":
            case "modified":
                return ChequebookStatus.InProgress;
        }
    }
}