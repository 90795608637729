import { bindable, autoinject, bindingMode, computedFrom } from 'aurelia-framework';

@autoinject
export class AokCheckbox {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: boolean;
    @bindable public checkboxLabel: string;
    @bindable public checkboxLabelFallback: string;
    @bindable public checkboxLabelSmall: string;
    @bindable public showLabel: boolean = true;

    @bindable helptext:string;

    
    @bindable public label: string;
    @bindable public id:string;

    
}