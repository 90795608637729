import { bindable, inject, observable, autoinject, bindingMode, TaskQueue } from 'aurelia-framework';
import { LocalizationProvider } from './../../services/localization/localizationProvider'

@autoinject
export class VoltoPager {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public page: number = 0;
    @bindable({ changeHandler: 'changeText' }) public count: number = 0;
    @bindable({ changeHandler: 'changeText' }) public pageSize: number = 10;
    private pages: number = 0;
    @bindable public onPaging: ({ page: number }) => void = () => null;


    private textTemplate: string;
    private text: string;

    constructor(private localizationProvider: LocalizationProvider, private taskQueue: TaskQueue) {
        this.textTemplate = localizationProvider.translate("I18N_VOLTOSPA_PAGER");
    }

    private pageChanged(newValue, oldValue) {
        this.changeText();
        if(oldValue != null)
            this.taskQueue.queueMicroTask(() => { this.onPaging({ page: this.page }); });
    }

    private replaceTemplateVariables(template, args): string {
        return template.replace(/\${(\w+)}/g, (_, v) => args[v]);
    }

    public changeText() {
        this.pages = Math.ceil(this.count / this.pageSize);

        this.text = this.replaceTemplateVariables(this.textTemplate, {
            currentPage: this.page + 1,
            pages: this.pages,
            count: this.count
        });
    }

    previousPage() {
        this.page = this.page - 1;
    }

    nextPage() {
        this.page = this.page + 1;
    }

    lastPage() {
        this.page = this.pages - 1;
    }

    firstPage() {
        this.page = 0;
    }

    public attached() {
        this.changeText();
    }
}