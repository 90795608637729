export class Constants {
    // Cookies
    static readonly aokCookieMandant = "_aok_program_mandant";
    static readonly aokCookieProgram = "_aok_program";

    //Config Keys
    static readonly aokConfigurationProgram = "AokProgram.";
    static readonly aokConfigurationProgramInfo = "AokProgramInfo.";
    static readonly aokRootPath = "rootPath";
    static readonly aokRouterPushState = "pushState";

    //Event Subscriptions
    static readonly routerNavigationCompleteEvent = "router:navigation:complete";
    static readonly tenantChangedEvent = "tenant:changed";
    static readonly tenantRemovedEvent = "tenant:removed";
    static readonly pageChangedEvent = "aok-pager:changed";
    static readonly inputValidatedEvent = "input-validated:changed";
    static readonly warenkorbItemChangedEvent = "warenkorb:item-changed";
    static readonly availablePointsChangedEvent = "availablePoints:item-changed";
    static readonly particpantNewsChangedEvent = "particpantNews:item-changed";
    static readonly voltospaFetchStartEvent = "voltospa:fetchstart";
    static readonly voltospaFetchCompleteEvent = "voltospa:fetchcomplete";
    static readonly routerCompleteEvent = "router:navigation:complete";

}