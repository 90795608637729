import { BindingSignaler } from 'aurelia-templating-resources';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { bindable, containerless, computedFrom } from 'aurelia-framework';
import { MenuItem } from '../../../models/navigation/menuItem';

@containerless
export class VoltoMenuLevel {

    @bindable
    public menuItems: MenuItem[]

    private subscription: Subscription;
    
    constructor(private eventAggregator: EventAggregator, private bindingSignaler: BindingSignaler){
        
    }

    public attached() {
        this.subscription = this.eventAggregator.subscribe('voltospa:navigate', ()=>this.bindingSignaler.signal('refreshActiveMenuItem'));
    }

    public detached(){
        this.subscription.dispose();
    }

    public filterHidden(menuItems: MenuItem[]) {
        if (menuItems == null)
            return null;

        var result = menuItems.filter(m => !m.hidden);
        if (result.length == 0)
            return false;
        return result;
    }

    public childActive(menuItem: MenuItem) {
        return menuItem.children != null && menuItem.children.find(c=>c.active && c.hidden) != null;
    }

    public isExternalUrl(menuItem: MenuItem){
        return menuItem.path == null && menuItem.url != null;
    }
}