export class ProfileViewModel{
    mainInsurer?: ProfileInformation | undefined;
    subInsurers?: ProfileInformation[] | undefined;
}

export class ProfileInformation{
    kvnr?: string | undefined;
    salutation?: string | undefined;
    title?: string | undefined;
    firstname?: string | undefined;
    lastname?: string | undefined;
    street?: string | undefined;
    streetNo?: string | undefined;
    addressSupplement?: string | undefined;
    city?: string | undefined;
    zip?: string | undefined;
    countryCode?: string | undefined;
    mainCollector?: boolean | undefined;
    membership? : MemebershipState | undefined;
    namePrefix?: string | undefined;
}

export class MemebershipState{
    email?: string | undefined;
    endDate?: Date | undefined;
    loyaltyProgramId?: number | undefined;
    membershipId?: number | undefined;
    registrationDate?: Date | undefined;
    startDate?: Date | undefined;
    status?: string | undefined;
    chequebookDelivery?: ChequebookDeliveryMethod | undefined;
}

export enum ChequebookDeliveryMethod{
    LETTER = "LETTER",
    ONLINE = "ONLINE",
    NONE = "NONE"
}