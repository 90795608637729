export class MenuItem {
    public text: string;
    public url: string;
    public path: string;
    public icon?: string
    public children : MenuItem[];
    public parent : MenuItem;
    public hidden : boolean;
    public active : boolean = false;
    public external : boolean = false;
    public permissions : string[];
}