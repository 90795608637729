import { MenuItem } from '../../../models/navigation/menuItem';
import { MenuProvider } from '../../../services/navigation/menuProvider';
import { autoinject, bindable } from 'aurelia-framework';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';

@autoinject
export class VoltoBreadcrumb {
    @bindable
    public menuItems: MenuItem[];

    private navigationSubscription: Subscription

    public hasLink(menuItem: MenuItem) : boolean{
        return menuItem.url != null && !menuItem.active; 
    }

    constructor(private menuProvider: MenuProvider, private eventAggregator: EventAggregator){
        this.navigationSubscription = this.eventAggregator.subscribe('voltospa:navigate', async () => {
            this.menuItems = await this.menuProvider.getBreadcrumb();
        });
    }

    async attached() : Promise<void>{
        this.menuItems = await this.menuProvider.getBreadcrumb();
    }

    detached() : void{
        this.navigationSubscription.dispose();
    }
}