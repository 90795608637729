import { FormElement } from './formElement';
import { FormControlHelper } from './../../services/binding/formControlHelper';
import { bindable, autoinject, bindingMode } from 'aurelia-framework';

@autoinject
export class VoltoDatepicker extends FormElement {

    @bindable({ defaultBindingMode: bindingMode.twoWay, changeHandler: 'triggerChange' }) public value: Date;
    @bindable public format: string = 'dd.mm.yyyy';
    private datePicker;
    
    constructor(element: Element, formControlHelper: FormControlHelper) {
        super(element, formControlHelper);
    }

    public attached() {
        super.attached();
        this.datePicker = $(this.element).find('.input-group.date').datepicker({
            format: this.format,
            orientation: "auto",
            todayHighlight: true,
            autoclose: true
        });

        this.datePicker.datepicker("setDate", this.value);

        this.datePicker.on("changeDate", (e) => {       
            if (this.value == null || this.value.getTime() != e.date.getTime()) {
                if(e.date == undefined)
                    this.value = null;
                else
                    this.value = e.date;
            }
        });

        this.datePicker.on("clearDate", (e) => {
            this.value = null;
        });
    }

    public detached() {
        this.datePicker.datepicker('destroy');
    }

    public triggerChange() {  
        var currentDate = this.datePicker.datepicker("getDate");
        if (this.value == null || currentDate.getTime() != this.value.getTime()) {
            this.datePicker.datepicker("setDate", this.value);
        }
    }
}