import { Verbs } from './../models/general/verbs';
import { NotificationService } from './../services/notifications/notificationService';
import { SearchParameter } from './../models/search/searchParameter';
import { IoC } from './../services/dependencies/ioc';
import { inject, autoinject, NewInstance } from 'aurelia-framework';
import { ConfigProvider } from '../services/configuration/configProvider';
import { HttpClient, json } from 'aurelia-fetch-client';
import { AuthenticationProvider } from '../services/security/authenticationProvider';

@inject(NewInstance.of(HttpClient), ConfigProvider, NotificationService)
export class GenericRepository<TClass, TIdentity> {

    constructor(protected configKey: string, protected type: new () => TClass, private idProperty: string, protected http?: HttpClient, protected configProvider?: ConfigProvider, private notifier?: NotificationService) {
        http.configure(config => config.withBaseUrl(this.getServiceUrl())
            .withDefaults({
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                }
            })
            .withInterceptor({
                request: async (request) => {
                    var auth = IoC.get<AuthenticationProvider>(AuthenticationProvider);
                    if(auth.authenticationActive()){
                        var isAuthenticated = await auth.isAuthenticated();
                        if(isAuthenticated){
                            let user = await auth.getUser();
                            request.headers.append('Authorization', 'Bearer ' + user.accessToken);
                        }
                    }
                    return request;
                },
                response: (response) => {
                    if (response.status > 299 || response.status < 200) {
                        throw new Error(`Http Request to ${response.url} failed with status "${response.status} ${response.statusText}".`);
                    }
                    return response;
                }
            }));
    }

    public getServiceUrl(): string {
        var url = this.configProvider.get<string>(this.configKey);
        if (url == null)
            throw new Error('ServiceUrl could not be found in configuration');
        if (url.indexOf('~') != -1) {
            var baseUrl = this.configProvider.get<string>('serviceUrls.baseUrl');
            url = url.replace('~', baseUrl);
        }
        return url;
    }

    public async getList(): Promise<TClass[]> {
        var result = await this.http.fetch('/');
        var entries = <TClass[]>await result.json();
        this.setPrototypes(entries, this.type);
        return entries;
    }

    public async search(searchParameter: SearchParameter): Promise<{ items: TClass[], count: number }> {
        var response = await this.http.fetch('/?' + this.toQueryString(searchParameter));
        var entries = <TClass[]>await response.json();
        this.setPrototypes(entries, this.type);
        var totalCount = response.headers.get('X-Total-Count');
        return { items: entries, count: parseInt(totalCount) };
    }

    public async get(id: TIdentity): Promise<TClass> {
        var result = await this.http.fetch('/' + id.toString());
        var entry = <TClass>await result.json();
        this.setPrototype(entry, this.type);
        return entry;
    }

    public async insert(entry: TClass): Promise<Response> {
        var data = json(entry);
        return await this.http.fetch('/', { method: Verbs.POST.toString(), body: data });
    }

    public async update(entry: TClass): Promise<Response> {
        var data = json(entry);
        return await this.http.fetch('/' + entry[this.idProperty], { method: Verbs.PUT.toString(), body: json(entry) });
    }

    public async delete(entry: TClass): Promise<Response> {
        return await this.http.fetch('/' + entry[this.idProperty], { method: Verbs.DELETE.toString() });
    }

    public async save(entry: TClass): Promise<Response> {
        if (entry[this.idProperty] == null)
            return this.insert(entry);
        else
            return this.update(entry);
    }

    public setPrototype(object: any, destinationType: new () => TClass) {
        Object.setPrototypeOf(object, destinationType.prototype)
    }

    public setPrototypes(objects: any[], destinationType: new () => TClass) {
        objects.forEach(object => {
            this.setPrototype(object, destinationType);
        });
    }

    protected toQueryString(searchParameter: SearchParameter) {
        var array = [];
        Object.keys(searchParameter).forEach(key => {
            if(searchParameter[key] != null) {
                var value = this.formatQueryStringValue(searchParameter[key]);
                array.push(encodeURIComponent(key) + "=" + encodeURIComponent(value));
            }
        });
        return array.join("&");
    }

    protected formatQueryStringValue(value: any): string {
        if(value instanceof Date) {
            return value.toJSON();
        }
        return value;
    }
}