import { autoinject } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { MenuItem } from '../../models/navigation/menuItem';
import { MenuProvider } from './menuProvider';
import { ConfigProvider } from '../configuration/configProvider';
import { Router } from 'aurelia-router';
import { EventAggregator, Subscription } from 'aurelia-event-aggregator';
import { LocalizationProvider } from '../localization/localizationProvider';
import { AuthorizationProvider } from "../security/authorizationProvider";
import { AuthenticationProvider } from "../security/authenticationProvider";

@autoinject
export class ConfigMenuProvider extends MenuProvider {

    constructor(private configProvider: ConfigProvider, public router: Router, public eventAggregator: EventAggregator, public localizationProvider: LocalizationProvider, public authorizationProvider: AuthorizationProvider, public authenticationProvider: AuthenticationProvider) {
        super(router, eventAggregator, localizationProvider, authorizationProvider, authenticationProvider);
    }

    public async getFooter(): Promise<MenuItem[]> {
        return new Promise<MenuItem[]>(resolve => {
            var footer = this.configProvider.get<MenuItem[]>('footer');
            resolve(footer);
        });
    }

    public async getMenu(): Promise<MenuItem[]> {
        return new Promise<MenuItem[]>(resolve => {
            var menu = this.configProvider.get<MenuItem[]>('menu');
            resolve(menu);
        });
    }

    public async getBreadcrumb(): Promise<MenuItem[]> {
        var applicationTitle = this.configProvider.get<string>('title');
        var items = await super.getBreadcrumb();

        var menuItem = new MenuItem();
        menuItem.text = applicationTitle;

        // Root Item should also have homepage navigagation links
        let rootitem = items.find(m => m.url == '' || m.url == '/');
        if(rootitem != null) {
            menuItem.path = rootitem.path;
            menuItem.url = rootitem.url;
        }

        items.unshift(menuItem);
        return items;
    }

    public setActiveItem(): void {
        super.setActiveItem();
        document.title = this.configProvider.get<string>('title') + " - " + document.title;
    }
}