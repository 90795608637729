import { ParticipantNewsService } from '../../../service/participant-news/participant-news-service';
import { StorageKey, StorageService } from './../../../service/storage/storage-service';
import { AccountService } from './../../../service/account/account-service';
import { ProfileService } from './../../../service/profile/profile-service';
import { EventAggregator } from 'aurelia-event-aggregator';
import { TenantContext } from './../../../service/tenant/tenant-context';
import { autoinject, observable } from 'aurelia-framework';
import { UILayoutService } from "../../../service/ui/ui-layout-service";
import { Constants } from "../../../constants/constants";
import { AuthenticationProvider, ConfigProvider, User } from "voltospa";
import { TenantService } from "../../../service/tenant/tenant-service";
import { UiNavigationService } from '../../../service/ui/ui-navigation-service';
import {ProgramInfo, ProgramType, TenantViewModel} from '../../../view-models/tenant-view-models';
import { OrderItemViewModel } from '../../../view-models/order-view-model';
import { ProfileViewModel } from '../../../view-models/profile-view-model';

@autoinject()
export class AokHeader {
    info?: AokProgramInfo;

    @observable tenantInfo: ProgramInfo;
    public activeTenant: TenantViewModel;

    public tenants: Array<TenantViewModel> = [];
    public currentRoute: string = "start";
    public rootPath: string = "/";
    public userProfile = new ProfileViewModel();
    private subscriptions: Array<any>;
    private maxDisplayCharcters: number = 22;
    private availablePoints: number = 0;
    private warenkorbItemCount: number = 0;
    private participantNewsCount: number = 0
    private userDisplayName: string = "";
    private user?: User;

    private readonly program : ProgramType;

    constructor(private layout: UILayoutService, private config: ConfigProvider, private tenantService: TenantService, public nav: UiNavigationService,
        private tenantProvider: TenantContext, private eventAggregator: EventAggregator, private profileService: ProfileService,
        private participantNewsService: ParticipantNewsService, private accountService: AccountService,
        private storageService: StorageService, private authProvider: AuthenticationProvider) {

        this.program = tenantService.getProgramTypeFromUrl();
        this.info = config.get(Constants.aokConfigurationProgramInfo + this.program);
        this.handleEvents();
        this.setActiveTenant();
        this.rootPath = this.config.get<string>(Constants.aokRootPath);
    }

    handleEvents() {
        this.subscriptions = [];

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.tenantChangedEvent, async (data) => {
            this.setActiveTenant();
            this.setTenantInfo();

            if (this.activeTenant && data.navigate) {
                this.nav.navigate('start');
            }
            await this.setHeaderInfomation();
        }));

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.tenantRemovedEvent, () => {
            this.setTenantInfo();
            this.nav.navigate('home');
        }));

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.routerNavigationCompleteEvent, (event) => {
            //this.currentRoute = event.instruction.config.name;
            this.currentRoute = this.nav.router.currentInstruction.config.name;
        }));

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.warenkorbItemChangedEvent, () => {
            this.computeWarenkorbItems();
        }));

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.availablePointsChangedEvent, async (data) => {
            await this.setHeaderInfomation();
        }));

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.routerCompleteEvent, async (data) => {
            await this.setHeaderInfomation();
        }));


        this.subscriptions.push(this.eventAggregator.subscribe(Constants.particpantNewsChangedEvent, async (data) => {
            await this.storageService.RemoveItem(StorageKey.ParticipantNewsCount);
            await this.setHeaderInfomation();
        }));
    }

    async attached() {

        try {
            this.tenants = await this.tenantService.getTenantsByProgramType();
            this.setTenantInfo();

            await this.setHeaderInfomation();
        } catch (error) {}

        this.user = await this.authProvider.getUser();

        if (this.userProfile != null) {
            this.userDisplayName = this.userProfile.mainInsurer.firstname + " " + this.userProfile.mainInsurer.lastname;

            if (this.userDisplayName.length > this.maxDisplayCharcters) {
                this.userDisplayName = this.userDisplayName.substring(0, this.maxDisplayCharcters) + " ...";
            }
        }
    }

    async detached() {
        this.subscriptions.forEach(subscription => {
            subscription.dispose();
        });
    }

    async changeTenant(tenant: TenantViewModel) {
        this.tenantProvider.setTenant(tenant);
        location.reload();
    }

    setTenantInfo() {
        if (this.activeTenant) {
            this.tenantInfo = this.activeTenant.ui.programs[0];
        } else {
            this.tenantInfo = <ProgramInfo>{ name: this.info.name, title: this.info.title };
        }
    }

    setActiveTenant() {
        this.activeTenant = this.tenantProvider.getTenant();
    }

    get activeTenantId(): string {
        if (this.activeTenant) {
            return this.activeTenant.tenantId;
        }
        return "";
    }

    private async setHeaderInfomation() {
        this.availablePoints = 0;
        this.participantNewsCount = 0;

        if (await this.authProvider.isAuthenticated()) {
            this.userProfile = await this.profileService.getUserProfile(false);
            if (this.userProfile.mainInsurer.mainCollector) {
                try {
                    var accountBalance = await this.accountService.getAccountBalance(this.userProfile.mainInsurer.membership.membershipId);
                    this.availablePoints = accountBalance.endBalance;
                } catch (error) {}
            }

            try {
                this.participantNewsCount = await this.participantNewsService.getCountOfNewParticpantNews();
            } catch (error) {}
        }
        await this.computeWarenkorbItems();
    }

    private beendigungsNews(){
        if(this.activeTenant.programs[0].id == 3){
            this.nav.navigate('news', { id: 439609499 });
        }
        if(this.activeTenant.programs[0].id == 15){
            this.nav.navigate('news', { id: 439609631 });
        }
    }

    private async computeWarenkorbItems() {
        this.warenkorbItemCount = 0;

        if (this.authProvider.isAuthenticated()) {
            var items = this.storageService.GetObject<OrderItemViewModel[]>(StorageKey.ShoppingCart);

            if (items) {
                items.forEach(item => {
                    this.warenkorbItemCount = this.warenkorbItemCount + parseInt(item.amount.toString());
                });
            }
        }
    }

    public async login() {
        await this.authProvider.login(window.location.href.indexOf('?') != -1 ? (window.location.href + "&") : (window.location.href + "?"));
    }

    public async logout() {
        await this.authProvider.logout(window.location.href.indexOf('?') != -1 ? (window.location.href + "&") : (window.location.href + "?"));
        await this.nav.navigate("start");
    }
}

export interface AokProgramInfo {
    types?: string[];
    title?: string;
    name?: string;
}