import { bindable } from 'aurelia-framework';
import { getPropertyInfo } from 'aurelia-validation';
import { FormControlHelper } from './../../services/binding/formControlHelper';
import { View } from 'aurelia-templating';
import { Expression } from 'aurelia-binding';

export class FormElement {
    @bindable public label: string;
    @bindable public labelFallback: string;
    @bindable public key: string;
    @bindable public requiredIndicator: boolean;

    public binding: string;
    protected propertyName: string;
    protected propertyInfo: { object, propertyName };
    protected bindingExpression: Expression;


    constructor(protected element: Element, protected formControlHelper: FormControlHelper) {
    }

    public created(owningView: View, myView: View) {
        let { property, of, bindingExpression } = this.formControlHelper.getBindingDetails(this, owningView, "value");
        this.bindingExpression = bindingExpression;
        this.binding = of;
        this.propertyName = property;
    }

    public bind(context, overrideContext) {
        this.propertyInfo = getPropertyInfo(this.bindingExpression, { bindingContext: context, overrideContext: overrideContext });

        if (this.label == null) {
            this.label = this.propertyName;
        }
        if (this.key == null) {
            this.key = this.binding;
        }
    }

    public attached() {
        this.formControlHelper.copyAttributes(this.element);
        if (!this.requiredIndicator) {
            this.requiredIndicator = this.formControlHelper.isRequired(this.propertyInfo);            
        }
    }
}