import { autoinject } from 'aurelia-framework';
import {AuthenticationProvider} from "voltospa";

@autoinject()
export class MitteilungBeendigung {

    constructor( private authProvider: AuthenticationProvider) {
    }

    public async login() {
        console.log("Auth login: " + await this.authProvider.login(window.location.href));
        await this.authProvider.login(window.location.href.indexOf('?') != -1 ? (window.location.href + "&") : (window.location.href + "?"));
    }
}