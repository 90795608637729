import { EventAggregator } from 'aurelia-event-aggregator';
import { ProfileViewModel } from './../view-models/profile-view-model';
import { AccountHistoryViewModel } from './../view-models/account-history-view-model';
import { autoinject } from 'aurelia-framework';
import { PageBase } from './contents/Base/page-base';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { AuthService } from '../service/auth/auth-service';
import { ProfileService } from '../service/profile/profile-service';
import { AccountService } from '../service/account/account-service';
import { ExpiringPointsResponseDTO } from '../service/api/aok-rest-api';
import { Constants } from '../constants/constants';

@autoinject()
export class Kontohistorie extends PageBase {

    public availablePoints: number = 0;
    public expiringPoints: ExpiringPointsResponseDTO = null;
    public options = { year: 'numeric', month: 'numeric', day: 'numeric' };
    public accountStatement: AccountHistoryViewModel = null;

    public pageSize: number = 15;
    public itemCount: number = 0;

    private userProfile: ProfileViewModel = null;
    private subscriptions: any[] = [];
    private totalPages = 0;

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, 
        public nav: UiNavigationService, private auth: AuthService, private profileService: ProfileService, private accountService: AccountService,
        private eventAggregator: EventAggregator) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        if (!this.auth.IsAuthenticated()) {
            this.nav.navigate("start");
        }

        this.subscriptions.push(this.eventAggregator.subscribe(Constants.pageChangedEvent, (data)=>{ this.getAccountStatement(data);}));
    }

    async activate(params){
        if (this.auth.IsAuthenticated()) {
            this.userProfile = await this.profileService.getUserProfile(false);
            var accountBalance = await this.accountService.getAccountBalance(this.userProfile.mainInsurer.membership.membershipId);
            this.availablePoints = accountBalance.endBalance;
            this.expiringPoints = await this.accountService.getExpiringPoints(this.userProfile.mainInsurer.membership.membershipId);
            this.accountStatement = await this.accountService.getAccountStatement(this.userProfile.mainInsurer.membership.membershipId, 0, this.pageSize);
            this.itemCount = this.accountStatement.transactionCount;
            this.totalPages = Math.ceil(this.itemCount / this.pageSize);
        }
    }

    async detached(){
        this.subscriptions.forEach(subscription => {
            subscription.dispose();
        });
    }

    private async getAccountStatement(data: any){
        var size = data.pageSize;

        if(this.totalPages == data.pageIndex){
            size = this.itemCount - (this.totalPages * data.pageSize);
        }

        this.accountStatement = await this.accountService.getAccountStatement(this.userProfile.mainInsurer.membership.membershipId, data.pageIndex, size);
    }
}