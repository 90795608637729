import { View } from 'aurelia-framework';
import {
    Expression,
    ValueConverter,
    Conditional,
    AccessMember,
    AccessKeyed,
    CallMember,
    BindingBehavior,
    Binary
} from 'aurelia-binding';
import { AccessScope } from 'aurelia-binding';
import { Rules } from 'aurelia-validation';

export class FormControlHelper {
    public getBindingDetails(viewModel: any, owningView: View, property: string): { property: string, of: string, bindingExpression: Expression } {
        var bindingExpression = this.getBindingExpressionForProperty(viewModel, owningView, property);
        var result = this.getPropertyInfoForBindingExpression(bindingExpression);
        return {
            property: result.property,
            of: result.of,
            bindingExpression: bindingExpression
        };
    }

    public getPropertyInfoForBindingExpression(binding: Expression): { property: string, of: string } {
        var propertyInfo: { property: string, of: string };
        binding.accept(new ExpressionVisitor((result) => propertyInfo = result));
        return {
            property: this.prettifyPropertyName(propertyInfo.property),
            of: propertyInfo.of
        }
    }

    public prettifyPropertyName(propertyName: string): string {
        let words = propertyName.split(/(?=[A-Z])/).join(' ');
        return words.charAt(0).toUpperCase() + words.slice(1);
    }

    public getPropertyValue<T>(item: object, expression: string){
        if (expression.indexOf('.') === -1) {
            return (item[expression] ? item[expression] : null) as T;
        }
        if (expression.indexOf('.') !== -1) {
            
            let splitKey = expression.split('.');
            let parent = splitKey[0];
            let child = splitKey[1];
            if (item[parent]) {
                return (item[parent][child] ? item[parent][child] : null) as T;
            }
        }
    }

    public getBindingExpressionForProperty(viewModel: any, owningView: View, property: string): Expression {
        return owningView["controllers"].find(c => c.viewModel == viewModel).boundProperties.find(b => b.binding.targetProperty = property).binding.sourceExpression;
    }

    public isRequired(propertyInfo: { object, propertyName }): boolean {
        if (propertyInfo == null)
            return false;
        var rules = Rules.get(propertyInfo.object);
        if (rules == null)
            return false;
        for (var i = 0; i < rules.length; i++) {
            if (rules[i].find(r => r.messageKey == "required" && r.property.name == propertyInfo.propertyName) != null)
                return true;
        }
        return false;
    }

    public copyAttributes(element: Element, innerSelector: string = '.form-control', outerSelector: string = '.form-group'): void {
        var $outer = $(element).find(outerSelector);
        var $inner = $(element).find(innerSelector);

        for (var i = 0; i < element.attributes.length; i++) {
            var attribute = element.attributes[i];
            if (attribute.name == 'inner-class') {
                $inner.addClass(attribute.value);
            }
            else if (attribute.name == 'outer-class') {
                $outer.addClass(attribute.value);
            }
            else if (attribute.name.startsWith('inner-')) {
                $inner.attr(attribute.name.replace('inner-', ''), attribute.value);
            }
            else if (attribute.name.startsWith('outer-')) {
                $outer.attr(attribute.name.replace('outer-', ''), attribute.value);
            }
        }
    }

    public AreArraysEqual(array1, array2) : boolean {
        if (!array2 && array1)
            return false;

        if (!array1 && array2)
            return false;
    
        if (array1.length != array2.length)
            return false;

        for (var i = 0, l=array1.length; i < l; i++) {
            if(array2.find(o=>o == array1[i]) == null)
                return false;          
        }       
        return true;
    }
}

class ExpressionVisitor {

    constructor(private callback: (result: { property: string, of: string }) => void) {
    }

    public visitBindingBehavior(behavior: BindingBehavior) {
        behavior.expression.accept(this);
    }

    public visitValueConverter(converter: ValueConverter) {
        converter.expression.accept(this);
    }

    public visitConditional(conditional: Conditional) {
        conditional.condition.accept(this);
        conditional.yes.accept(this);
        conditional.no.accept(this);
    }

    public visitAccessMember(access: AccessMember) {
        this.callback({
            property: access.name,
            of: access.toString()
        });
    }

    public visitAccessScope(access: AccessScope) {
        this.callback({
            property: access.name,
            of: access.toString()
        });
    }

    public visitAccessKeyed(access: AccessKeyed) {
        access.object.accept(this);
        access.key.accept(this);
    }

    public visitCallMember(call: CallMember) {
        this.visitArgs(call.args);
    }

    public visitBinary(binary: Binary) {
        binary.left.accept(this);
        binary.right.accept(this);
    }

    private visitArgs(args: Expression[]) {
        for (let i = 0; i < args.length; i++) {
            args[i].accept(this);
        }
    }
}
