import { autoinject, bindable, bindingMode } from 'aurelia-framework';
import { ProgramCategoryViewModel, ProgramParticipantModel } from '../../../view-models/activity-prototype-view-model';

@autoinject()
export class ProgrammDetails {

    @bindable
    data: ProgramParticipantModel[];

    
}