import { ProfileService } from './../../service/profile/profile-service';
import { autoinject } from 'aurelia-framework';
import { ConfigProvider, Logger } from "voltospa";
import { TenantContext } from "../../service/tenant/tenant-context";
import { TenantService } from "../../service/tenant/tenant-service";
import { UILayoutService } from "../../service/ui/ui-layout-service";
import { UiNavigationService } from "../../service/ui/ui-navigation-service";
import { PageBase } from "../contents/Base/page-base";
import { ProfileInformation, ProfileViewModel } from '../../view-models/profile-view-model';
import { AuthService } from '../../service/auth/auth-service';

@autoinject()
export class Index extends PageBase {

    public tenant: string = '';
    public userProfile: ProfileViewModel = new ProfileViewModel();

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private profileService: ProfileService, private auth: AuthService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        if (!this.auth.IsAuthenticated()) {
            this.nav.navigate("start");
        } else {
            this.tenant = this.tenantProvider.getTenant().key;
        }
    }

    async attached() {
        if (this.auth.IsAuthenticated()) {
            this.userProfile = await this.profileService.getUserProfile(true);
            if (this.userProfile.mainInsurer.mainCollector)
                this.userProfile.subInsurers = this.userProfile.subInsurers.filter(si => si.membership);
            else
                this.userProfile.subInsurers = [];
        }
    }
    
    goToDetails(profile: ProfileInformation) {
        if (profile.membership) {
            this.nav.navigate('scheckheft-details', { membershipId: profile.membership.membershipId, name: profile.firstname + " " + profile.lastname });
        }
        else {
            this.nav.navigate('scheckheft-details', { membershipId: this.userProfile.mainInsurer.membership.membershipId, name: profile.firstname + " " + profile.lastname });
        }
    }

    goToEinreichen(profile: ProfileInformation) {
        if (profile.membership) {
            this.nav.navigate('scheckheft-einreichen', { membershipId: profile.membership.membershipId });
        }
        else {
            this.nav.navigate('scheckheft-einreichen', { membershipId: this.userProfile.mainInsurer.membership.membershipId });
        }
    }
}