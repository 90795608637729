import {autoinject} from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { ConfigProvider } from 'voltospa';
import { Constants } from '../../constants/constants';

@autoinject()
export class EnvironmentConfigurationService {

    private config?: EnvironmentConfiguration = undefined;

    constructor(private http: HttpClient, private configProvider: ConfigProvider) {
    }

    public async init(): Promise<void> {
        var root = this.configProvider.get<string>(Constants.aokRootPath);
        var response = await this.http.fetch(root +"config/environment.json");
        if (response.status > 299 || response.status < 200) {
            return null;
        }
        this.config = await response.json();
    }

    public async getConfiguration(): Promise<EnvironmentConfiguration> {
        if(!this.config) {
            await this.init();
        }

        return this.config;
    }
}


export interface EnvironmentConfiguration {
    restbasicauth?: BasicAuthConfig;
    serviceurl?: string;
    backendmocked?: boolean;
    oidc?: any;
    authmocked?: boolean;
    shownextyear?:boolean;
}

export interface BasicAuthConfig {
    user?: string;
    password?: string;
}