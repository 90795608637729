import { AuthenticationProvider } from './authenticationProvider';
import { autoinject } from 'aurelia-framework';
import { AuthorizationProvider } from "./authorizationProvider";

@autoinject
export class DummyAuthorizationProvider extends AuthorizationProvider {

    constructor(private authenticationProvider: AuthenticationProvider){
        super();
    }

    public async hasPermission(permission: string): Promise<boolean> {
        let isAuthenticated: boolean = await this.authenticationProvider.isAuthenticated();
        return isAuthenticated;
    }
}