import { autoinject } from 'aurelia-framework';
import { TenantContext } from "./tenant-context";
import { ProgramType, TenantViewModel } from "../../view-models/tenant-view-models";
import { AuthenticationType, RestClient } from "../api/rest-client";
import { Constants } from '../../constants/constants';
import { TenantMapping } from "../../mappings/tenant-mapping";
import { ConfigProvider } from "voltospa";
import { IAOKRestApi, TenantDTO } from "../api/aok-rest-api";
import { AokProgramInfo } from '../../pages/contents/Layouts/aok-header';
@autoinject()
export class TenantService {

    private allTenants: TenantViewModel[] = [];

    constructor(private tenantContext: TenantContext, private rest: RestClient, private config: ConfigProvider) {

    }

    /**
     * Request all Tenants from Backend System to local allTenants variable
     */
    private async _init(): Promise<void> {
        let client = await this.rest.getRestApi(AuthenticationType.BASIC_NO_X_HEADER);
        let tenants = await client.getAllTenantsUsingGET();
        tenants = tenants.filter(t => t.id != 10);//Bremen rausfiltern
        this.allTenants = tenants.map(dto => this.mapTenant(dto));
        this.allTenants = this.allTenants.sort((a, b) => (a.name > b.name) ? 1 : (a.name === b.name) ? ((a.name > b.name) ? 1 : -1) : -1);
    }

    /**
     * Getting all configured Tenants
     */
    public async getAllTenants(): Promise<TenantViewModel[]> {
        if (this.allTenants.length == 0) {
            await this._init();
        }
        return this.allTenants;
    }

    /**
     * Getting the current Program Type for this url
     */
    public getProgramTypeFromUrl(): ProgramType {
        return this.getProgramTypeFromGivenUrl(window.location.toString());
    }


    public getProgramTypeFromGivenUrl(url: string): ProgramType {
        let host = this.getHost(url);
        let key= host.replace(new RegExp('^(www|pre)\d*\.','g'), '').replace(/\./g, '_');
        var programKey = Constants.aokConfigurationProgram + key;
        let type = ProgramType[this.config.get<string>(programKey)];
        return type ? type : ProgramType.PREMIUM;
    }


    private getHost(url: string): string
    {
        let value = url.replace(/.*?:\/*/, '');
        let hostname = value.match(/[^:/?]*/)[0];
        let portMatch = value.match(/:(\d+)/);
        let port = portMatch ? portMatch[1] : ''
        return port.length > 0 ? hostname + ':' + port : hostname;
    }


    /**
     * Getting Tenant by Program Type
     */
    public async getTenantsByProgramType(): Promise<TenantViewModel[]> {
        
        let client = await this.rest.getRestApi(AuthenticationType.BASIC_NO_X_HEADER);

        let programType = this.getProgramTypeFromUrl().toString();
        let programInfo = this.config.get<AokProgramInfo>("AokProgramInfo." + programType);

        let result: TenantDTO[] = [];
        for (let idx in programInfo.types) {
            let result2: TenantDTO[] = await this.getTenantsByProgramInfoType(client, programInfo.types[idx]);
            result2.forEach(r => result.push(r));
        }
        let vm = this;
        let response = result.map((t: TenantDTO) => vm.mapTenant(t));
        return response.sort((a, b) => a.name.localeCompare(b.name));
    }

    public async getTenantsByProgramTypeFromUrl(url: string): Promise<TenantViewModel[]> {
        let client = await this.rest.getRestApi(AuthenticationType.BASIC_NO_X_HEADER);

        let programType = this.getProgramTypeFromGivenUrl(url).toString();
        let programInfo = this.config.get<AokProgramInfo>("AokProgramInfo." + programType);


        let result: TenantDTO[] = [];
        for (let idx in programInfo.types) {
            let result2: TenantDTO[] = await this.getTenantsByProgramInfoType(client, programInfo.types[idx]);
            result2.forEach(r => result.push(r));
        }
        let vm = this;
        let response = result.map((t: TenantDTO) => vm.mapTenant(t));
        return response.sort((a, b) => a.name.localeCompare(b.name));
    }



    private async getTenantsByProgramInfoType(client: IAOKRestApi, programInfoType: string): Promise<TenantDTO[]> {
        let tenants = await client.getTenantsByTariffUsingGET(programInfoType);
        tenants = tenants.filter(t => t.id != 10);//Bremen herausfiltern
        return tenants;
    }


    private mapTenant(t: TenantDTO): TenantViewModel {
        // Mapping DTO to view
        let view = TenantMapping.TenantDTOtoTenantViewModel(t);
        // Adding configured UI Texts for this tenant
        view.ui = this.config.get("Tenants." + t.tenantId);

        //Key im Backend ist anders definieret.
        //Im Frontend gibt es Abhängigkeiten, z.B. Dateipfade
        t.key = view.ui.uiKey;
        view.key = view.ui.uiKey;

        view.ui.programs = view.ui.programs.filter(p => p.key === this.getProgramTypeFromUrl());
        return view;
    }
}