import { HashTable } from 'voltospa/models/general/hashTable';
import { ChangePasswordViewModel } from './../view-models/change-password-view-model';
import { ProfileService } from './../service/profile/profile-service';
import { ValidationControllerFactory, ValidationController, ValidationRules } from 'aurelia-validation';
import { PageBase } from "./contents/Base/page-base";
import {autoinject, bindable, bindingMode, observable} from 'aurelia-framework';
import { ConfigProvider, Logger, NotificationService } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { BootstrapErrorRenderer } from './contents/CustomElements/BootstrapErrorRenderer';

@autoinject()
export class PasswortAendern extends PageBase {

    public passwordChanged: boolean;
    public errorOccured: boolean;
    private validationController: ValidationController;
    @bindable({ defaultBindingMode: bindingMode.twoWay }) public changePassword: ChangePasswordViewModel = new ChangePasswordViewModel();
    @observable public newPassword: string;
    public newPasswordRepeat: string;
    public passwordRules: PasswordRules = new PasswordRules();
    @observable() public passwordHelpVisible: boolean = false;

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        private notificationService: NotificationService, public nav: UiNavigationService,
        private validationControllerFactory: ValidationControllerFactory, private profileService: ProfileService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapErrorRenderer());
        this.setValidationRules();
    }

    private setValidationRules() {
        ValidationRules.ensure((item: ChangePasswordViewModel) => item.oldPassword)
            .required().withMessage('Bitte geben Sie Ihr altes Passwort an.')
            .then().satisfies((val, obj) => this.hasServerError("oldPassword") === false).withMessage('Das derzeitige Passwort ist falsch. Bitte überprüfen Sie Ihre Eingabe und versuchen Sie es erneut.')

            .ensure((item: ChangePasswordViewModel) => item.newPassword)
            .required().withMessage("Bitte geben Sie ein neues Passwort an.")
            .minLength(11).withMessage("Das Passwort muss mindestens 11 Zeichen lang sein.")
            .then().matches(new RegExp(/[0-9]+/)).withMessage("Das Passwort muss mindestens eine Zahl enthalten.")
            .then().matches(new RegExp(/[a-zA-Z]+/)).withMessage("Das Passwort muss mindestens einen Buchstaben enthalten.")
            .then().matches(new RegExp(/[!?@(){}\[\]\\\/=~$%&#*-+.,_]+/)).withMessage("Das Passwort muss mindestens ein Sonderzeichen enthalten.")
            .on(this.changePassword);

        ValidationRules.ensure("newPasswordRepeat")
            .required().withMessage("Bitte wiederholen Sie Ihr neues Passwort.")
            .then().satisfies((val, obj) => this.changePassword.newPassword == val).withMessage('Das Passwort stimmt nicht mit Ihrem neuen Passwort überein.')
            .on(this);
    }

    private hasServerError(field: string): boolean {
        if (this.changePassword.serverNotifications.has(field)) {
            return true;
        }
        return false;
    }

    async saveNewPassword() {
        var isValid = (await this.validationController.validate()).valid;
        if (isValid == true) {
            try {
                this.changePassword.serverNotifications.clear();
                try {
                    var result = await this.profileService.changePassword(this.changePassword);
                    if (result == true) {
                        this.passwordChanged = true;
                    }
                    else {
                        this.changePassword.serverNotifications.set("oldPassword", "");
                        await this.validationController.validate();
                    }
                }
                catch (error) {
                    this.notificationService.error("Leider ist ein Fehler beim Senden der Daten aufgetreten. Bitte versuchen Sie es erneut.");
                    return;
                }

            } catch (error) {
                this.errorOccured = true;
            }
        }
    }

    newPasswordChanged (): void {
        this.changePassword.newPassword = this.newPassword;
        this.passwordRules.testAllRules(this.changePassword.newPassword)
    }

    showPasswordHelp (): boolean {
        this.passwordHelpVisible = !this.passwordHelpVisible;
        return this.passwordHelpVisible;
    }

    public navigateToStart() {
        this.nav.navigate('start')
    }

    public reInitilizeData() {
        this.passwordChanged = false;
        this.errorOccured = false;
    }


}

export class PasswordRules {
    public atLeast11Char: boolean = false;
    public oneLetter: boolean = false;
    public oneNumber: boolean = false;
    public oneSpecialChar: boolean = false;

    testAllRules(password: string){
        this.testAtLeast11Char(password);
        this.testOneLetter(password);
        this.testOneNumber(password);
        this.testOneSpecialChar(password);
    }
    
    testAtLeast11Char(password: string) {
        this.atLeast11Char = password.length >= 11 ? true : false;
    }

    testOneLetter(password: string) {
        this.oneLetter = password.match(/(?=.*[a-z])(?=.*[A-Z]).*$/g) ? true : false;
    }

    testOneNumber(password: string) {
        this.oneNumber = password.match(/[0-9]/g) ? true : false;
    }

    testOneSpecialChar(password: string) {
        this.oneSpecialChar = password.match(/[!?@(){}\[\]\/\\=~$%&#*\-+.,_]/g) ? true : false;
    }
    
}

