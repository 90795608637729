import { autoinject } from 'aurelia-framework';
import { TenantContext } from "../tenant/tenant-context";
import { TenantService } from "../tenant/tenant-service";
import { AuthenticationType, RestClient } from "../api/rest-client";
import { ConfigProvider } from "voltospa";
import { LoyaltyProgramActivityPrototypeDTO, LoyaltyProgramFAQDTO, TenantDTO } from "../api/aok-rest-api";
import { ActivityPrototypeViewModel, ProgramCategoryViewModel, ProgramParticipantModel } from '../../view-models/activity-prototype-view-model';
import { ActivityPrototypeMapping } from '../../mappings/activity-prototype-mapping';


@autoinject
export class ActivityPprototypeService {



    constructor(private rest: RestClient, private config: ConfigProvider, private tenantContext: TenantContext, private tenantService: TenantService) {

    }

    public async getActivityPrototypeByTenantandProgramType(): Promise<ProgramParticipantModel[]> {
        let today = new Date();
        let tenant = this.tenantContext.getTenant();
        let programType = this.tenantService.getProgramTypeFromUrl();
        let program = tenant.programs.filter(p => p.programType == programType)[0];
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result: LoyaltyProgramActivityPrototypeDTO[] = await client.getActivityPrototypesUsingGET(program.id, undefined);

        let vm = this;
        let mappedresponse = result.map((t: LoyaltyProgramActivityPrototypeDTO) => vm.mapPrototype(t)).filter(c => c.category != "none").filter(o => o.validFrom < today && o.validTo > today);

        let groupedResult: ProgramParticipantModel[] = [];
        var uniqueParticipants = mappedresponse.map(item => item.category).filter((value, index, self) => self.indexOf(value) === index);

        for (let part in uniqueParticipants) { // Adult, Child
            let programParticipant = new ProgramParticipantModel();
            programParticipant.participant = uniqueParticipants[part];
            
            programParticipant.ProgramCategories = [];
            var interimCategories: ProgramCategoryViewModel[]=[];
            var uniqueProgram = mappedresponse.filter(n => n.category === uniqueParticipants[part]).map(item => item.categoryExternal).filter((value, index, self) => self.indexOf(value) === index);
        
            for (let prog in uniqueProgram) { // Vorsorge, Fitness, Regionales
                var programCategory = new ProgramCategoryViewModel();
                programCategory.program = uniqueProgram[prog];
        
                var filteredActivityPrototypes = mappedresponse.filter(n => n.categoryExternal === uniqueProgram[prog]).filter(m => m.category === uniqueParticipants[part]);
                programCategory.accordionItems = filteredActivityPrototypes.filter((obj, index, self) =>  index === self.findIndex((t) => ( t.key === obj.key)));
                //programParticipant.ProgramCategories.push(programCategory);
                interimCategories.push(programCategory);
            }

            programParticipant.ProgramCategories = programParticipant.ProgramCategories.concat(interimCategories.filter(f => f.program==="Vorsorge"));
            programParticipant.ProgramCategories = programParticipant.ProgramCategories.concat(interimCategories.filter(f => f.program==="Fitness"));
            programParticipant.ProgramCategories = programParticipant.ProgramCategories.concat(interimCategories.filter(f => f.program==="Regionales"));

            groupedResult.push(programParticipant);
        }

       
        return groupedResult;
    }


    private mapPrototype(t: LoyaltyProgramActivityPrototypeDTO): ActivityPrototypeViewModel {
        return ActivityPrototypeMapping.LoyaltyProgramActivityPrototypeDTOtoActivityPrototypeViewModel(t);
    }
}