import { Logger } from './../logging/logger';
import { autoinject } from 'aurelia-framework'
import { NotificationMessage } from '../../models/notifications/notificationMessage'
import { NotificationType } from '../../models/notifications/notificationType'
import { LocalizationProvider } from '../localization/localizationProvider'
import { EventAggregator, Subscription } from 'aurelia-event-aggregator'
import { ConfigProvider } from '../configuration/configProvider';

@autoinject
export class NotificationService {

    private keepMessages: boolean = false;

    public error(message: string, fallback?: string): void {
        this.message(message, NotificationType.Error, fallback);
    }

    public fatal(message: string) {
        this.message(message, NotificationType.Fatal);
    }

    public success(message: string, fallback?: string): void {
        this.message(message, NotificationType.Success, fallback);
    }

    public info(message: string, fallback?: string): void {
        this.message(message, NotificationType.Info, fallback);
    }

    public warning(message: string, fallback?: string): void {
        this.message(message, NotificationType.Warning, fallback);
    }

    private message(message: string, type: NotificationType, fallback?: string): void {
        let notificationMessage = new NotificationMessage();
        notificationMessage.message = message;
        notificationMessage.type = type;
        notificationMessage.fallback = fallback;
        this.displayMessages([notificationMessage]);
    }

    public clear(force?: boolean) {
        if (this.keepMessages && !force)
            return;

        //Notifier Meldungen
        $('.Notifier .message').empty();
        $('.Notifier .alert').stop().hide();
        //Inlinefehler
        $('[data-validator-for]').empty();
        $('.form-group').removeClass('has-error');
    }

    private readNotificationsFromHeader(request: JQueryXHR): void {
        var jsonNotifier = request.getResponseHeader('X-Notifications');
        if (jsonNotifier == null)
            return;
        var messages = JSON.parse(jsonNotifier);
        this.displayMessages(messages);
    };

    private readNotificationsFromFetchHeader(response: Response): void {
        if (response == null || response.headers == null)
            return;
        var jsonNotifier = response.headers.get('X-Notifications');
        if (jsonNotifier == null)
            return;
        var messages = JSON.parse(jsonNotifier);
        this.displayMessages(messages);
    };

    public displayMessages(messages: NotificationMessage[]) {
        if (messages.length > 0) {
            this.scrollTop();
            this.clear();
        }
        for (var i = 0; i < messages.length; i++) {
            this.displayMessage(messages[i]);
            this.displayInlineError(messages[i]);
        }
        this.setClearTimeout();
    };

    private displayMessage(message: NotificationMessage): void {
        if (message.type == NotificationType.Fatal) {
            this.logger.error(message.message);
            var detail = message.message;
            message.message = this.localizationProvider.translate("I18N_VOLTOSPA_FATAL");
            message.type = NotificationType.Error;
            if (this.configProvider.get<boolean>("displayErrorDetails"))
                message.message += `&nbsp;<small style="cursor:pointer" onclick="showDebugMessage(this);">[Exception Details (Debug Only)]</small><small style="display:none"><br/>${$('<div/>').text(detail).html().replace('\n', '<br/>')}</small>`;
        }
        else {
            message.message = this.localizationProvider.translate(message.message, message.fallback);
        }
        if (message.type != NotificationType.Error && $('.Notifier:visible .Error').is(':visible'))
            return;

        if (message.type == NotificationType.Error) {
            $('.Notifier :not(.Error)').find('.message').empty();
            $('.Notifier .alert:not(.Error)').hide();
        }

        var notifier = $('.Notifier:visible ' + "." + message.type).removeClass('fade').removeClass('in')
        if (!notifier.is(':visible'))
            notifier.show();
        var messageContainer = notifier.find('.message');
        if (messageContainer.is(':empty')) {
            messageContainer.append(message.message);
        }
        else {
            if (messageContainer.text().indexOf(message.message) != -1)
                return;
            var ul = messageContainer.find('ul');
            if (!ul.length) {
                var existingmessage = messageContainer.text();
                messageContainer.empty();
                ul = $('<ul></ul>').appendTo(messageContainer);
                ul.append('<li>' + existingmessage + '</li>');
            }
            ul.append('<li>' + message.message + '</li>');
        }
    };

    private displayInlineError(message: NotificationMessage): void {
        if (message.key == null)
            return;
        let target = $('[data-validator-for="' + message.key + '"]');
        if (target.text().indexOf(message.inlineMessage) != -1)
            return;
        target.append('<i class="fa fa-warning"></i> ' + message.inlineMessage + "<br/>").closest('.form-group').addClass('has-error');
    };

    private onAjaxRequestCompleted(request: JQueryXHR) {
        this.enableNotificationsForModalDialogs();
        $('.modal').each(() => {
            if (($(this).data('bs.modal') || {}).isShown) {
                this.enable(<any>$(this));
                this.enableCloseButton();
            }
        });
        this.clear();
        this.readNotificationsFromHeader(request);
    };

    private enableCloseButton(): void {
        $('.Notifier .close:not(.isInitialized)').click(function () {
            $(this).closest('div.alert').fadeOut();
        }).addClass('isInitialized');
    };

    public enable(p_parent: JQuery<HTMLElement>): void {
        this.clear();
        $('.Notifier').hide();
        window.setTimeout(()=>p_parent.find('.Notifier').show(),1);
    }

    public disable = function (p_parent: JQuery<HTMLElement>): void {
        this.clear();
        $('.Notifier').show();
        p_parent.find('.Notifier').hide();
    }

    private enableNotificationsForModalDialogs(): void {
        $('.modal').on('show.bs.modal', () => this.enable(<any>$(this)));
        $('.modal').on('hide.bs.modal', () => this.disable(<any>$(this)));
    };

    private clearMessagesOnNavigation() {
        this.eventAggregator.subscribe('router:navigation:success', () => {
            this.clear();
        });
    }

    private initialize(): void {
        $(document).ajaxComplete((event, xhr) => this.onAjaxRequestCompleted(xhr));
        this.eventAggregator.subscribe('voltospa:fetchcomplete', (fetch) => this.readNotificationsFromFetchHeader(fetch));
        this.enableNotificationsForModalDialogs();
        this.enableCloseButton();
        this.clearMessagesOnNavigation();
    };

    private scrollTop(): void {
        $("html, body").animate({ scrollTop: 0 }, 200);
    }

    private setClearTimeout() {
        this.keepMessages = true;
        window.setTimeout(() => this.resetClearTimeout(), 1000);
    }

    private resetClearTimeout() {
        this.keepMessages = false;
    }

    constructor(public eventAggregator: EventAggregator, private localizationProvider: LocalizationProvider, private configProvider: ConfigProvider, private logger: Logger) {
        $(() => this.initialize());

        eventAggregator.subscribe("router:navigation:error", (data: any, eventName: string) => {
            this.fatal(data.result.output.message);
        });

        window.onerror = (message, file, line, col, error) => {
            if (error && error.message != message)
                message += '\n' + error.message;
            message += '\n' + 'See browser console (F12) for details.';
            this.fatal(<any>message);
        };

        window.addEventListener("unhandledrejection", (e: any) => {
            e.preventDefault();
            this.fatal(e.detail.reason);
        });

        (<any>window).showDebugMessage = (element) =>{
            $(element).hide().next().show();
        }
    }
}