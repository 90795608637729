import {autoinject} from 'aurelia-framework';
import {AuthenticationType, RestClient} from "../api/rest-client";
import {ConfigurationView} from "../../view-models/configuration-view-models";
import {ConfigurationMappings} from "../../mappings/configuration-mappings";
import { StorageKey, StorageService } from '../storage/storage-service';

@autoinject()
export class BackendConfigurationService {

    constructor(private rest: RestClient, private storageService: StorageService) {
    }

    /**
     * Requesting all possible membership status from Backend
     */
    async getMembershipStatusValues(): Promise<ConfigurationView[]> {

        let configView = await this.storageService.GetObject<ConfigurationView[]>(StorageKey.MembershipStatus);

        if(configView) return configView;

        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result = await client.requestConfigurationAppUsingGET('membershipstatus');
        configView = result.map(conf => ConfigurationMappings.ConfigurationDTOtoConfigurationView(conf));
        await this.storageService.SetObject(StorageKey.MembershipStatus, configView);

        return configView;
    }

    async getOrderItemTypeValues(): Promise<ConfigurationView[]> {

        let configView = await this.storageService.GetObject<ConfigurationView[]>(StorageKey.OrderItemType);

        if(configView) return configView;

        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result = await client.requestConfigurationAppUsingGET('rewardtypes');
        configView = result.map(conf => ConfigurationMappings.ConfigurationDTOtoConfigurationView(conf));
        await this.storageService.SetObject(StorageKey.OrderItemType, configView);

        return configView;
    }

    async getRewardGroupValues(): Promise<ConfigurationView[]> {

        let configView = await this.storageService.GetObject<ConfigurationView[]>(StorageKey.RewardGroup);

        if(configView) return configView;

        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result = await client.requestConfigurationAppUsingGET('rewardgroups');
        configView = result.map(conf => ConfigurationMappings.ConfigurationDTOtoConfigurationView(conf));
        await this.storageService.SetObject(StorageKey.RewardGroup, configView);
 
        return configView;
    }

    async getOrderItemStatusValues(): Promise<ConfigurationView[]> {

        let configView = await this.storageService.GetObject<ConfigurationView[]>(StorageKey.OrderItemStatus);

        if(configView) return configView;

        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result = await client.requestConfigurationAppUsingGET('orderitemstatus');
        configView = result.map(conf => ConfigurationMappings.ConfigurationDTOtoConfigurationView(conf));
        await this.storageService.SetObject(StorageKey.OrderItemStatus, configView);

        return configView;
    }

    async getSalutationValues(): Promise<ConfigurationView[]> {

        let configView = await this.storageService.GetObject<ConfigurationView[]>(StorageKey.Salutation);

        if(configView) return configView;

        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let result = await client.requestConfigurationAppUsingGET('salutations');
        configView = result.map(conf => ConfigurationMappings.ConfigurationDTOtoConfigurationView(conf));
        await this.storageService.SetObject(StorageKey.Salutation, configView);

        return configView;
    }

}