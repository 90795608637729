import { autoinject, singleton } from 'aurelia-framework';
import { HttpClient } from 'aurelia-fetch-client';
import { AuthenticationProvider, ConfigProvider, Logger } from 'voltospa';
import { IoC } from 'voltospa/services/dependencies/ioc';
import { Router } from 'aurelia-router';
import { AOKRestApi, IAOKRestApi } from "./aok-rest-api";
import { AokRestApiMock } from "./aok-rest-api-mock";
import { TenantContext } from "../tenant/tenant-context";
import { EnvironmentConfigurationService } from "../configuration/environment-configuration-service";
import { IResourceRestApi, ResourceRestApi } from './resource-rest-api';
import { ResourceRestApiMock } from './resource-rest-api-mock';
import {ServiceUnavailableInterceptor} from "./service-unavailable-interceptor";

@singleton()
@autoinject()
export class RestClient {

    constructor(private authenticationProvider: AuthenticationProvider, private router: Router, private logger: Logger,
        private config: ConfigProvider, private tenantContext: TenantContext, private environment: EnvironmentConfigurationService,
                private interceptor: ServiceUnavailableInterceptor) {
    }


    private async buildResourcesApi(auth: AuthenticationType): Promise<ResourceRestApi> {
        let baseURL = await this.getServiceUrl();
        let httpClient = await this.buildHttpClient(auth)
        return new ResourceRestApi(baseURL, httpClient);
    }

    private async buildApi(auth: AuthenticationType): Promise<AOKRestApi> {
        let baseURL = await this.getServiceUrl();
        let httpClient = await this.buildHttpClient(auth);
        return new AOKRestApi(baseURL, httpClient);
    }

    private async buildHttpClient(auth: AuthenticationType): Promise<HttpClient> {
        let vm = this;
        let headers = {};
        switch (auth) {
            case AuthenticationType.BEARER:
                let user = await vm.authenticationProvider.getUser();
                headers = {
                    'Authorization': 'Bearer ' + user.accessToken,
                };
                break;
            case AuthenticationType.BASIC_NO_X_HEADER:
            case AuthenticationType.BASIC:
                let env = await vm.environment.getConfiguration();
                headers = {
                    'Authorization': 'Basic ' + btoa(env.restbasicauth.user + ":" + env.restbasicauth.password),
                };
                break;
        }

        if (this.tenantContext.isTenantSet() && auth != AuthenticationType.BASIC_NO_X_HEADER) {
            headers["X-TenantID"] = vm.tenantContext.getTenant().tenantId;
        }

        let httpClient = new HttpClient().configure(config => {
            config
                .withDefaults({
                    headers: headers,
                    credentials: 'include'
                })
                .withInterceptor(vm.interceptor)
            ;
        });

        return httpClient;
    }

    private async getServiceUrl(): Promise<string> {
        return (await this.environment.getConfiguration()).serviceurl;
    }

    public async getRestApi(auth: AuthenticationType): Promise<IAOKRestApi> {
        // For development purpose we want to mock our backend
        let ismocked: boolean = (await this.environment.getConfiguration()).backendmocked;
        if (ismocked) {
            return new AokRestApiMock(this.config);
        }
        return await this.buildApi(auth);
    }


    public async getDefaultRestApi(auth: AuthenticationType): Promise<IResourceRestApi> {
        // For development purpose we want to mock our backend
        let ismocked: boolean = (await this.environment.getConfiguration()).backendmocked;
        if (ismocked) {
            return new ResourceRestApiMock(this.config);
        }

        return await this.buildResourcesApi(auth);
    }


}


export enum AuthenticationType {
    /**
     * Authentication should not be set
     */
    NONE = "NONE",
    /**
     * Use configured Basic Authentication
     */
    BASIC = "BASIC",
    /**
     * Use configured Basic Authentication, but no X-Headers
     */
    BASIC_NO_X_HEADER = "BASIC_NO_X_HEADER",
    /**
     * Use Bearer Token from User
     */
    BEARER = "BEARER"
}