import { DefaultLogger } from './../logging/defaultLogger';
import { DefaultFormRenderer } from './../validation/defaultFormRenderer';
import { FormRenderer } from './../validation/formRenderer';
import { BootstrapperCommand } from '../dependencies/bootstrapperCommand';
import { IoC } from '../dependencies/ioc';
import { MenuProvider } from '../navigation/menuProvider';
import { ConfigMenuProvider } from '../navigation/configMenuProvider';
import { LocalizationProvider } from '../localization/localizationProvider';
import { DefaultLocalizationProvider } from '../localization/defaultLocalizationProvider';
import { LayoutProvider } from '../layout/layoutProvider';
import { DefaultLayoutProvider } from '../layout/defaultLayoutProvider';
import { ConfigProvider } from '../configuration/configProvider';
import { DefaultConfigProvider } from '../configuration/defaultConfigProvider';
import { Logger } from '../logging/logger';
import { AuthenticationProvider } from './../security/authenticationProvider';
import { DefaultAuthenticationProvider } from './../security/defaultAuthenticationProvider';
import { AuthorizationProvider } from './../security/authorizationProvider';
import { DummyAuthorizationProvider } from './../security/dummyAuthorizationProvider';
import { RouterAuthorizeStepProvider } from './../security/routerAuthorizeStepProvider';
import { DefaultRouterAuthorizeStepProvider } from './../security/defaultRouterAuthorizeStepProvider';

export class DefaultRegistrations implements BootstrapperCommand {
  public async execute(): Promise<void> {
    //IoC Registrierungen
    IoC.register(MenuProvider, ConfigMenuProvider);
    IoC.register(LocalizationProvider, DefaultLocalizationProvider);
    IoC.register(LayoutProvider, DefaultLayoutProvider);
    IoC.register(ConfigProvider, DefaultConfigProvider);
    IoC.register(FormRenderer, DefaultFormRenderer);
    IoC.register(Logger, DefaultLogger);
    IoC.register(AuthenticationProvider, DefaultAuthenticationProvider);
    IoC.register(AuthorizationProvider, DummyAuthorizationProvider);
    IoC.register(RouterAuthorizeStepProvider, DefaultRouterAuthorizeStepProvider);
  }
}