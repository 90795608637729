import { ResourceReferenceDTO, ExtendedRewardDTO } from './../service/api/aok-rest-api';
import { ResourceReferenceViewModel } from '../view-models/resource-view-model';
import { RewardViewModel } from '../view-models/reward-view-model';

export class RewardMapping{

    public static ExtendedRewardDTOToRewardViewModel(from?: ExtendedRewardDTO): RewardViewModel{

        let to = new RewardViewModel();
        to.id = from.id.toString();
        to.shortDescription = from.description;
        to.thumbnail = this.ResourceReferenceDTOToResourceReferenceViewModel(from.thumbnail);
        to.title = from.name;
        to.points = from.value;
        to.articleNumber = from.articleNumber;
        to.description = from.formattedDescription;
        to.name = from.name;
        to.rewardId = from.id;
        to.extendedType = from.type;
        

        if(from.resources)
            to.resources = from.resources.map(res => this.ResourceReferenceDTOToResourceReferenceViewModel(res));
        else
            to.resources = [];
        
        return to;
    }

    public static ResourceReferenceDTOToResourceReferenceViewModel(from?: ResourceReferenceDTO): ResourceReferenceViewModel{
        
        if(!from) {
            return null;
        }

        let to = new ResourceReferenceViewModel();
        to.alternativeText = from.alternativeText;
        to.resourceId = from.id;
        to.filename = from.filename;
        return to;
    }
}