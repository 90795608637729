import { autoinject } from 'aurelia-framework';
import { PageBase } from './contents/Base/page-base';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { ProgramCategoryViewModel, ProgramParticipantModel } from '../view-models/activity-prototype-view-model';
import { ActivityPprototypeService } from '../service/activityPrototype/activity-prototype-service';
@autoinject()
export class SoFunktionierts extends PageBase {

    public data:string ='!!!Hello World!!!';
    public tenant: string = '';
    public groupedActivityPrototypes: ProgramParticipantModel[] = [];

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, public nav: UiNavigationService,public activityPprototypeService: ActivityPprototypeService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.tenant = this.tenantProvider.getTenant().key;
        
    }

    async attached(){
        this.groupedActivityPrototypes = await this.activityPprototypeService.getActivityPrototypeByTenantandProgramType();
    }
}