import { PageBase } from "./contents/Base/page-base";
import { autoinject } from 'aurelia-framework';
import { TenantService } from "../service/tenant/tenant-service";
import { ConfigProvider, Logger } from "voltospa";
import { TenantContext } from "../service/tenant/tenant-context";
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";

@autoinject()
export class Datenschutz extends PageBase {

    constructor(tenantService: TenantService, config: ConfigProvider, tenantProvider: TenantContext, layout: UILayoutService, logger: Logger, nav: UiNavigationService) {

        super(tenantService, config, tenantProvider, layout, logger, nav);
    }
}