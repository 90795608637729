import { FormControlHelper } from './../../services/binding/formControlHelper';
import { bindable, autoinject, bindingMode, computedFrom } from 'aurelia-framework';
import { FormElement } from './formElement';

@autoinject
export class VoltoCheckbox extends FormElement {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: boolean;
    @bindable public checkboxLabel: string;
    @bindable public checkboxLabelFallback: string;
    @bindable public showLabel: boolean = true;

    @computedFrom('showLabel')
    get showLabelInternal(): boolean {
        return this.showLabel.toString() == "true";
    }

    constructor(element: Element, formControlHelper: FormControlHelper) {
        super(element, formControlHelper)
    }
}