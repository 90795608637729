import { StarterCommand } from '../dependencies/starterCommand';
import { autoinject } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';

@autoinject
export class FetchEvents extends StarterCommand {
    constructor(private eventAggregator: EventAggregator) {
        super();
    }

    public async execute(): Promise<void> {
        var _oldFetch = fetch;
        var self = this;
        window.fetch = function() {
            var fetchCall = _oldFetch.apply(this, arguments);
            self.eventAggregator.publish('voltospa:fetchstart');
            fetchCall.then((response) =>{
                self.eventAggregator.publish('voltospa:fetchcomplete', response);
                return response
            }).catch((error)=>{
                self.eventAggregator.publish('voltospa:fetchcomplete', error);
                return error;
            });
            return fetchCall;
        };
    }
}