import { autoinject, Aurelia } from 'aurelia-framework';
import { AuthenticationType, RestClient } from "../api/rest-client";
import { ConfigProvider } from "voltospa";
import { OrderViewModel } from '../../view-models/order-view-model';
import { ErrorDTO, OrderDTO, OrderItemDTOType, OrderRewardRequestDTO, RegistrationFormResultDTO, SwaggerException } from '../api/aok-rest-api';
import { AddressViewModel } from '../../view-models/address-view-model';
import { ErrorViewModel } from '../../view-models/error-view-model';
import { StorageKey, StorageService } from '../storage/storage-service';
import { OrderItemViewModel } from '../../view-models/order-view-model';
import { OrderMapping } from '../../mappings/order-mapping';
import { BackendConfigurationService } from '../configuration/backend-configuration-service';
import { ConfigurationView } from '../../view-models/configuration-view-models';

@autoinject
export class OrderService {


    constructor(private rest: RestClient, private config: ConfigProvider, private storageService: StorageService,
        private orderMapper: OrderMapping, private backendConfigurationService: BackendConfigurationService) {

    }
    public async getOrders(membershipId: number): Promise<OrderViewModel[]> {

        let client = await this.rest.getRestApi(AuthenticationType.BEARER);
        let result: OrderViewModel[] = [];
        let configs: Map<string, ConfigurationView[]> = new Map<string, ConfigurationView[]>();
        let statusConfig = await this.backendConfigurationService.getOrderItemStatusValues();
        configs.set("status", statusConfig);
        let typeConfig = await this.backendConfigurationService.getOrderItemTypeValues();
        configs.set("type", typeConfig);
        try {
            let response = await client.listOrdersGET(membershipId);
            var filteredResponse = response.filter(x => x.orderItems.every(y=> y.type != OrderItemDTOType.GIFT))
            result = filteredResponse.map(o => OrderMapping.OrderDtoToOrderViewModel(o, configs));
        } catch (error) {

        }
        return result;

    }

    public async submitOrder(membershipId: number, deliveryAddress?: AddressViewModel): Promise<ErrorViewModel> {
        let client = await this.rest.getRestApi(AuthenticationType.BEARER);

        let res = new ErrorViewModel();
        res.success=false;
        var orders = await this.storageService.GetObject<OrderItemViewModel[]>(StorageKey.ShoppingCart);
        // map Orders
        let orderRequest: OrderRewardRequestDTO = new OrderRewardRequestDTO();

        orderRequest.items = orders.map(item => OrderMapping.OrderViewModelToOrderItemRequestDTO(item));
        if (deliveryAddress != undefined) {
            orderRequest.deliveryAddress = OrderMapping.DeliveryAddressViewModelToDeliveryAddressDTO(deliveryAddress);
        }
        let result: OrderDTO;
        try {
            result = await client.createOrderRewardPOST(membershipId, orderRequest);            
            res.success=true;
        } catch (error) {
            if (error instanceof ErrorDTO) {
                var errorDto: ErrorDTO = error as ErrorDTO;
                res.exception = errorDto.exception;
                res.errorCode = errorDto.errorCode;
                res.errorMessages = errorDto.errorMessages;
            }
            return res;
        }        
        await this.storageService.RemoveItem(StorageKey.ShoppingCart);
        return res;
    }


}


