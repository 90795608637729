import { ErrorDTO, OrderItemDTOStatus, OrderItemDTOType } from '../service/api/aok-rest-api';
import { ExtendedRewardDTOType } from '../service/api/aok-rest-api';
import { AddressViewModel } from './address-view-model';


export class OrderViewModel {
    id?: number | undefined;
    orderDate?: Date | undefined;
    value?: number | undefined;
    deliveryAdress: AddressViewModel | undefined;
    orderItems?: OrderItemViewModel[] | undefined;

    getFullValue(): number {
        let result: number = this.orderItems.reduce((sum: number, oi: OrderItemViewModel) => sum + (oi.amount * oi.valuePerUnit), 0);
        return result;
    }
}

export class OrderItemViewModel {
    amount?: number | undefined;
    valuePerUnit?: number | undefined;
    status?: OrderItemDTOStatus | undefined;
    statusDescription?: string | undefined;
    articleNumber?: string | undefined;
    name?: string | undefined;
    description?: string | undefined;
    type?:  OrderItemDTOType | undefined;
    typeDescription?: string | undefined;
    //type?: ExtendedRewardDTOType | undefined;
    resourceId?: number | undefined;
    imageUrl?: string | undefined;
    rewardId?: number | undefined;
    couponCode?: string | undefined;
    trackingId?: string | undefined;
    carrier?: string | undefined;

    
}