import { NotificationMessage } from './../../models/notifications/notificationMessage';
import { autoinject, transient } from 'aurelia-framework';
import { NotificationService } from './../notifications/notificationService';
import { ValidationRenderer, RenderInstruction, ValidateResult, ResultInstruction } from 'aurelia-validation';
import { NotificationType } from './../../models/notifications/notificationType';
import { FormRenderer } from './formRenderer';

@autoinject
@transient()
export class DefaultFormRenderer extends FormRenderer {
    private list: ResultInstruction[] = new Array<ResultInstruction>();

    constructor(private notifier: NotificationService) {
        super();
    }

    public render(instruction: RenderInstruction) {

        var numberOfMessages = this.list.length;
        var messagesAdded = false;
        var messagesRemoved = false;
        var invalid = instruction.render.filter(r => !r.result.valid)
        this.list = this.list.concat(invalid);
        messagesAdded = numberOfMessages != this.list.length;

        numberOfMessages = this.list.length;
        for (let { result, elements } of instruction.unrender) {
            this.list = this.list.filter((l) => l.result.id != result.id);
        }
        messagesRemoved = numberOfMessages != this.list.length;

        this.list = this.list.sort((a, b) => a.result.message > b.result.message ? 1 : b.result.message > a.result.message ? -1 : 0);
        
        if (messagesAdded || messagesRemoved) {
            this.notifier.clear(true);
        }

        for (let { result, elements } of this.list) {
            for (let element of elements) {
                this.add(element, result);
            }
        }
    }

    private add(element: Element, result: ValidateResult) {
        if (result.valid) {
            return;
        }
        var formGroup = $(element).closest('.form-group');
        if (formGroup.length == 0)
            formGroup = $(element).find('.form-group');
        let helpBlock = formGroup.find('.help-block').first();
        let notifierMessage: NotificationMessage = { type: NotificationType.Error, message: result.message, inlineMessage: result.message, key: helpBlock.attr('data-validator-for'), fallback: null };
        this.notifier.displayMessages([notifierMessage]);
    }
}