import {User} from "voltospa";

export class AuthUtil {

    public static hasRoleString(user : User, role : string) : boolean{

        if(!user){
            console.info("role check for undefined user");
            return false;
        }

        if(user && user.claims && user.claims["roles"]) {
            return user.claims["roles"].includes(role);
        } else {
            console.warn("user has no roles: "+ JSON.stringify(user))
            return false;
        }
    }

    public static hasRole(user : User, role : Roles) : boolean{
        return this.hasRoleString(user, role.toString());
    }

    public static hasOneRole(user : User, ...roles : Roles[]) : boolean{
        return roles.filter(role => this.hasRole(user, role)).length > 0;
    }
}

export enum Roles {
    ROLE_USER = "ROLE_USER"
}