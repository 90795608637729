import { autoinject } from 'aurelia-framework';
import { PageBase } from './contents/Base/page-base';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { AuthService } from '../service/auth/auth-service';
import { ProfileService } from '../service/profile/profile-service';
import { ProfileViewModel } from '../view-models/profile-view-model';

@autoinject()
export class Sitemap extends PageBase {

    public tenant: string = '';
    public userProfile = new ProfileViewModel();
    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, 
        public nav: UiNavigationService, private auth: AuthService,  private profileService: ProfileService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.tenant = this.tenantProvider.getTenant().key;
    }
    async attached() {
        this.userProfile = await this.profileService.getUserProfile(false);
    }
}