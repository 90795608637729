import * as $ from 'jquery';

export enum ProgressIndicatorTypes {
    Spinner = 1,
    FadingCircle = 2,
    Dots = 3,
    SpinnerWithClose = 4
}

/***
 * showProgressIndicator
 *
 * Display an overlay including a symbol indicating ongoing work on the site:
 *
 * type [ProgressIndicatorType] - Type that determines the visual appearance of the indicator
 * elem [HTMLElement] - Element that contains the indicator (null for full page - default)
 */
export function showProgressIndicator(type, elemSelector = null) {
    let progressIndicator = document.createElement("div");
    progressIndicator.classList.add('progress-indicator');

    let centeredContainer = document.createElement("div");
    centeredContainer.classList.add("centered-container");

    let spinnerHtml = "<div class='spinner'></div>";
    let fadingCircleHtml = "<div class='fading-circle'><div class='circle circle-1'></div><div class='circle circle-2'></div><div class='circle circle-3'></div><div class='circle circle-4'></div><div class='circle circle-5'></div><div class='circle circle-6'></div><div class='circle circle-7'></div><div class='circle circle-8'></div><div class='circle circle-9'></div><div class='circle circle-10'></div><div class='circle circle-11'></div><div class='circle circle-12'></div></div>";
    let dotsHtml = "<div class='dots'><div class='dot1'></div><div class='dot2'></div><div class='dot3'></div></div>";
    let spinnerWithCloseHtml = "<div><div class='spinner'></div><div class='text-center mt-2 d-flex align-items-center justify-content-center'><button type='button' class='indicator-close close spinner-close d-flex align-items-center' aria-label='Close'><span class='fal fa-times mr-1'></span>Close</button></div></div>";

    let parser = new DOMParser();
    let symbolHtml = spinnerHtml;

    switch(type) {
        case ProgressIndicatorTypes.FadingCircle:
        symbolHtml = fadingCircleHtml;
            break;
        case ProgressIndicatorTypes.Dots:
        symbolHtml = dotsHtml;
            break;
        case ProgressIndicatorTypes.SpinnerWithClose:
        symbolHtml = spinnerWithCloseHtml;
            break;
    }

    let symbol = parser.parseFromString(symbolHtml, "text/html").querySelector("div:first-of-type");
    centeredContainer.appendChild(symbol);
    progressIndicator.appendChild(centeredContainer);

    if(elemSelector != null) {
        $(elemSelector).css("position","relative");
        $(elemSelector)[0].appendChild(progressIndicator);
    } else {
        progressIndicator.style.position = "fixed";
        progressIndicator.classList.add("fullscreen");
        $("body")[0].appendChild(progressIndicator);
    }

    return progressIndicator;
}

export function hideProgressIndicator(elem) {
    $(elem).hide();
}
