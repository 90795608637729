import 'bootstrap';
import { AuthenticationProvider, AuthorizationProvider, IoC, VoltoSPA } from 'voltospa';
import { Aurelia, PLATFORM } from 'aurelia-framework';
import { AokAuthenticationProvider } from "./service/security/aok-authentication-provider";
import { AokAuthorizationProvider } from "./service/security/aok-authorization-provider";
import { $ } from 'volto';

export async function configure(aurelia: Aurelia) {

    IoC.replace(AuthenticationProvider, AokAuthenticationProvider);
    IoC.replace(AuthorizationProvider, AokAuthorizationProvider);

    await VoltoSPA.bootstrap();
    aurelia.use.standardConfiguration().plugin(PLATFORM.moduleName('voltospa'));
    await aurelia.start();
    await VoltoSPA.start();
    await aurelia.setRoot(PLATFORM.moduleName('AOK.Royale/app'));

    $('body').fadeIn();
}