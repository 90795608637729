import { bindable, computedFrom, inject } from 'aurelia-framework';
import { LocalizationProvider } from '../../../services/localization/localizationProvider';

@inject(Element)
export class VoltoContainer {
    @bindable icon: string;
    @bindable title: string;
    @bindable titleFallback: string;
    @bindable noPadding: boolean = false;
    @bindable subtitle: string;
    @bindable subtitleFallback: string;
    @bindable collapsible: boolean = true;

    static counter: number = 0;
    public id: string = 'volto-container';

    constructor(public element) {
        VoltoContainer.counter++;
        this.id = this.id + VoltoContainer.counter.toString();
    }

    attached(){
        this.initCollapseIcon();
    }

    private initCollapseIcon(){
        $(this.element).find('.btn-collapse').off('click').on('click', function(){  $(this).find('[data-fa-i2svg]').toggleClass('fa-angle-up fa-angle-down')});
    }

    detached(){
        $(this.element).find('.btn-collapse').off('click');
    }
}