import { autoinject, computedFrom } from 'aurelia-framework';
import { AuthService } from '../auth/auth-service';

let instance = null;

@autoinject
export class UILayoutService {

    constructor(private auth: AuthService) {
        if (!instance) {
            instance = this;
        }

        return instance;
    }

    public IS_LANDINGPAGE = false;
    public SHOW_SERVICE_BAR = false;
    public SHOW_CUSTOMER_HEADER = false;
    public SHOW_MOBILE_ACTION_BAR=false;
    public SHOW_NAVIGATION = false;
    public SHOW_BREADCRUMB = false;
    public SHOW_FOOTER = false;
    public SHOW_MINIMAL_LAYOUT = true;

    @computedFrom("SHOW_CUSTOMER_HEADER")
    public get SHOW_CUSTOMER_HEADER_INFO_LOGGED_IN() {
        if (!this.SHOW_CUSTOMER_HEADER)
            return false;
        return this.auth.IsAuthenticated();
    }

    @computedFrom("SHOW_MOBILE_ACTION_BAR")
    public get SHOW_MOBILE_ACTION_BAR_INFO_LOGGED_IN():boolean {
        if (!this.SHOW_MOBILE_ACTION_BAR)
            return false;

            return this.auth.IsAuthenticated()==true;
    }


    public setStartLayout(): void {
        this.IS_LANDINGPAGE = true;
        this.SHOW_SERVICE_BAR = false;
        this.SHOW_CUSTOMER_HEADER = false;
        this.SHOW_MOBILE_ACTION_BAR = false;
        this.SHOW_NAVIGATION = false;
        this.SHOW_MINIMAL_LAYOUT = true;
        this.SHOW_FOOTER = false;
        this.SHOW_BREADCRUMB = false;
    }

    public setMainLayout(): void {
        this.IS_LANDINGPAGE = false;
        this.SHOW_SERVICE_BAR = true;
        this.SHOW_CUSTOMER_HEADER = true;
        this.SHOW_MOBILE_ACTION_BAR = true;
        this.SHOW_NAVIGATION = true;
        this.SHOW_MINIMAL_LAYOUT = false;
        this.SHOW_FOOTER = true;
        this.SHOW_BREADCRUMB = true;
    }

    public diasbaleBreadCrumbs() {
        this.SHOW_BREADCRUMB = false;
    }

    public enableBreadCrumbs() {
        this.SHOW_BREADCRUMB = true;
    }

    public enableFooter(): void {
        this.SHOW_FOOTER = true;
    }

    public disableFooter(): void {
        this.SHOW_FOOTER = false;
    }

    public disableMobileActionBar():void{        
        this.SHOW_MOBILE_ACTION_BAR = false;
    }
}
