import { AokCardViewModel } from './../../../view-models/aok-card-view-model';
import { autoinject, bindable } from 'aurelia-framework';
import { UiNavigationService } from '../../../service/ui/ui-navigation-service';
import { ResourceService } from '../../../service/resource/resource-service';
import { ImageSize } from '../../../service/api/resource-rest-api';

@autoinject()
export class AokCard {

    @bindable
    public card: AokCardViewModel = new AokCardViewModel();

    @bindable
    public route: string;

    @bindable
    public detailtext: string;

    public imageUrl: string;
    public backgroundImageStylestring: string = "";
    public showNav: boolean = true;

    constructor(public nav: UiNavigationService, private resourceService: ResourceService) {

    }

    async navigateToDetail() {
        if (this.route) {
            await this.nav.navigate(this.route, { id: this.card.id });
        }
    }

    async attached() {
        if (this.card && this.card.thumbnail) {
            let imageFile = await this.resourceService.getResourceById(this.card.thumbnail.resourceId, ImageSize.M);
            this.imageUrl = URL.createObjectURL(imageFile);
            this.backgroundImageStylestring = "background-image: url(" + this.imageUrl + ");";
        }

        if (!this.route) {
            this.showNav = false;
        }
    }
}