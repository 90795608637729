import { PageBase } from "./contents/Base/page-base";
import { autoinject } from 'aurelia-framework';
import { History } from 'aurelia-history';
import { Router } from 'aurelia-router';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../service/tenant/tenant-context';
import { TenantService } from '../service/tenant/tenant-service';
import { UILayoutService } from "../service/ui/ui-layout-service";
import { UiNavigationService } from "../service/ui/ui-navigation-service";
import { NewsService } from '../service/news/news-service';
import { NewsViewModel } from "../view-models/news-view-model";

@autoinject()
export class News extends PageBase{

    public news: NewsViewModel;

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger, 
        public nav: UiNavigationService, public newsService: NewsService, public router: Router, public history: History) {

        super(tenantService, config, tenantProvider, layout, logger, nav);
    }

    async activate(params: any, route, navigationInstruction) {       
        this.news= await this.newsService.getNewsById(params.id);
        navigationInstruction.config.navModel.title = this.news.title;
        route.navModel.router.updateTitle();
    }

    async navigateBack(){
        history.back();
    }
}
