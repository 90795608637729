import { EventAggregator } from 'aurelia-event-aggregator';
import { StorageService } from './../../service/storage/storage-service';
import { PageBase } from './../contents/Base/page-base';
import { autoinject } from 'aurelia-framework';
import { ConfigProvider, Logger } from 'voltospa';
import { AuthService } from '../../service/auth/auth-service';
import { RewardsService } from '../../service/rewards/rewards-service';
import { TenantContext } from '../../service/tenant/tenant-context';
import { TenantService } from '../../service/tenant/tenant-service';
import { UILayoutService } from '../../service/ui/ui-layout-service';
import { UiNavigationService } from '../../service/ui/ui-navigation-service';
import { RewardViewModel } from '../../view-models/reward-view-model';
import { ResourceService } from '../../service/resource/resource-service';
import { ResourceReferenceViewModel } from '../../view-models/resource-view-model';
import { ImageSize } from '../../service/api/resource-rest-api';
import { StorageKey } from '../../service/storage/storage-service';
import { OrderItemViewModel } from '../../view-models/order-view-model';
import { Constants } from '../../constants/constants';
import { OrderItemDTOStatus } from '../../service/api/aok-rest-api';
import { ProfileViewModel } from '../../view-models/profile-view-model';
import { ProfileService } from '../../service/profile/profile-service';
@autoinject()
export class PremiumDetails extends PageBase {

    public reward: RewardViewModel;
    public CarouselImage: CarouselViewModel[];
    public tenant: string = '';
    public isInShoppingCart: boolean = false;
    public userProfile = new ProfileViewModel();

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, public layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private auth: AuthService, private rewardsService: RewardsService, private resourceService: ResourceService,
        private storageService: StorageService, private eventAggregator: EventAggregator, private profileService: ProfileService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.tenant = this.tenantProvider.getTenant().key;
    }

    async activate(params: any, route, navigationInstruction) {
        if (this.auth.IsAuthenticated()) {
            this.userProfile = await this.profileService.getUserProfile(false);
        }
        var praemieId = parseInt(params.premiumId, 10);
        if (!isNaN(praemieId)) {

            this.reward = await this.rewardsService.getRewardById(praemieId);
            navigationInstruction.config.navModel.title = this.reward.name;
            route.navModel.router.updateTitle();

            this.CarouselImage = this.reward.resources;
            await Promise.all(this.CarouselImage.map(async (item): Promise<any> => {
                var imageFile = await this.resourceService.getResourceById(item.resourceId, ImageSize.L);
                item.url = URL.createObjectURL(imageFile);
                var imageFileSmall = await this.resourceService.getResourceById(item.resourceId, ImageSize.S);
                item.urlSmall = URL.createObjectURL(imageFileSmall);
            }));

            this.isInShoppingCart = await this.checkIfOrderIsInShoppingCart();
        }
    }

    async orderReward() {
        let orders: OrderItemViewModel[] = [];

        if (await this.checkIfOrderIsInShoppingCart() == false) {
            let order = this.setNewOrder(this.reward);

            let shoppingCart = await this.storageService.GetObject<RewardViewModel[]>(StorageKey.ShoppingCart);
            if (shoppingCart != undefined) {
                orders = shoppingCart;
            }
            orders.push(order);
            await this.storageService.SetObject(StorageKey.ShoppingCart, orders);
            this.isInShoppingCart = true;
            await this.eventAggregator.publish(Constants.warenkorbItemChangedEvent);
        }
    }

    private setNewOrder(card: RewardViewModel): OrderItemViewModel {
        let order = new OrderItemViewModel();
        order.amount = 1;
        order.articleNumber = card.articleNumber;
        order.description = card.shortDescription;
        order.name = card.title;
        order.status = OrderItemDTOStatus.CREATED;
        order.valuePerUnit = card.points;
        //order.unitCount = 1;
        order.resourceId = card.thumbnail.resourceId;
        order.rewardId = card.rewardId;
        return order;
    }

    private async checkIfOrderIsInShoppingCart(): Promise<boolean> {
        let shoppingCart = await this.storageService.GetObject<RewardViewModel[]>(StorageKey.ShoppingCart);
        if (shoppingCart == undefined) {
            return false;
        }
        else if (shoppingCart.every(x => x.articleNumber != this.reward.articleNumber)) {
            return false
        }
        else {
            return true;
        }
    }
}

export class CarouselViewModel extends ResourceReferenceViewModel {
    url?: string | undefined;
    urlSmall?: string | undefined;
}