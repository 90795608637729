import { bindable, autoinject, bindingMode } from 'aurelia-framework';
import "bootstrap-datepicker/js/locales/bootstrap-datepicker.de";

@autoinject
export class AokDatepicker {

  @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: Date;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) public textValue: string;
  @bindable public format: string = 'dd.mm.yyyy';
  @bindable public label: string;
  @bindable public id: string;
  @bindable helptext: string;
  @bindable onlySpecificYear: boolean = false;
  @bindable year: number;
  private startDate: Date;
  private endDate: Date;


  private datePicker;

  public attached() {

    if (this.datePicker) return;

    if (this.onlySpecificYear) {
      var currentDate = new Date();
      var defaultViewDate = this.year == currentDate.getFullYear() ? currentDate : new Date(this.year, 0, 2);
      this.startDate = new Date(this.year, 0, 1);
      this.endDate = new Date(this.year, 11, 31);
      this.datePicker = $('#datePicker' + this.id).datepicker({
        language: "de",
        format: this.format,
        orientation: "auto",
        todayHighlight: true,
        autoclose: true,
        forceParse: false,
        defaultViewDate: defaultViewDate,
        startDate: this.startDate,
        endDate: this.endDate
      });
    }
    else {
      this.datePicker = $('#datePicker' + this.id).datepicker({
        language: "de",
        format: this.format,
        orientation: "auto",
        todayHighlight: true,
        autoclose: true
      });
    }



    this.datePicker.datepicker("setDate", this.value);

    this.datePicker.on("hide", (e) => {
      this.setDate(e)
    });

    this.datePicker.on("clearDate", (e) => {
      this.value = null;
    });

    this.datePicker.on("change", (e) => {
      var currentTextValue = $('#datePicker' + this.id).val();
      this.textValue = currentTextValue.toString();
      if (this.textValue.length == 0 && this.value != null) {
        this.setDate({ date: undefined });
        return;
      }
      if (this.textValue.length == 0 && this.value == null) {
        return;
      }

      var date = this.parseDate(this.textValue);
      if (date != this.value) {   
        if(this.onlySpecificYear && (date < this.startDate || date > this.endDate)) 
          this.setDate({ date: undefined });
        else
          this.setDate({ date: date });
      }
    });
  }

  public detached() {
    this.datePicker.datepicker('destroy');
  }

  private setDate(e: any) {
    if (e.date == undefined) {
      this.value = null;
      return;
    }
    if (this.value == null || this.value != e.date) {
      this.value = e.date;
    }
  }

  private parseDate(input): Date {
    var parts = input.match(/(\d+)/g);
    return new Date(parts[2], parts[1] - 1, parts[0]);
  }
}