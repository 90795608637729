import { autoinject } from "aurelia-framework";
import { Interceptor } from "aurelia-fetch-client";
import { Router } from 'aurelia-router';
import {Logger} from "voltospa";


@autoinject()
export class ServiceUnavailableInterceptor implements Interceptor {


    constructor(
                private router: Router
    ) {

    }

    async request(request: Request): Promise<Request> {
        // we don't have to change anything on all requests
        return request;
    }

    /**
     * Implementation of Aurelia to handle any response
     * @param resp Response from this call
     */
    async response(resp: Response): Promise<Response> {

        if(resp.status == 503) {
            window.location.href = "/error/";
        }

        return resp;
    }


}
