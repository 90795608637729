import { LayoutProvider } from '../../services/layout/layoutProvider';
import { autoinject, containerless } from 'aurelia-framework'
import { MenuProvider } from '../../services/navigation/menuProvider';

@autoinject
@containerless
export class VoltoContent {

    public menuPopulated: boolean = false;
    constructor(public layoutProvider: LayoutProvider, private menuProvider: MenuProvider) {
    }

    async created() {
        await this.menuProvider.populateRouter();
        this.menuPopulated = true;
    }
}