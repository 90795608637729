
import { bindable, autoinject, bindingMode } from 'aurelia-framework';
import { FormElement } from 'voltospa/customElements/formElements/formElement';
import { FormControlHelper } from 'voltospa/services/binding/formControlHelper';

@autoinject
export class AokTextbox  {

  @bindable helptext: string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: any;
  @bindable public label: string;
  @bindable public id:string;
  @bindable({ defaultBindingMode: bindingMode.twoWay }) public readonly: boolean = false;
}