import * as moment from "moment";
import { FeedbackDTO, FeedbackDTOSalutation } from "../service/api/aok-rest-api";
import { FeedbackViewModel } from "../view-models/feedback-view-model";

export class FeedbackMapping{

    public static FeedbackViewModeltoFeedbackDTO(from?: FeedbackViewModel) : FeedbackDTO{
        
        if(!from) {
            return null;
        }

        let to = new FeedbackDTO();
        to.email = from.email;
        to.firstname = from.firstname;
        if(from.kvnr != undefined){
            to.kvnr = from.kvnr.toUpperCase();
        }
        to.lastname = from.lastname;
        to.message = from.message;
        to.phone = from.phone;
        to.street = from.street;
        to.streetNo = from.streetNo;
        to.subject = from.subject;
        to.title = from.title;
        to.zip = from.zip;
        to.countryCode = from.countryCode;
        to.city = from.city;
        to.kvnr = from.kvnr;
        to.birthDate =  moment.utc({ year: from.birthdate.getFullYear(), month: from.birthdate.getMonth(), day: from.birthdate.getDate() });
        to.salutation = FeedbackDTOSalutation[from.salutation];
        to.namePrefix = from.namePrefix;
        
        return to;

    }
}