import { DeliveryAddressDTO, DeliveryAddressDTOSalutation, OrderDTO, OrderItemDTO, OrderItemRequestDTO } from "../service/api/aok-rest-api";
import { OrderItemViewModel, OrderViewModel } from "../view-models/order-view-model";
import * as moment from 'moment';
import { AddressViewModel } from "../view-models/address-view-model";
import { ConfigurationView } from '../view-models/configuration-view-models';

export class OrderMapping {
    public static OrderDtoToOrderViewModel(from: OrderDTO, configView?: Map<string, ConfigurationView[]>): OrderViewModel {
        let to = new OrderViewModel();
        to.id = from.id;
        to.orderDate = from.orderDate.toDate();
        to.orderItems = from.orderItems.map(o => this.OrderItemDtoToOrderItemViewModel(o, configView));
        to.value = from.value;
        to.deliveryAdress = this.DeliveryAddressDtoToDeliveryAddressViewModel(from.deliveryAddress);
        return to;
    }

    public static OrderItemDtoToOrderItemViewModel(from: OrderItemDTO, configView?: Map<string, ConfigurationView[]>): OrderItemViewModel {
        let to = new OrderItemViewModel();
        to.amount = from.amount;
        to.articleNumber = from.articleNumber;
        to.description = from.description;

        to.name = from.name;
        to.status = from.status;

        if (from.status != undefined) {
            to.statusDescription = configView.get("status").filter(c => c.key === from.status.toString())[0].displayValue;
        }
        to.type = from.type;
        if (from.type != undefined) {
            to.typeDescription = configView.get("type").filter(c => c.key === from.type.toString())[0].displayValue;
        }
        to.valuePerUnit = from.valuePerUnit;
        to.couponCode = from.couponCode;
        to.carrier = from.carrier;
        to.trackingId = from.trackingID;

        // missing in dto:
        //to.imageUrl = from.
        //to.resourceId = from.
        //to.unitCount = from.
        return to;
    }

    public static DeliveryAddressDtoToDeliveryAddressViewModel(from: DeliveryAddressDTO): AddressViewModel {
        let to = new AddressViewModel();
        to.addressSupplement = from.addressSupplement;
        to.city = from.city;
        to.countryCode = from.countryCode;
        to.firstname = from.firstname;
        to.lastname = from.lastname;
        to.namePrefix = from.namePrefix;
        if (from.salutation != undefined) {
            to.salutation = from.salutation.toString();
        }
        to.street = from.street;
        to.streetNo = from.streetNo
        to.title = from.title;
        to.zip = from.zip;

        return to;
    }

    public static OrderViewModelToOrderItemRequestDTO(from?: OrderItemViewModel) : OrderItemRequestDTO{
        if(!from){
            return null;
        }
        let to = new OrderItemRequestDTO();
        to.amount = from.amount;
        to.rewardId = from.rewardId;
        return to;
    }

    public static DeliveryAddressViewModelToDeliveryAddressDTO(from?: AddressViewModel ): DeliveryAddressDTO{
        if(!from){
            return null;
        }
        let to = new DeliveryAddressDTO();
        to.addressSupplement = from.addressSupplement;
        to.countryCode = from.countryCode;
        to.firstname = from.firstname;
        to.lastname = from.lastname;
        to.namePrefix = from.namePrefix;
        
        if (from.salutation != undefined) {
            to.salutation = DeliveryAddressDTOSalutation[from.salutation];
        }
        to.city = from.city;
        to.street = from.street;
        to.streetNo = from.streetNo;
        to.zip = from.zip;

        return to;
    }



}