import { autoinject, bindable } from 'aurelia-framework';

@autoinject
export class VoltoNotifications {

    constructor(private element: Element){

    }

    attached(){
        $(this.element).find('.close:not(.isInitialized)').click(function () {
            $(this).closest('div.alert').fadeOut();
        }).addClass('isInitialized');
    }
}