import { computedFrom } from 'aurelia-binding';
import { autoinject, customElement, bindable, containerless } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { BindingSignaler } from 'aurelia-templating-resources';
import { Language } from '../../models/localization/language';
import { LocalizationProvider } from '../../services/localization/localizationProvider';
import { LayoutProvider } from '../../services/layout/layoutProvider';

@autoinject
@customElement('volto-header')
@containerless
export class VoltoHeader {
    
    @bindable
    public showCollapseMenu: boolean = false;
   
    @bindable
    public languages: Language[];

    public constructor(public EventAggregator: EventAggregator, public BindingSignaler: BindingSignaler, 
        public localizationProvider: LocalizationProvider, public layoutProvider: LayoutProvider) {
        
    }

    async created() {
        this.languages = await this.localizationProvider.getLanguages();

    }

    @computedFrom('showCollapseMenu')
    get showCollapseMenuInternal(): boolean {
        return this.showCollapseMenu.toString() == "true";
    }

    @computedFrom('languages')
    get activeLanguage(): Language {
        return this.languages.find(l => l.isActive);
    }

    public selectLanguage(language: Language) {
        this.localizationProvider.setLocale(language.culture);
        this.languages.forEach(l => l.isActive = false);
        language.isActive = true;
        this.EventAggregator.publish('voltospa:selectlanguage', language);
        this.BindingSignaler.signal('selectlanguage');
        window.location.reload();
    }
}