import { autoinject, customAttribute, DOM } from 'aurelia-framework';
import { LocalizationProvider } from '../services/localization/localizationProvider'

@customAttribute('localize')
@autoinject
export class LocalizeAttribute {

  constructor(private element: Element, private localizationProvider: LocalizationProvider) {
  }

  attached() {
    this.valueChanged((<any>this).value);
  }

  valueChanged(key) {
    var translation = this.localizationProvider.translate(key, $(this.element).html());
    this.element.textContent = translation;
  }
}