import * as $ from 'jquery';

export const breakpoints =
    {
        "xs":
            {
                "from": 0,
                "to": 575
            },
        "sm":
            {
                "from": 576,
                "to": 767
            },
        "md":
            {
                "from": 768,
                "to": 991
            },
        "lg":
            {
                "from": 992,
                "to": 1199
            },
        "xl":
            {
                "from": 1200,
                "to": 10000
            }
    }

export function initSidebarToggle() {
    // Sidebar
    $("#menu-toggle").on("click", () => {
        let $body = $("body");
        let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        if (viewportWidth <= breakpoints.xs.to) {
            $body.removeClass("hide-sidebar");
            $body.toggleClass("show-sidebar");
        } else {
            $body.removeClass("show-sidebar");
            $body.toggleClass("hide-sidebar");
        }
    });

    let lastResize = new Date();

    $(window).on('resize', (e) => {
        let viewportWidth = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        if (viewportWidth <= breakpoints.xs.to) {
            $(".menu-toggle-icon").toggleClass("fa-arrow-left fa-arrow-right");
        }
    });
}

export function initIconSidebar(scrollbar) {
    var scrollElem = scrollbar.getScrollElement();

    ////// icon-sidebar toggle

    $("#icon-sidebar-toggle").on("click", () => {
        $("body").toggleClass("icon-sidebar");

        scrollElem.scrollBy({
            top: 0,
            left: 0,
            behavior: "smooth"
        });
    });

    ////// icon-sidebar collapsibles
    var $popupContainer = $(".icon-sidebar-popup-container");

    //// init icon-sidebar collapsibles
    if ($("body").hasClass("icon-sidebar") || sessionStorage.getItem("iconSidebar")) {
        $(".sidebar li.collapsible > a").each(function (index, item) {
            $(item).attr("data-collapsible-id", index);
            $(item).on("click", function (e) {
                $(".sidebar li.collapsible > a").removeClass("opened");
                $(item).addClass("opened");
                var listItem = $(item).closest("li");
                var content = listItem.find("div.collapse").html();

                $popupContainer.html(content);

                var viewportOffset = this.getBoundingClientRect();

                $popupContainer.attr("data-collapsible-id", index);
                $popupContainer.css({
                    "top": viewportOffset.top + "px",
                    "display": "block"
                });
            });
        });
    }

    //// adjust collapsible position
    window.addEventListener("scroll", function () {
        var collapsibleId;
        collapsibleId = $popupContainer.attr("data-collapsible-id");
        var $menuItem = $("a[data-collapsible-id = " + collapsibleId + "]");
        if ($menuItem.length == 1) {
            var viewportOffset = $menuItem[0].getBoundingClientRect();

            $popupContainer.css({
                "top": viewportOffset.top + "px"
            });
        }
    });

    //// hide collapsible
    $(scrollbar.getScrollElement()).on("scroll", function (e) {
        hidePopupContainer();
    });
    $("body").on("click", function (e) {
        var collapsibleId;
        collapsibleId = $popupContainer.attr("data-collapsible-id");
        if (collapsibleId
            && $(e.target).closest(".icon-sidebar-popup-container").length <= 0
            && $(e.target).closest("a[data-collapsible-id = " + collapsibleId + "]").length <= 0) {
            hidePopupContainer();
        }
    });
}

export function initIconSidebarScrolling(scrollbar) {
    var scrollElem = scrollbar.getScrollElement();

    $(scrollElem).on("scroll", function () {
        if (scrollElem.scrollTop <= 0) {
            $(".icon-sidebar-scroll-btn.scroll-up").hide();
        } else {
            $(".icon-sidebar-scroll-btn.scroll-up").show();
        }

        if (scrollElem.scrollTop + $(scrollElem).height() >= $(".simplebar-content").height()) {
            $(".icon-sidebar-scroll-btn.scroll-down").hide();
        } else {
            $(".icon-sidebar-scroll-btn.scroll-down").show();
        }
    });

    $(".icon-sidebar-scroll-btn.scroll-down").on("click", function () {
        scrollElem.scrollBy({
            top: $(scrollElem).height() / 2,
            left: 0,
            behavior: "smooth"
        })
    });

    $(".icon-sidebar-scroll-btn.scroll-up").on("click", function () {
        scrollElem.scrollBy({
            top: -1 * $(scrollElem).height() / 2,
            left: 0,
            behavior: "smooth"
        })
    });
}

export function initGroupHover() {
    $("#main-menu .nav-link").on("mouseover", (e) => {
        $(e.target).parents(".nav-item").css("background-color", "rgba(2,121,179,0.1)");
    });
    $("#main-menu .nav-link").on("mouseleave", (e) => {
        $(e.target).parents(".nav-item").css("background-color", "transparent");
    });
}

function hidePopupContainer() {
    $(".icon-sidebar-popup-container").html("");
    $(".icon-sidebar-popup-container").css({
        "display": "none"
    });
}