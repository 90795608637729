import { ResourceReferenceViewModel } from './../view-models/resource-view-model';
import { LoyaltyProgramNewsDTO, ResourceReferenceDTO } from "../service/api/aok-rest-api";
import { NewsViewModel } from "../view-models/news-view-model";

import * as moment from 'moment';
export class NewsMapping {

    public static LoyaltyProgrammNewsTOtoNewsViewViewModel(from?: LoyaltyProgramNewsDTO): NewsViewModel {

        if (!from) {
            return null;
        }
        let to = new NewsViewModel();
        to.id = from.id ? from.id.toString() : "";
        to.validFrom = from.validFrom.toDate();
        to.validFromFormatted = moment(to.validFrom).format("DD.MM.YYYY");
        to.category = from.category;
        to.title = from.title;
        to.shortDescription = from.shortDescription;
        to.formattedContent = from.formattedContent;
        to.thumbnail = this.ResourceReferenceDTOToResourceReferenceViewModel(from.thumbnail);
        return to;
    }

    public static ResourceReferenceDTOToResourceReferenceViewModel(from?: ResourceReferenceDTO): ResourceReferenceViewModel {

        if (!from) {
            return null;
        }

        let to = new ResourceReferenceViewModel();
        to.alternativeText = from.alternativeText;
        to.resourceId = from.id;
        to.filename = from.filename;
        return to;
    }
}