import { ValidationControllerFactory, ValidationController, ValidationRules } from 'aurelia-validation';
import { autoinject } from 'aurelia-framework';
import { PageBase } from '../contents/Base/page-base';
import { ConfigProvider, Logger } from 'voltospa';
import { TenantContext } from '../../service/tenant/tenant-context';
import { TenantService } from '../../service/tenant/tenant-service';
import { UILayoutService } from "../../service/ui/ui-layout-service";
import { UiNavigationService } from "../../service/ui/ui-navigation-service";
import { ParticipationService } from '../../service/participation/participation-service';
import { BootstrapErrorRenderer } from '../contents/CustomElements/BootstrapErrorRenderer';
import * as moment from 'moment';
import { ErrorDTO } from '../../service/api/aok-rest-api';

@autoinject()
export class OnlineLoginRequest extends PageBase {

    public kvnr: string = '';
    public email: string = '';
    public mobileNumber: string = '';
    public dateOfBirth: Date = null;
    public controlReadonly: boolean = true;
    public requestState: boolean = false;
    public unknownError: boolean = false;
    public legitimationError: boolean = false;
    public isPmvError: boolean = false;
    public duplicateEmailError: boolean = false;
    public requestSend: boolean = false;
    public showFailedRequestException: boolean = false;
    public enableButton:boolean=true;

    private validationController: ValidationController;

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private validationControllerFactory: ValidationControllerFactory, private participationService: ParticipationService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        this.validationController = this.validationControllerFactory.createForCurrentScope();
        this.validationController.addRenderer(new BootstrapErrorRenderer());

        ValidationRules.customRule('validateDate', (value: Date) => {
            var date = moment(value);
            return date.isValid() && date <= moment(new Date());
        }, '\${$displayName} ist ungültig');

        ValidationRules.ensure("kvnr")
            .required().withMessage('Bitte füllen Sie dieses Pflichtfeld aus.')
            .then().minLength(9).withMessage("Mindestens 9 Zeichen.")
            .then().maxLength(10).withMessage("Nicht mehr als 10 Zeichen.")
            .then().matches(new RegExp(/^[a-zA-Z]?\d{9}$/)).withMessage("Bitte überprüfen Sie Ihre Eingabe.")
            .ensure("email")
            .required().withMessage('Bitte geben Sie Ihre E-Mail-Adresse an.')
            .then().email().withMessage('Bitte geben Sie eine gültige E-Mail-Adresse an.')           
            .maxLength(40).withMessage("Nicht mehr als 40 Zeichen. ")
            .then().satisfies((val, obj) => !this.duplicateEmailError).withMessage("Die E-Mail-Adresse ist bereits vergeben. ")
            .ensure("mobileNumber")
            .required().withMessage('Bitte geben Sie Ihre Handynummer an.')
            .then().matches(new RegExp(/^((0{1,2}|(\+49))[1-9][0-9\-\/\s]*?)$/)).withMessage('Bitte geben Sie eine gültige Handynummer an.')
            .then().minLength(7).withMessage("Mindestens 7 Zeichen inklusive Vorwahl.")
            .ensure("dateOfBirth").displayName('Geburtsdatum')
            .required().withMessage('Bitte geben Sie Ihr Geburtsdatum an.')
            .then().satisfiesRule('validateDate')
            .on(this);
    }

    async activate(params: any, route, navigationInstruction) {

        if (params.kvnr) {
            this.kvnr = params.kvnr.toUpperCase();
        } else {
            this.nav.navigate('teilnehmen');
        }
    }

    async submitData() {
        this.duplicateEmailError=false;
        this.enableButton=false;
        if ((await this.validationController.validate()).valid) {
            try {
                this.requestSend = true;
                await this.participationService.createOnlineAccess(this.tenantProvider.getTenant().id, this.getLoyalityProgram().id, this.kvnr, this.email, this.prepareTelno(this.mobileNumber), this.dateOfBirth);
                this.requestState = true;
            }
            catch (error) {
                if (error instanceof ErrorDTO) {
                    var knownErrorCodes = ["PARTICIPANT_NOT_ACTIVE", "ONLINE_ACCESS_ALREADY_EXISTS", "KVNR_ALREADY_IN_USE"];
                    if (error.errorCode == "PARTICIPANT_NOT_FOUND")
                        this.legitimationError = true;
                    else if (error.errorCode == "PARTICIPANT_IS_PMV")
                        this.isPmvError = true;
                    else if (error.errorCode == "EMAIL_NOT_UNIQUE")
                    {
                        this.duplicateEmailError = true;
                        this.requestSend = false;
                        await this.validationController.validate();
                    }
                    else if (knownErrorCodes.indexOf(error.errorCode) > -1)
                        this.showFailedRequestException = true;
                    else
                        this.unknownError = true;
                }
                else {
                    this.unknownError = true;
                }
            }
        }        
        this.enableButton=true;
    }

    async reInitilizeData() {
        this.requestSend = false;
        this.requestState = false;
        this.unknownError = false;
        this.showFailedRequestException = false;
        this.legitimationError = false;
        this.isPmvError = false;
        this.duplicateEmailError = false;
    }

    private prepareTelno(telno: string): string {    
        telno = telno.replace(new RegExp(/[\s\/\-]/g), '');
        return telno.replace('+', '00');
    }

}