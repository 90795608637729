import { EventAggregator } from 'aurelia-event-aggregator';
import { AccountService } from './../../service/account/account-service';
import { autoinject, observable } from 'aurelia-framework';
import { ConfigProvider, Logger } from 'voltospa';
import { ImageSize } from '../../service/api/resource-rest-api';
import { ResourceService } from '../../service/resource/resource-service';
import { StorageKey, StorageService } from '../../service/storage/storage-service';
import { TenantContext } from '../../service/tenant/tenant-context';
import { TenantService } from '../../service/tenant/tenant-service';
import { UILayoutService } from '../../service/ui/ui-layout-service';
import { UiNavigationService } from '../../service/ui/ui-navigation-service';
import { OrderItemViewModel } from '../../view-models/order-view-model';
import { PageBase } from '../contents/Base/page-base';
import { ProfileService } from '../../service/profile/profile-service';
import { Constants } from '../../constants/constants';
import { AuthService } from '../../service/auth/auth-service';
import { LoyaltyProgramViewModel, ProgramType } from '../../view-models/tenant-view-models';


@autoinject
export class Warenkorb extends PageBase {

    @observable
    public orderItems: OrderItemViewModel[] = [];

    private canOrder: boolean = true;
    private availablePoints: number = 0;
    private totalPoints: number = 0;
    private loyaltyProgram:LoyaltyProgramViewModel;
    private minimumOrderValue: number=0;
    private minimumOrderValueReached: boolean=false;

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, public layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private storageService: StorageService, private resourceService: ResourceService, private auth: AuthService,
        private accountService: AccountService, private profileService: ProfileService, private eventAggregator: EventAggregator) {
        super(tenantService, config, tenantProvider, layout, logger, nav);

        if (!this.auth.IsAuthenticated()) {
            this.nav.navigate("start");
        }
        var tenant =this.tenantProvider.getTenant();        
        this.loyaltyProgram =tenant.programs[0];
        // if(this.loyaltyProgram.programType== ProgramType.FAMILY_BONUS)
        // {
        //     this.minimumOrderValue=800;
        // }
        layout.disableMobileActionBar();
    }

    public async attached() {
        var items = this.storageService.GetObject<OrderItemViewModel[]>(StorageKey.ShoppingCart);

        if (items) {
            this.orderItems = items;

            for (var i = 0; i < this.orderItems.length; i++) {
                try {
                    let imageFile = await this.resourceService.getResourceById(this.orderItems[i].resourceId, ImageSize.M);
                    this.orderItems[i].imageUrl = URL.createObjectURL(imageFile);
                } catch (error) {
                }
            }
        }
        var userProfile = await this.profileService.getUserProfile(false);
        var accountBalance = await this.accountService.getAccountBalance(userProfile.mainInsurer.membership.membershipId);
        this.availablePoints = accountBalance.endBalance;

        this.computeTotalPoints();
    }

    private computeTotalPoints() {
        var sum = 0;
        for (var i = 0; i < this.orderItems.length; i++) {
            if (this.orderItems[i].amount < 1) {
                this.orderItems[i].amount = 1;
            }
            sum = sum + (this.orderItems[i].valuePerUnit * this.orderItems[i].amount);
        }

        this.storageService.SetObject(StorageKey.ShoppingCart, this.orderItems);
        this.canOrder = this.availablePoints >= sum;
        this.minimumOrderValueReached = sum>= this.minimumOrderValue;
        this.totalPoints = sum;
        this.eventAggregator.publish(Constants.warenkorbItemChangedEvent);
    }

    deleteOrderItem(index: number) {
        this.orderItems.splice(index, 1);
        this.computeTotalPoints();
    }

    deleteShoppingcart() {
        this.orderItems.splice(0);
        this.computeTotalPoints();
    }

    itemCountChanged() {
        this.computeTotalPoints();
    }
}