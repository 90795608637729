import { autoinject } from 'aurelia-framework';
import { TenantContext } from "../tenant/tenant-context";
import { TenantService } from "../tenant/tenant-service";
import { AuthenticationType, RestClient } from "../api/rest-client";
import { ConfigProvider } from "voltospa";
import { ErrorDTO, LoyaltyProgramFAQDTO, RegistrationFormResultDTO, TenantDTO } from "../api/aok-rest-api";
import { FaqViewModel, GroupedFaqViewModel } from '../../view-models/faq-view-model';
import { FaqMapping } from '../../mappings/faq-mapping';
import { FeedbackViewModel } from '../../view-models/feedback-view-model';
import { FeedbackMapping } from '../../mappings/feedback-mapping';
import { FeedbackResultViewModel } from '../../view-models/feedback-result-view-Model';


@autoinject
export class FeedbackService {

    constructor(private rest: RestClient, private config: ConfigProvider) {

    }

    public async createFeedback(tenantId: number, loyaltyProgramId: number, requestData: FeedbackViewModel): Promise<FeedbackResultViewModel> {
        let client = await this.rest.getRestApi(AuthenticationType.BASIC);
        let requestParamsDto = FeedbackMapping.FeedbackViewModeltoFeedbackDTO(requestData);
        let res = new FeedbackResultViewModel();
        var result: void;
        try {
            result = await client.sendFeedbackUsingPOST(loyaltyProgramId, requestParamsDto);
        } catch (error) {
            if(error instanceof ErrorDTO)
            {
                var errorDto: ErrorDTO = error as ErrorDTO;
                res.exception = errorDto.exception;
                res.errorCode = errorDto.errorCode;
                res.errorMessages = errorDto.errorMessages;
            }
            return res;
        }
        return res;
    }
}