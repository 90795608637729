import { autoinject, observable } from 'aurelia-framework';
import { StorageKey, StorageService } from '../storage/storage-service';
import { AuthenticationProvider } from "voltospa";
import { EventAggregator } from 'aurelia-event-aggregator';
import {AuthenticationType, RestClient} from "../api/rest-client";

@autoinject
export class AuthService {

    constructor(public storage: StorageService, private authenticationProvider: AuthenticationProvider,
                private eventAggregator: EventAggregator, private rest: RestClient) {
        let vm = this;
        this.eventAggregator.subscribe('voltospa:authentication-logout', async () => {
            await vm.internalPerformLogout();
        });
        this.eventAggregator.subscribe('voltospa:authentication-login', async () => {
            await vm.internalPerformLogin();
            await vm.postAppUsage();
        });

    }

    @observable 
    private isAuthenticated: boolean;

    private async internalPerformLogin() {
        let user = await this.authenticationProvider.getUser();
        
        if(user != null){
            this.storage.SetItem(StorageKey.Token, user.accessToken);
            this.isAuthenticated=true;
        }
        else{
            this.internalPerformLogout();
        }
    }

    private async internalPerformLogout() {
        for (const key in StorageKey) {
                this.storage.RemoveItem(StorageKey[StorageKey[key]]);
        }

        this.isAuthenticated = false;
    }

    public IsAuthenticated(): boolean {
        this.authenticationProvider.isAuthenticated().then((value:boolean) =>{
            this.isAuthenticated = value;
        }).catch((err) =>{this.isAuthenticated = false;});

        return this.isAuthenticated;
    }

    public async postAppUsage() {
        let client = await this.rest.getRestApi(AuthenticationType.BEARER);
        await client.postAppUsageUsingPOST();
    }
}