import { autoinject } from 'aurelia-framework';
import { ConfigProvider, Logger } from 'voltospa';
import { AuthService } from '../../service/auth/auth-service';
import { RewardsService } from '../../service/rewards/rewards-service';
import { TenantContext } from '../../service/tenant/tenant-context';
import { TenantService } from '../../service/tenant/tenant-service';
import { UILayoutService } from '../../service/ui/ui-layout-service';
import { UiNavigationService } from '../../service/ui/ui-navigation-service';
import { RewardGroupViewModel } from '../../view-models/reward-group-view-model';
import { TenantViewModel } from '../../view-models/tenant-view-models';
import { PageBase } from '../contents/Base/page-base';

@autoinject()
export class Overview extends PageBase {

    public activeTenant: TenantViewModel;
    public rewardGroups: RewardGroupViewModel[];

    constructor(tenantProvider: TenantContext, tenantService: TenantService, config: ConfigProvider, public layout: UILayoutService, logger: Logger,
        public nav: UiNavigationService, private auth: AuthService, private rewardsService: RewardsService) {
        super(tenantService, config, tenantProvider, layout, logger, nav);
        this.activeTenant = tenantProvider.getTenant();
        console.log("active Tenant: " + this.activeTenant.id);
    }

    public async attached() {
        this.rewardGroups = await this.rewardsService.getRewardGroups(this.activeTenant.id, this.getLoyalityProgram().id);
    }
}