import { FormControlHelper } from './../../services/binding/formControlHelper';
import { bindable, autoinject, bindingMode, computedFrom } from 'aurelia-framework';
import { FormElement } from './formElement';

@autoinject
export class VoltoTextbox extends FormElement {

    @bindable({ defaultBindingMode: bindingMode.twoWay }) public value: any;

    @bindable private multiline: boolean = false;

    @computedFrom('multiline')
    get multilineInternal(): boolean {
        return this.multiline.toString() == "true";
    }

    
    constructor(element: Element, formControlHelper: FormControlHelper) {
        super(element, formControlHelper);
    }
}