import { ConfigOptions } from './configOptions';
import { ConsoleAppender } from 'aurelia-logging-console';
import { PLATFORM, FrameworkConfiguration, TaskQueue } from 'aurelia-framework';
import * as LogManager from 'aurelia-logging';
import { ValidationProvider } from './services/validation/validationProvider';
import { MenuProvider } from './services/navigation/menuProvider';
import { ConfigMenuProvider } from './services/navigation/configMenuProvider';
import { LocalizationProvider } from './services/localization/localizationProvider';
import { LayoutProvider } from './services/layout/layoutProvider';
import { DefaultLayoutProvider } from './services/layout/defaultLayoutProvider';
import { IoC } from './services/dependencies/ioc';
import { BootstrapperCommand } from './services/dependencies/bootstrapperCommand';
import { StarterCommand } from './services/dependencies/starterCommand';
import { Bootstrapper } from './services/dependencies/bootstrapper';
import { DefaultRegistrations } from './services/boot/defaultRegistrations';
import { FixPromises } from './services/boot/fixPromises';
import { BootstrapPhase } from './services/dependencies/bootstrapPhase';
import { SetDefaultLayoutOptions } from './services/boot/setDefaultLayoutOptions';
import { FetchEvents } from './services/boot/fetchEvents';
import { ConfigProvider } from './services/configuration/configProvider';
import { Logger } from './services/logging/logger'
import { AuthenticationProvider } from './services/security/authenticationProvider';
import { AuthorizationProvider } from './services/security/authorizationProvider';
import { RouterAuthorizeStepProvider } from './services/security/routerAuthorizeStepProvider';
import './services/validation/customValidationRules';

export async function configure(config: FrameworkConfiguration, options: ConfigOptions) {
    if (!(options instanceof ConfigOptions))
        options = new ConfigOptions();

    //wait until config has loaded
    await IoC.get<ConfigProvider>(ConfigProvider).init(config, options);
    if (options.loadValidationPlugin)
        config.plugin(PLATFORM.moduleName('aurelia-validation'));

    IoC.get<LocalizationProvider>(LocalizationProvider).init(config);
    IoC.get<Logger>(Logger).init(config);
    IoC.get<AuthenticationProvider>(AuthenticationProvider).init(config);

    //Custom Elements
    config
        .globalResources(PLATFORM.moduleName('./customAttributes/volto-localize'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/volto-app'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/volto-logininfo'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/volto-header'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/volto-content'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/navigation/volto-menu'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/navigation/volto-menu-level'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/navigation/volto-breadcrumb'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/navigation/volto-footer'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/volto-notifications'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/container/volto-container'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/container/volto-container-buttons'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/modal/volto-modal'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/modal/volto-modal-buttons'))
        .globalResources(PLATFORM.moduleName('./customElements/layout/modal/volto-modal-close-button'))
        .globalResources(PLATFORM.moduleName('./customElements/formElements/volto-textbox'))
        .globalResources(PLATFORM.moduleName('./customElements/formElements/volto-checkbox'))
        .globalResources(PLATFORM.moduleName('./customElements/formElements/volto-checkboxes'))
        .globalResources(PLATFORM.moduleName('./customElements/formElements/volto-radiobuttons'))
        .globalResources(PLATFORM.moduleName('./customElements/formElements/volto-select'))
        .globalResources(PLATFORM.moduleName('./customElements/formElements/volto-datepicker'))
        .globalResources(PLATFORM.moduleName('./customElements/grid/volto-pager'))
        .globalResources(PLATFORM.moduleName('./customElements/grid/volto-grid'))
        ;
};

export class VoltoSPA {
    static async bootstrap() {
        //Bootstrapper Registrierungen
        IoC.register(BootstrapperCommand, DefaultRegistrations);
        IoC.register(BootstrapperCommand, FixPromises);
        await Bootstrapper.run(BootstrapPhase.Bootstrap);
    }

    static async start() {
        IoC.register(StarterCommand, SetDefaultLayoutOptions);
        IoC.register(StarterCommand, FetchEvents);
        await Bootstrapper.run(BootstrapPhase.Start);
    }
}

//Sämtliche Exporte
export { Bootstrapper } from './services/dependencies/bootstrapper';
export { Language } from './models/localization/language';
export { MenuItem } from './models/navigation/menuItem';
export { NotificationService } from './services/notifications/notificationService';
export { NotificationMessage } from './models/notifications/notificationMessage';
export { NotificationType } from './models/notifications/notificationType';
export { ModalDialogService } from './services/modal/modalDialogService';
export { ValidationProvider } from './services/validation/validationProvider';
export { NoLocalizationProvider } from './services/localization/noLocalizationProvider'
export { LogAppenderProvider } from './services/logging/logAppenderProvider';
export { GenericRepository } from './repositories/genericRepository'
export { Verbs } from './models/general/verbs'
export { SearchParameter } from './models/search/searchParameter'
export { SearchtermSearchParameter } from './models/search/searchtermSearchParameter'
export { User } from './models/security/user'
export { Logger }
export { MenuProvider };
export { LocalizationProvider };
export { LayoutProvider };
export { IoC };
export { BootstrapperCommand }
export { StarterCommand }
export { ConfigProvider }
export { ConfigOptions }
export { AuthenticationProvider }
export { AuthorizationProvider }
export { RouterAuthorizeStepProvider }
export { DefaultConfigProvider } from './services/configuration/defaultConfigProvider'
export { SortDirection } from './models/search/sortDirection';