import { RewardGroupViewModel } from './../view-models/reward-group-view-model';
import { ConfigurationView } from '../view-models/configuration-view-models';
import { ResourceReferenceDTO, RewardGroupDTO } from './../service/api/aok-rest-api';
import { ResourceReferenceViewModel } from '../view-models/resource-view-model';

export class RewardgroupMapping {

    public static RewardGroupDTOToRewardGroupViewModel(from?: RewardGroupDTO, configView?: ConfigurationView[]): RewardGroupViewModel {

        if (!from || !configView) {
            return null;
        }

        if (configView.length == 0) {
            return null;
        }

        let to = new RewardGroupViewModel();
        to.id = from.group.toString();
        to.shortDescription = from.shortDescription;
        to.thumbnail = this.ResourceReferenceDTOToResourceReferenceViewModel(from.thumbnail);
        to.title = configView.filter(c => c.key == from.group.toString())[0].displayValue;
        if(to.title =="Gutscheine & Geldprämien"){to.title="Geldprämien";}
        return to;
    }

    public static ResourceReferenceDTOToResourceReferenceViewModel(from?: ResourceReferenceDTO): ResourceReferenceViewModel {

        if (!from) {
            return null;
        }

        let to = new ResourceReferenceViewModel();
        to.alternativeText = from.alternativeText;
        to.resourceId = from.id;
        to.filename = from.filename;
        return to;
    }
}