import { IoC } from './ioc';
import { BootstrapperCommand } from './bootstrapperCommand';
import { StarterCommand } from './starterCommand';
import { BootstrapPhase } from './bootstrapPhase';

export class Bootstrapper {
    public static async run(phase: BootstrapPhase): Promise<void> {
        if(phase == BootstrapPhase.Bootstrap)
            await IoC.getAll<BootstrapperCommand>(BootstrapperCommand).forEach(item => item.execute());
        else
            await IoC.getAll<BootstrapperCommand>(StarterCommand).forEach(item => item.execute());
    }
}